import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { ImageUrlsFragmentDoc } from '../fragments.generated';
import { AiOrgContextFragmentDoc } from './useBranding.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocationGroupObjectFragmentFragment = { __typename?: 'LocationGroup', id: string, name: string, brandDetails?: { __typename?: 'BrandDetails', id: string, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null };

export type BrandSelectorQueryVariables = Types.Exact<{
  locationGroupInput?: Types.InputMaybe<Types.LocationGroupsInput>;
}>;


export type BrandSelectorQuery = { __typename?: 'Query', LocationGroups: { __typename?: 'LocationGroups', objects: Array<{ __typename?: 'LocationGroup', id: string, name: string, brandDetails?: { __typename?: 'BrandDetails', id: string, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null }> }, Me?: { __typename?: 'Me', user: { __typename?: 'Employee', org?: { __typename?: 'Org', id: number, name: string, brandDetails?: { __typename?: 'BrandDetails', id: string, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null } | null } } | null };

export const LocationGroupObjectFragmentFragmentDoc = gql`
    fragment LocationGroupObjectFragment on LocationGroup {
  id
  name
  brandDetails {
    id
    logoUrls {
      ...ImageUrls
    }
    aiBrandContext {
      ...AIOrgContext
    }
  }
}
    ${ImageUrlsFragmentDoc}
${AiOrgContextFragmentDoc}`;
export const BrandSelectorDocument = gql`
    query BrandSelector($locationGroupInput: LocationGroupsInput) {
  LocationGroups(input: $locationGroupInput) {
    objects {
      ...LocationGroupObjectFragment
    }
  }
  Me {
    user {
      org {
        id
        name
        brandDetails {
          id
          logoUrls {
            ...ImageUrls
          }
          aiBrandContext {
            ...AIOrgContext
          }
        }
      }
    }
  }
}
    ${LocationGroupObjectFragmentFragmentDoc}
${ImageUrlsFragmentDoc}
${AiOrgContextFragmentDoc}`;

/**
 * __useBrandSelectorQuery__
 *
 * To run a query within a React component, call `useBrandSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandSelectorQuery({
 *   variables: {
 *      locationGroupInput: // value for 'locationGroupInput'
 *   },
 * });
 */
export function useBrandSelectorQuery(baseOptions?: Apollo.QueryHookOptions<BrandSelectorQuery, BrandSelectorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandSelectorQuery, BrandSelectorQueryVariables>(BrandSelectorDocument, options);
      }
export function useBrandSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandSelectorQuery, BrandSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandSelectorQuery, BrandSelectorQueryVariables>(BrandSelectorDocument, options);
        }
export type BrandSelectorQueryHookResult = ReturnType<typeof useBrandSelectorQuery>;
export type BrandSelectorLazyQueryHookResult = ReturnType<typeof useBrandSelectorLazyQuery>;
export type BrandSelectorQueryResult = Apollo.QueryResult<BrandSelectorQuery, BrandSelectorQueryVariables>;