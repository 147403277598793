import React from "react";

import Text from "@ui/text";
import AddMediaModal from "@src/components/modals/addMedia/AddMediaModal";
import { MediaFragment } from "@src/fragments.generated";
import { Button } from "@src/ui/button";
import PhotoOutlineIcon from "@src/ui/icons/18px/photo-outline";
import FileDropZone from "@src/ui/file-drop-zone";
import { gql } from "@apollo/client";
import { useToast } from "@src/hooks/useToast";
import { emptyTranslationSet } from "@src/utils/prompts";
import { useMessageBlastModalCreateMediaMutation } from "@src/components/messaging/MessageBlastFileDropZone.generated";
import { SentMessage_MessageBlastMediaFragment } from "./SentMessage.generated";

type Props = {
  pinnedMediaId?: string | null;
  messageBlastMedias: Set<SentMessage_MessageBlastMediaFragment>;
  setMessageBlastMedias: (
    newSet: Set<SentMessage_MessageBlastMediaFragment>,
  ) => void;
};

const MessageBlastFileDropZone: React.FC<Props> = (props) => {
  const { addErrorToast } = useToast();
  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const [createMedia] = useMessageBlastModalCreateMediaMutation({
    onCompleted(data) {
      if (data.createMedia.success && data.createMedia.media) {
        const media = data.createMedia.media;
        const messageBlastMediaObj: SentMessage_MessageBlastMediaFragment = {
          id: media.id,
          media: media,
          pinned: false,
        };
        props.setMessageBlastMedias(
          new Set([...props.messageBlastMedias, messageBlastMediaObj]),
        );
      } else {
        addErrorToast({
          ...data.createMedia.error,
          callsite: "add_media_modal",
        });
      }
    },
    onError: (error) => {
      addErrorToast({
        ...error,
        callsite: "add_media_modal",
      });
    },
  });
  const handleOnCreateMedia = React.useCallback(
    (url: string) => {
      createMedia({
        variables: {
          input: {
            mediaUrls: emptyTranslationSet({
              en: url,
              translationsDisabled: true,
            }),
          },
        },
      });
    },
    [createMedia],
  );
  return (
    <FileDropZone
      disabled={false}
      setMediaUrl={handleOnCreateMedia}
      isFileUploading={isFileUploading}
      setIsFileUploading={setIsFileUploading}
    >
      <div className="mb-4 flex flex-1 flex-col items-center justify-center self-stretch rounded-xl border-2 border-dotted bg-gray-1 py-4">
        <Text
          type="P2"
          fontWeight="Medium"
          className="mb-2 items-center text-muted-foreground"
        >
          Drop files here or{" "}
          <span className="cursor-pointer text-foreground underline">
            upload file
          </span>
        </Text>
        <AddMediaModal
          title="Add media"
          onMediaChange={(media?: MediaFragment) => {
            if (media) {
              const messageBlastMediaObj: SentMessage_MessageBlastMediaFragment =
                {
                  id: media.id,
                  media: media,
                  pinned: false,
                };
              props.setMessageBlastMedias(
                new Set([...props.messageBlastMedias, messageBlastMediaObj]),
              );
            }
          }}
          trigger={
            <Button
              variant="outline"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <PhotoOutlineIcon />
              Add media
            </Button>
          }
        />
      </div>
    </FileDropZone>
  );
};

gql`
  mutation MessageBlastModalCreateMedia($input: MediaInput!) {
    createMedia(input: $input) {
      success
      error {
        code
      }
      media {
        ...Media
      }
    }
  }
`;

export default MessageBlastFileDropZone;
