import React from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "@src/ui/tooltip";
import { Button } from "@src/ui/button";
import TextSizeIcon from "@src/ui/icons/18px/text-size";
import ChevronDownIcon from "@src/ui/icons/12px/chevron-down";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@src/ui/dropdown-menu";
import { TiptapEditorContext } from "@src/components/libraryItemDetailPages/course/contexts/TiptapEditorContext";

const MessageBlastTextStyleAction: React.FC = () => {
  const tiptapEditorContext = React.useContext(TiptapEditorContext);
  return (
    <Tooltip>
      <DropdownMenu>
        <TooltipTrigger asChild>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon" className="w-12">
              <TextSizeIcon />
              <ChevronDownIcon />
            </Button>
          </DropdownMenuTrigger>
        </TooltipTrigger>
        <DropdownMenuContent>
          <DropdownMenuCheckboxItem
            className="text-[1.4rem] font-bold"
            checked={tiptapEditorContext?.editor?.isActive("heading", {
              level: 1,
            })}
            onClick={() =>
              tiptapEditorContext?.editor
                ?.chain()
                .focus()
                .toggleHeading({ level: 1 })
                .run()
            }
          >
            Heading 1
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            className="text-[1.2rem] font-bold"
            checked={tiptapEditorContext?.editor?.isActive("heading", {
              level: 2,
            })}
            onClick={() =>
              tiptapEditorContext?.editor
                ?.chain()
                .focus()
                .toggleHeading({ level: 2 })
                .run()
            }
          >
            Heading 2
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            className="text-[1.1rem] font-bold"
            checked={tiptapEditorContext?.editor?.isActive("heading", {
              level: 3,
            })}
            onClick={() =>
              tiptapEditorContext?.editor
                ?.chain()
                .focus()
                .toggleHeading({ level: 3 })
                .run()
            }
          >
            Heading 3
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={tiptapEditorContext?.editor?.isActive("paragraph")}
            onClick={() => tiptapEditorContext?.editor?.commands.setParagraph()}
          >
            Text
          </DropdownMenuCheckboxItem>
        </DropdownMenuContent>
        <TooltipContent side="bottom">Text styles</TooltipContent>
      </DropdownMenu>
    </Tooltip>
  );
};

export default MessageBlastTextStyleAction;
