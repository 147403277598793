import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { BrandSelector } from "@src/components/aiAssist/BrandSelector";
import { ViewBrandContextButton } from "@src/components/aiAssist/ViewBrandContextButton";
import { AddBrandContextButton } from "@src/components/aiAssist/AddBrandContextButton";
import { BrandSelectOption } from "@src/hooks/useAIAssistBrandSelector";

export function AIAssistModalTopSection({
  brandSelectOptions,
  selectedBrandId,
  selectedBrandItem,
  setSelectedBrandId,
}: {
  brandSelectOptions: BrandSelectOption[];
  selectedBrandId: string | undefined;
  setSelectedBrandId: (value: string) => void;
  selectedBrandItem: BrandSelectOption | undefined;
}): JSX.Element {
  const needsReview = !selectedBrandItem?.aiOrgContext?.isApproved;
  return (
    <AutoLayout
      flex={1}
      direction="vertical"
      borderRadius={12}
      width="100%"
      padding={16}
      style={{
        background:
          "linear-gradient(180deg, rgba(189, 130, 250, 0.10) 0%, rgba(219, 188, 220, 0.10) 100%)",
      }}
    >
      <AutoLayout flex={1} direction="horizontal" spaceBetweenItems={5}>
        <AutoLayout flex={1} direction="vertical" paddingBottom={10}>
          <Text
            type="P1"
            fontWeight="SemiBold"
            style={{
              lineHeight: "22px",
              paddingBottom: 4,
            }}
          >
            Improve AI results
          </Text>
          <Text
            type="P2"
            color={deprecatedTones.gray10}
            style={{
              whiteSpace: "normal",
            }}
          >
            Add contextual information about your brand to improve the results
            of our AI tools. The more we know, the better the results.
          </Text>
          {selectedBrandItem &&
            brandSelectOptions.length &&
            brandSelectOptions.length > 1 && (
              <AutoLayout
                direction="vertical"
                spaceBetweenItems={6}
                paddingTop={8}
              >
                <Text type="P2" fontWeight="Medium">
                  Select Brand
                </Text>
                <AutoLayout
                  flex={1}
                  direction="horizontal"
                  spaceBetweenItems={8}
                >
                  <BrandSelector
                    defaultValue={selectedBrandId!}
                    brandSelectOptions={brandSelectOptions}
                    onSelectValueChange={(value) => {
                      setSelectedBrandId(value);
                    }}
                    selectedBrandItem={selectedBrandItem}
                  />

                  {needsReview ? (
                    <AddBrandContextButton
                      brandSelectOption={selectedBrandItem}
                    />
                  ) : (
                    <ViewBrandContextButton
                      brandSelectOption={selectedBrandItem}
                    />
                  )}
                </AutoLayout>
              </AutoLayout>
            )}
          {selectedBrandItem && brandSelectOptions.length === 1 && (
            <AutoLayout paddingTop={10}>
              {needsReview ? (
                <AddBrandContextButton brandSelectOption={selectedBrandItem} />
              ) : (
                <ViewBrandContextButton brandSelectOption={selectedBrandItem} />
              )}
            </AutoLayout>
          )}
        </AutoLayout>
        <AutoLayout
          flex={1}
          width={105}
          spaceBetweenItems={17}
          alignSelf={"stretch"}
          alignmentVertical="top"
          alignmentHorizontal="center"
          direction="horizontal"
        >
          <div
            style={{
              boxShadow: "0px 2.286px 6.857px 0px rgba(0, 0, 0, 0.03)",
              borderRadius: "18px",
              background: "white",
              alignItems: "center",
              display: "flex",
              padding: "6px",
            }}
          >
            {selectedBrandItem?.logoUrl ? (
              <div
                style={{
                  width: "64px",
                  height: "64px",
                  background: `url(${selectedBrandItem?.logoUrl}) lightgray 50% / cover no-repeat`,
                  borderRadius: 64,
                }}
              />
            ) : (
              <DeprecatedIcon
                type="registered-trademark"
                iconSize={64}
                containerSize={64}
                color={deprecatedTones.gray10}
              />
            )}
          </div>
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
}
