import { FC } from "react";

const CourseTemplateImage: FC = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 43C22 44.6568 23.3431 46 25 46H39C40.6568 46 42 44.6568 42 43V25.2426C42 24.447 41.684 23.6839 41.1214 23.1213L36.8787 18.8787C36.3161 18.3161 35.553 18 34.7574 18H25C23.3431 18 22 19.3431 22 21V43Z"
        fill="#80C3AD"
      />
      <rect x="25" y="28" width="8" height="2" rx="1" fill="white" />
      <rect x="25" y="33" width="14" height="2" rx="1" fill="white" />
      <rect x="25" y="38" width="14" height="2" rx="1" fill="white" />
    </svg>
  );
};

export default CourseTemplateImage;
