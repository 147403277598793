import useDropdown from "@hooks/useDropdown";
import React, { FC, useCallback, useContext } from "react";
import EmojiPicker from "../../ui/EmojiPicker";
import RichTextEditorFormattingToolbar from "./RichTextEditorFormattingToolbar";
import { TiptapEditorContext } from "./contexts/TiptapEditorContext";
import {
  MediaFragment,
  TranslationSetFragment,
} from "@src/fragments.generated";
import AddMediaModal from "@src/components/modals/addMedia/AddMediaModal";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@src/ui/tooltip";
import AiTextSuggestionsDropdown from "@src/components/aiAssist/AiTextSuggestionsDropdown";
import { Separator } from "@src/ui/separator";
import PhotoOutlineIcon from "@src/ui/icons/18px/photo-outline";
import { Button } from "@src/ui/button";
import EmojiOutlineIcon from "@src/ui/icons/18px/emoji-outline";
import { useAvailableLanguages } from "@src/hooks/useAvailableLanguages";
import { Switch } from "@src/ui/switch";
import { Label } from "@src/ui/label";
import LanguageIcon from "@src/ui/icons/18px/language";

type Props = {
  onMediaChange?: (media?: MediaFragment) => void;
  messageText?: TranslationSetFragment;
  setMessageText?: (text: TranslationSetFragment) => void;
  translationModeEnabled?: boolean;
  setTranslationModeEnabled?: (v: boolean) => void;
};

const EditorActionsOld: FC<Props> = ({
  onMediaChange,
  messageText,
  setMessageText,
  translationModeEnabled,
  setTranslationModeEnabled,
}) => {
  const tiptapEditorContext = useContext(TiptapEditorContext);
  const onEmojiSelect = useCallback(
    (emoji: string) => {
      if (tiptapEditorContext?.editor) {
        const insertPoint =
          tiptapEditorContext.editor.view?.state?.selection?.anchor || 1;
        tiptapEditorContext.editor
          .chain()
          .focus()
          .insertContentAt(insertPoint, emoji, {
            parseOptions: {
              preserveWhitespace: false,
            },
          })
          .run();
      }
    },
    [tiptapEditorContext?.editor],
  );
  const { nonEnglishLanguagesInUse } = useAvailableLanguages();
  const {
    isDropdownVisible: emojiDropdownVisible,
    setIsDropdownVisible: setEmojiDropdownVisible,
    dropdownMenuRef: emojiDropdownRef,
  } = useDropdown();
  return (
    <TooltipProvider>
      <div
        className="flex h-full items-center rounded-b-xl bg-background py-0 pl-2.5 pr-1.5"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-1">
          <RichTextEditorFormattingToolbar />
          {onMediaChange && (
            <Tooltip>
              <TooltipTrigger>
                <AddMediaModal
                  onMediaChange={onMediaChange}
                  title="Add media"
                  trigger={
                    <Button size="icon" variant="ghost">
                      <PhotoOutlineIcon />
                    </Button>
                  }
                />
              </TooltipTrigger>
              <TooltipContent side="bottom">Add media</TooltipContent>
            </Tooltip>
          )}
          <div className="relative">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  onClick={() => {
                    setEmojiDropdownVisible(!emojiDropdownVisible);
                  }}
                  variant={emojiDropdownVisible ? "secondary" : "ghost"}
                >
                  <EmojiOutlineIcon />
                </Button>
              </TooltipTrigger>
              <TooltipContent side="bottom">Emoji</TooltipContent>
              {emojiDropdownVisible && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 40,
                    left: 48,
                    top: -224,
                  }}
                  ref={emojiDropdownRef}
                >
                  <EmojiPicker onEmojiSelect={onEmojiSelect} />
                </div>
              )}
            </Tooltip>
          </div>
          {messageText && setMessageText && (
            <>
              <div className="mx-2 flex self-stretch py-1">
                <Separator orientation="vertical" />
              </div>
              <div className="flex flex-1 justify-between self-stretch">
                <AiTextSuggestionsDropdown
                  messageText={messageText}
                  setMessageText={setMessageText}
                />
                {nonEnglishLanguagesInUse.length > 0 && (
                  <div className="mr-2 flex items-center gap-2">
                    <LanguageIcon />
                    <Label>Translation mode</Label>
                    <Switch
                      size="sm"
                      checked={translationModeEnabled}
                      onCheckedChange={() =>
                        setTranslationModeEnabled?.(!translationModeEnabled)
                      }
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </TooltipProvider>
  );
};

export default EditorActionsOld;
