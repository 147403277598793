import { cn } from "@src/ui/lib/utils";
import React from "react";

type Props = {
  url: string;
  className?: string;
  disabled?: boolean;
};

const ImagePreview: React.FC<Props> = (props) => {
  const onClick = React.useCallback(() => {
    if (!props.disabled) {
      window.open(props.url, "_blank");
    }
  }, [props.disabled, props.url]);
  return (
    <div onClick={onClick} className="cursor-pointer">
      <img
        alt="media"
        src={props.url}
        className={cn(
          "overflow-hidden object-center object-contain size-full max-w-full max-h-max",
          props.className,
        )}
      />
    </div>
  );
};

export default ImagePreview;
