import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { BrandDetailsFragmentDoc, AiOrgContextFragmentDoc } from '../../../hooks/useBranding.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AiOrgContextQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AiOrgContextQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', user: { __typename?: 'Employee', org?: { __typename?: 'Org', id: number, brandDetails?: { __typename?: 'BrandDetails', id: string, brandColor?: string | null, logoUrls?: { __typename?: 'ImageUrls', id: string, original: string, medium?: string | null, thumb?: string | null } | null, aiBrandContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } | null } | null } } | null };

export type UpdateAiOrgContextMutationVariables = Types.Exact<{
  input: Types.UpdateAiOrgContextInput;
}>;


export type UpdateAiOrgContextMutation = { __typename?: 'Mutation', updateAIOrgContext: { __typename?: 'AIOrgContextMutationResult', success: boolean, aiOrgContext?: { __typename?: 'AIOrgContext', id: string, foundingStory: string, missionStatement: string, businessType: string, sells: string, isApproved: boolean } | null } };


export const AiOrgContextDocument = gql`
    query AIOrgContext {
  Me {
    user {
      org {
        id
        brandDetails {
          ...BrandDetails
        }
      }
    }
  }
}
    ${BrandDetailsFragmentDoc}`;

/**
 * __useAiOrgContextQuery__
 *
 * To run a query within a React component, call `useAiOrgContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useAiOrgContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAiOrgContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useAiOrgContextQuery(baseOptions?: Apollo.QueryHookOptions<AiOrgContextQuery, AiOrgContextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AiOrgContextQuery, AiOrgContextQueryVariables>(AiOrgContextDocument, options);
      }
export function useAiOrgContextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AiOrgContextQuery, AiOrgContextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AiOrgContextQuery, AiOrgContextQueryVariables>(AiOrgContextDocument, options);
        }
export type AiOrgContextQueryHookResult = ReturnType<typeof useAiOrgContextQuery>;
export type AiOrgContextLazyQueryHookResult = ReturnType<typeof useAiOrgContextLazyQuery>;
export type AiOrgContextQueryResult = Apollo.QueryResult<AiOrgContextQuery, AiOrgContextQueryVariables>;
export const UpdateAiOrgContextDocument = gql`
    mutation UpdateAIOrgContext($input: UpdateAIOrgContextInput!) {
  updateAIOrgContext(updateAIOrgContextInput: $input) {
    aiOrgContext {
      ...AIOrgContext
    }
    success
  }
}
    ${AiOrgContextFragmentDoc}`;
export type UpdateAiOrgContextMutationFn = Apollo.MutationFunction<UpdateAiOrgContextMutation, UpdateAiOrgContextMutationVariables>;

/**
 * __useUpdateAiOrgContextMutation__
 *
 * To run a mutation, you first call `useUpdateAiOrgContextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAiOrgContextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAiOrgContextMutation, { data, loading, error }] = useUpdateAiOrgContextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAiOrgContextMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAiOrgContextMutation, UpdateAiOrgContextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAiOrgContextMutation, UpdateAiOrgContextMutationVariables>(UpdateAiOrgContextDocument, options);
      }
export type UpdateAiOrgContextMutationHookResult = ReturnType<typeof useUpdateAiOrgContextMutation>;
export type UpdateAiOrgContextMutationResult = Apollo.MutationResult<UpdateAiOrgContextMutation>;
export type UpdateAiOrgContextMutationOptions = Apollo.BaseMutationOptions<UpdateAiOrgContextMutation, UpdateAiOrgContextMutationVariables>;