import { FC, CSSProperties } from "react";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { css, StyleSheet } from "aphrodite";

type Props = {
  value: string;
  onChange: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  onFocus?: () => void;
  style?: CSSProperties;
  autoFocus?: boolean;
};

const SearchBar: FC<Props> = (props: Props) => {
  const {
    value,
    onChange,
    onKeyDown,
    placeholder = "Search",
    className,
    onFocus,
    style,
    autoFocus,
  } = props;

  return (
    <div
      className={`${css(styles.container)} ${className ? className : ""}`}
      style={style}
    >
      <TextField
        text={value}
        leftIcon="search"
        onTextChange={onChange}
        placeholder={placeholder}
        onFocus={onFocus}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 0,
    position: "relative",
    height: 32,
    maxWidth: 500,
  },
  iconStyle: {
    position: "absolute",
    top: 4,
    left: 12,
  },
});

export default SearchBar;
