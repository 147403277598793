import * as React from "react";
import { IconProps } from "../types";

const UsersOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M11.04 14.817C11.877 14.526 12.306 13.56 11.906 12.769C11 10.978 9.145 9.74902 7 9.74902C4.855 9.74902 3 10.977 2.094 12.769C1.694 13.56 2.122 14.526 2.96 14.817C3.991 15.175 5.368 15.5 7 15.5C8.632 15.5 10.009 15.175 11.04 14.817Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.53 13.555C15.124 13.44 15.649 13.286 16.096 13.127C16.798 12.877 17.106 12.05 16.771 11.384C15.985 9.82102 14.369 8.74902 12.5 8.74902C12.245 8.74902 11.995 8.76902 11.752 8.80702"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.953 6.167C12.13 6.212 12.309 6.25 12.5 6.25C13.743 6.25 14.75 5.243 14.75 4C14.75 2.757 13.743 1.75 12.5 1.75C12.166 1.75 11.853 1.832 11.567 1.963"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 7.25C8.51878 7.25 9.75 6.01878 9.75 4.5C9.75 2.98122 8.51878 1.75 7 1.75C5.48122 1.75 4.25 2.98122 4.25 4.5C4.25 6.01878 5.48122 7.25 7 7.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default UsersOutlineIcon;
