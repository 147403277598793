import { DateTime } from "luxon";

export const isPastDue = (item: {
  dueDate?: string | null | undefined;
  completedAt?: string | null | undefined;
}): boolean => {
  if (!item.dueDate || item.completedAt) {
    return false;
  }
  return isInPast(item.dueDate);
};

export const isInPast = (dateStr: string): boolean => {
  return (
    DateTime.fromISO(dateStr).toFormat("yyyy-MM-dd") <
    DateTime.now().toFormat("yyyy-MM-dd")
  );
};

export const isDateTimeInPast = (dateStr: string): boolean => {
  return DateTime.fromISO(dateStr) < DateTime.now();
};

export const msToTimestamp = (ms: number): string => {
  const hours = Math.floor(ms / 3600000);
  const minutes = Math.floor((ms % 3600000) / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);

  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0",
  )}:${String(seconds).padStart(2, "0")}`;
};

export const getBrowserTimezone = (): string => {
  return new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
};
