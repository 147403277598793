import React from "react";

import { useAvailableLanguages } from "@hooks/useAvailableLanguages";
import { langCodeToEnglishName } from "@utils/copy";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@src/ui/select";
import LanguageIcon from "@src/ui/icons/18px/language";

type Props = {
  selectedLanguage: Language;
  onLanguageChange: (language: Language) => void;
};

const LanguageSelect: React.FC<Props> = (props) => {
  const {
    allNonEnglishLanguages,
    nonEnglishLanguagesInUse,
    nonEnglishLanguagesNotInUse,
  } = useAvailableLanguages();
  const hasSections = React.useMemo(
    () =>
      nonEnglishLanguagesInUse.length > 0 &&
      nonEnglishLanguagesNotInUse.length > 0,
    [nonEnglishLanguagesInUse, nonEnglishLanguagesNotInUse],
  );
  return (
    <Select
      value={props.selectedLanguage}
      onValueChange={props.onLanguageChange}
    >
      <SelectTrigger>
        <div className="flex gap-2 items-center">
          <SelectValue
            placeholder={langCodeToEnglishName(props.selectedLanguage)}
          />
        </div>
      </SelectTrigger>
      <SelectContent>
        {hasSections ? (
          <>
            <SelectGroup>
              <SelectLabel>Languages used in your org</SelectLabel>
              {nonEnglishLanguagesInUse.map((language) => (
                <SelectItem key={language} value={language}>
                  {langCodeToEnglishName(language)}
                </SelectItem>
              ))}
            </SelectGroup>
            <SelectGroup>
              <SelectLabel>Other languages</SelectLabel>
              {nonEnglishLanguagesNotInUse.map((language) => (
                <SelectItem key={language} value={language}>
                  {langCodeToEnglishName(language)}
                </SelectItem>
              ))}
            </SelectGroup>
          </>
        ) : (
          <>
            {allNonEnglishLanguages.map((language) => (
              <SelectItem key={language} value={language}>
                {langCodeToEnglishName(language)}
              </SelectItem>
            ))}
          </>
        )}
      </SelectContent>
    </Select>
  );
};

export default LanguageSelect;
