import { TranslationSetInput } from "@src/types.generated";
import { RoleGroupThinFragment } from "@src/hooks/useRoleGroups.generated";

export type Group = {
  id: number;
  name: string;
};

export type Employee = {
  id: number;
  name?: string | null;
};

export const isBlastInValid = (
  allLocations: boolean,
  allTrainees: boolean,
  allManagers: boolean,
  allAdmins: boolean,
  users: Employee[],
  locations: Group[],
  roles: Group[],
  roleGroups: RoleGroupThinFragment[],
  translationSet: TranslationSetInput,
  scheduledTime: Date | undefined | null,
): boolean => {
  if (isTranslationSetEmpty(translationSet)) return true;
  let isInValid = !hasRecipients(
    allLocations,
    allTrainees,
    allManagers,
    allAdmins,
    users,
    locations,
    roles,
    roleGroups,
  );
  if (scheduledTime) {
    isInValid = isInValid || !scheduledTime;
    if (scheduledTime && scheduledTime < new Date()) {
      isInValid = true;
    }
  }
  return isInValid;
};

export const hasRecipients = (
  allLocations: boolean,
  allTrainees: boolean,
  allManagers: boolean,
  allAdmins: boolean,
  users: Employee[],
  locations: Group[],
  roles: Group[],
  roleGroups: RoleGroupThinFragment[],
): boolean => {
  if (users.length > 0) {
    return true;
  } else if (
    locations.length > 0 ||
    roles.length > 0 ||
    roleGroups.length > 0
  ) {
    return true;
  } else if (allLocations || allTrainees || allManagers || allAdmins) {
    return true;
  } else {
    return false;
  }
};

export const confirmCopy = (
  isEdit: boolean,
  isScheduled: boolean,
  isDisabled: boolean,
): string => {
  const action = isScheduled ? "Schedule" : "Send";
  if (isEdit && isScheduled) {
    return "Update message";
  } else if (isDisabled) {
    return `${action} message`;
  } else {
    return ` 🚀 ${action} message`;
  }
};

export function isTranslationSetEmpty(ts: TranslationSetInput): boolean {
  return ts.en === "" || ts.en === "<p></p>";
}
