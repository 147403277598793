import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { FC } from "react";

const MultiSelectProTipBanner: FC = () => {
  return (
    <AutoLayout
      direction="horizontal"
      backgroundColor={deprecatedTones.gray4Alpha}
      paddingVertical={8}
      paddingHorizontal={8}
      paddingRight={12}
      alignmentHorizontal="center"
      alignmentVertical="center"
      spaceBetweenItems={4}
      borderRadius={16}
      marginTop={16}
      marginBottom={12}
    >
      <AutoLayout alignmentVertical="center">
        <DeprecatedIcon type="light-bulb" />
        <Text type="P2" fontWeight="SemiBold">
          Pro tip:
        </Text>
      </AutoLayout>
      <Text type="P2" fontWeight="Regular">
        Multi-select items in the library to grant access in bulk
      </Text>
    </AutoLayout>
  );
};

export default MultiSelectProTipBanner;
