import { gql } from "@apollo/client";
import DetailPage from "@src/components/contentDetail/shared/DetailPage";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { AIAssistForm } from "../../forms/AIAssistForm";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import { BrandSelector } from "@src/components/aiAssist/BrandSelector";
import { useAIAssistBrandSelector } from "@src/hooks/useAIAssistBrandSelector";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";

function AIAssistSettings(): JSX.Element {
  const {
    brandSelectOptions,
    selectedAIBrandContextId: selectedBrandId,
    selectedBrandItem,
    setSelectedAIBrandContextId,
  } = useAIAssistBrandSelector();

  if (!selectedBrandItem) return <Spinner />;
  return (
    <DetailPage>
      <AutoLayout
        direction="vertical"
        width={616}
        alignmentHorizontal="left"
        paddingTop={30}
        paddingBottom={30}
        alignSelf="center"
        spaceBetweenItems={16}
      >
        <Text type="title" style={{ marginBottom: 24 }}>
          AI Assist
        </Text>
        <AutoLayout flex={1} direction={"vertical"} spaceBetweenItems={16}>
          <AutoLayout
            flex={1}
            direction="vertical"
            borderRadius={12}
            width="100%"
            padding={16}
            style={{
              background:
                "linear-gradient(180deg, rgba(189, 130, 250, 0.10) 0%, rgba(219, 188, 220, 0.10) 100%)",
            }}
          >
            <AutoLayout flex={1} direction="horizontal" spaceBetweenItems={40}>
              <AutoLayout
                flex={1}
                direction="vertical"
                paddingBottom={10}
                spaceBetweenItems={12}
              >
                <div className="flex flex-col">
                  <Text
                    type="P1"
                    fontWeight="SemiBold"
                    style={{
                      lineHeight: "22px",
                      paddingBottom: 4,
                    }}
                  >
                    Improve AI results
                  </Text>
                  <Text
                    type="P2"
                    color={deprecatedTones.gray10}
                    style={{ whiteSpace: "normal" }}
                  >
                    Add contextual information about your brand to improve the
                    results of our AI tools. The more we know, the better the
                    results.
                  </Text>
                </div>
                {brandSelectOptions.length && brandSelectOptions.length > 1 && (
                  <AutoLayout direction="vertical" spaceBetweenItems={6}>
                    <Text type="P2" fontWeight="Medium">
                      Select Brand
                    </Text>
                    <AutoLayout
                      flex={1}
                      direction="horizontal"
                      spaceBetweenItems={8}
                    >
                      <BrandSelector
                        defaultValue={selectedBrandId!}
                        brandSelectOptions={brandSelectOptions}
                        onSelectValueChange={(value) => {
                          setSelectedAIBrandContextId(value);
                        }}
                        selectedBrandItem={selectedBrandItem}
                      />
                    </AutoLayout>
                  </AutoLayout>
                )}
              </AutoLayout>
              <AutoLayout
                width={105}
                alignSelf={"stretch"}
                alignmentVertical="top"
                alignmentHorizontal="right"
                direction="horizontal"
              >
                <div
                  style={{
                    boxShadow: "0px 2.286px 6.857px 0px rgba(0, 0, 0, 0.03)",
                    borderRadius: "18px",
                    background: "white",
                    alignItems: "center",
                    display: "flex",
                    padding: "6px",
                  }}
                >
                  {selectedBrandItem.logoUrl ? (
                    <div
                      style={{
                        width: "56px",
                        height: "56px",
                        background: `url(${selectedBrandItem.logoUrl}) lightgray 50% / cover no-repeat`,
                        borderRadius: 64,
                      }}
                    />
                  ) : (
                    <DeprecatedIcon
                      type="registered-trademark"
                      iconSize={54}
                      containerSize={54}
                      color={deprecatedTones.gray10}
                    />
                  )}
                </div>
              </AutoLayout>
            </AutoLayout>
          </AutoLayout>
          <AIAssistForm
            aiOrgContext={selectedBrandItem.aiOrgContext}
            orgBrandName={selectedBrandItem.name}
            brandDetailsId={selectedBrandItem.brandDetailsId}
          />
        </AutoLayout>
      </AutoLayout>
    </DetailPage>
  );
}
export const businessTypeOptions: { label: string; value: string }[] = [
  { label: "Casual Dining", value: "Casual Dining" },
  { label: "Coffee Shop", value: "Coffee Shop" },
  { label: "Entertainment", value: "Entertainment" },
  { label: "Fine Dining", value: "Fine Dining" },
  { label: "Hospitality Group", value: "Hospitality Group" },
  { label: "Pizzeria", value: "Pizzeria" },
  { label: "QSR", value: "QSR" },
  { label: "Other", value: "Other" },
];

export default AIAssistSettings;

gql(`
query AIOrgContext {
    Me {
        user {
            org {
                id
                brandDetails {
                  ...BrandDetails
                }
            }
        }
    }
}
mutation UpdateAIOrgContext($input: UpdateAIOrgContextInput!) {
    updateAIOrgContext(updateAIOrgContextInput: $input) {
        aiOrgContext {
            ...AIOrgContext
        }
        success
    }
}
`);
