import { RefObject, useEffect, useState, useCallback } from "react";

type Args = {
  target: RefObject<HTMLDivElement>;
  onIntersect?: () => void;
  threshold?: number;
  enabled?: boolean;
  root?: Element | Document | null;
  rootMargin?: number;
};

type Return = {
  isInViewport: boolean;
  reobserve: () => void;
};
export default function useIntersectionObserver({
  target,
  onIntersect,
  threshold = 1.0,
  enabled = true,
  root,
  rootMargin,
}: Args): Return {
  const [isInViewport, setIsInViewport] = useState(false);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInViewport(entry.isIntersecting);
        if (entry.isIntersecting && onIntersect) {
          onIntersect();
        }
      },
      {
        root,
        threshold,
        rootMargin: rootMargin ? `${rootMargin}px` : undefined,
      },
    );

    setObserver(observer);

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [
    enabled,
    rootMargin,
    onIntersect,
    target,
    threshold,
    setIsInViewport,
    root,
  ]);
  const reobserve = useCallback(() => {
    if (target && target.current) {
      observer?.unobserve(target.current);
      observer?.observe(target.current);
    }
  }, [observer, target]);
  return { isInViewport, reobserve };
}
