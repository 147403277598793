import * as React from "react";
import { IconProps } from "../types";

const GearOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.25 9.35499V8.64499C16.25 8.13499 15.867 7.70699 15.36 7.65099L14.266 7.52899L13.763 6.31499L14.451 5.45599C14.769 5.05799 14.738 4.48499 14.377 4.12399L13.875 3.62199C13.515 3.26199 12.941 3.22999 12.543 3.54799L11.684 4.23599L10.47 3.73299L10.348 2.63899C10.292 2.13299 9.864 1.74899 9.354 1.74899H8.644C8.134 1.74899 7.706 2.13199 7.65 2.63899L7.528 3.73299L6.314 4.23599L5.455 3.54899C5.057 3.23099 4.484 3.26199 4.123 3.62299L3.621 4.12499C3.261 4.48499 3.229 5.05899 3.547 5.45699L4.235 6.31599L3.732 7.52999L2.638 7.65199C2.132 7.70799 1.748 8.13599 1.748 8.64599V9.35599C1.748 9.86599 2.131 10.294 2.638 10.35L3.732 10.472L4.235 11.686L3.548 12.545C3.23 12.943 3.261 13.517 3.622 13.877L4.124 14.379C4.484 14.739 5.058 14.771 5.456 14.453L6.315 13.765L7.529 14.268L7.651 15.362C7.707 15.868 8.135 16.252 8.645 16.252H9.355C9.865 16.252 10.293 15.869 10.349 15.362L10.471 14.268L11.685 13.765L12.544 14.453C12.942 14.771 13.515 14.74 13.876 14.379L14.378 13.877C14.738 13.517 14.77 12.943 14.452 12.545L13.765 11.686L14.268 10.472L15.362 10.35C15.868 10.294 16.252 9.86599 16.252 9.35599L16.25 9.35499Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default GearOutlineIcon;
