import React, { FC } from "react";
import ClickableIframe from "@components/ui/ClickableIframe";

export const PDF_WIDTH = 180;

type Props = {
  mediaUrl: string;
  clickableIframeProps?: Partial<React.ComponentProps<typeof ClickableIframe>>;
};

const ClickablePdfPreview: FC<Props> = ({ mediaUrl, clickableIframeProps }) => {
  return (
    <ClickableIframe
      src={mediaUrl}
      title="PDF"
      onClick={() => {
        window.open(mediaUrl, "_blank");
      }}
      {...clickableIframeProps}
      style={{
        aspectRatio: "128 / 180",
        width: PDF_WIDTH + 4,
        outline: "none",
        border: "none",
      }}
    />
  );
};

export default ClickablePdfPreview;
