import { FC } from "react";

const AiAssistImage: FC = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44 27H20C18.8954 27 18 27.8954 18 29V35C18 36.1046 18.8954 37 20 37H44C45.1046 37 46 36.1046 46 35V29C46 27.8954 45.1046 27 44 27Z"
        fill="#DDCEF6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 23C24.5 22.1716 25.1716 21.5 26 21.5H28H30C30.8284 21.5 31.5 22.1716 31.5 23C31.5 23.8284 30.8284 24.5 30 24.5H29.5V39.5H30C30.8284 39.5 31.5 40.1716 31.5 41C31.5 41.8284 30.8284 42.5 30 42.5H28H26C25.1716 42.5 24.5 41.8284 24.5 41C24.5 40.1716 25.1716 39.5 26 39.5H26.5V24.5H26C25.1716 24.5 24.5 23.8284 24.5 23Z"
        fill="#986BE4"
      />
    </svg>
  );
};

export default AiAssistImage;
