import * as React from "react";
import { IconProps } from "../types";

const SearchIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M8.5 13.75C11.6756 13.75 14.25 11.1756 14.25 8C14.25 4.82436 11.6756 2.25 8.5 2.25C5.32436 2.25 2.75 4.82436 2.75 8C2.75 11.1756 5.32436 13.75 8.5 13.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9511 12.9511L15.25 15.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default SearchIcon;
