import * as React from "react";
import { IconProps } from "../types";

const DotFilledIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="13"
        height="12"
        fill="none"
        viewBox="0 0 13 12"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M6.5 12C9.81371 12 12.5 9.31371 12.5 6C12.5 2.68629 9.81371 0 6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 9.31371 3.18629 12 6.5 12Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default DotFilledIcon;
