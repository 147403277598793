import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import { Button } from "@ui/button";
import DateTimeField from "@src/deprecatedDesignSystem/components/DateTimeField";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";
import RadioButton from "@src/deprecatedDesignSystem/components/RadioButton";
import useClickOutside from "@hooks/useClickOutside";
import { formatDateTime, getNowForDatetimeLocalHtmlInput } from "@utils/dates";
import { StyleDeclaration, StyleSheet, css } from "aphrodite";
import { FC, useMemo, useRef, useState } from "react";
import ClockOutlineIcon from "@src/ui/icons/18px/clock-outline";
import ChevronsOpenIcon from "@src/ui/icons/12px/chevrons-open";
import { getBrowserTimezone } from "@src/utils/time";

type Props = {
  scheduledDateTime?: Date | null | undefined;
  onScheduledDateTimeChange: (val: Date | null | undefined) => void;
  className?: string;
  styleDeclaration?: StyleDeclaration;
  noDateSelectedLabel?: string;
  messageSchedulingEnabled?: boolean;
  fullWidth?: boolean;
};

const NowOrScheduledPicker: FC<Props> = ({
  scheduledDateTime,
  onScheduledDateTimeChange,
  styleDeclaration,
  noDateSelectedLabel = "Now",
  messageSchedulingEnabled = false,
  fullWidth = false,
}) => {
  const now = useMemo(() => getNowForDatetimeLocalHtmlInput(), []);
  const [menuVisible, setMenuVisible] = useState(false);
  const scheduledDateTimeAsDate = useMemo(() => {
    if (scheduledDateTime) {
      return new Date(scheduledDateTime);
    }
    return scheduledDateTime;
  }, [scheduledDateTime]);

  const ref = useRef(null);
  useClickOutside(ref, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const timezone = getBrowserTimezone();

  return (
    <div
      className={css(
        styles.container,
        styleDeclaration,
        fullWidth && styles.fullWidthContainer,
      )}
    >
      <Button variant="outline" onClick={() => setMenuVisible(true)}>
        <ClockOutlineIcon />
        {scheduledDateTimeAsDate
          ? `${messageSchedulingEnabled ? "Send on " : ""}${formatDateTime(
              scheduledDateTimeAsDate,
            )} ${timezone}`
          : messageSchedulingEnabled
            ? `Send ${noDateSelectedLabel.toLocaleLowerCase()}`
            : noDateSelectedLabel}
        <ChevronsOpenIcon />
      </Button>
      {menuVisible && (
        <div className={css(styles.menu)} ref={ref}>
          <RadioButton
            label={noDateSelectedLabel}
            checked={!scheduledDateTimeAsDate}
            onClick={() => onScheduledDateTimeChange(undefined)}
            styleDeclaration={styles.nowButtonStyles}
          />
          <HorizontalDivider
            styleDeclaration={styles.horizontalDividerStyles}
          />
          <RadioButton
            label="At a later date and time"
            checked={!!scheduledDateTimeAsDate}
            onClick={() => onScheduledDateTimeChange(new Date())}
            styleDeclaration={styles.laterDateButtonStyles}
          />
          <DateTimeField
            value={scheduledDateTimeAsDate}
            onChange={onScheduledDateTimeChange}
            styleDeclaration={[
              styles.dateTimePickerStyles,
              !!scheduledDateTimeAsDate && styles.selected,
            ]}
            min={now}
          />
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  fullWidthContainer: {
    alignSelf: "stretch",
  },
  menu: {
    position: "absolute",
    bottom: 48,
    left: 0,
    width: 260,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: 8,
    backgroundColor: deprecatedTones.white,
    boxShadow: shadows.elevation5,
  },
  horizontalDividerStyles: {
    margin: "8px 0",
  },
  fullWidthButton: {
    width: "100%",
  },
  nowButtonStyles: {
    marginTop: 12,
    marginBottom: 16,
    paddingLeft: 12,
    paddingRight: 12,
  },
  laterDateButtonStyles: {
    marginTop: 16,
    marginBottom: 12,
    paddingLeft: 12,
    paddingRight: 12,
  },
  dateTimePickerStyles: {
    marginLeft: 36,
    marginBottom: 16,
    opacity: 0.3,
    pointerEvents: "none",
  },
  selected: {
    opacity: 1,
    pointerEvents: "auto",
  },
});

export default NowOrScheduledPicker;
