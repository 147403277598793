import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { useAvailableLanguages } from "@hooks/useAvailableLanguages";
import { langCodeToEnglishName } from "@utils/copy";
import { StyleSheet, css } from "aphrodite";
import { FC, useMemo } from "react";

type Props = {
  selectedLanguage: Language;
  onLanguageChange: (language: Language) => void;
};

const LanguageSelectorDropdown: FC<Props> = ({
  selectedLanguage,
  onLanguageChange,
}) => {
  const {
    allNonEnglishLanguages,
    nonEnglishLanguagesInUse,
    nonEnglishLanguagesNotInUse,
  } = useAvailableLanguages();
  const hasSections = useMemo(
    () =>
      nonEnglishLanguagesInUse.length > 0 &&
      nonEnglishLanguagesNotInUse.length > 0,
    [nonEnglishLanguagesInUse, nonEnglishLanguagesNotInUse],
  );
  return (
    <div className={css(styles.selectContainer)}>
      <select
        className={css(styles.select)}
        value={selectedLanguage}
        onChange={(e) => onLanguageChange(e.target.value as Language)}
        onBlur={(e) => onLanguageChange(e.target.value as Language)}
      >
        {hasSections ? (
          <>
            <optgroup label="Languages used in your org">
              {nonEnglishLanguagesInUse.map((language) => (
                <option key={language} value={language}>
                  {langCodeToEnglishName(language)}
                </option>
              ))}
            </optgroup>
            <optgroup label="Other languages">
              {nonEnglishLanguagesNotInUse.map((language) => (
                <option key={language} value={language}>
                  {langCodeToEnglishName(language)}
                </option>
              ))}
            </optgroup>
          </>
        ) : (
          <>
            {allNonEnglishLanguages.map((language) => (
              <option key={language} value={language}>
                {langCodeToEnglishName(language)}
              </option>
            ))}
          </>
        )}
      </select>
      <DeprecatedIcon
        type="chevron-down"
        color={deprecatedTones.white}
        styleDeclaration={styles.caret}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  selectContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  select: {
    fontSize: 14,
    color: deprecatedColors.onPrimary,
    fontWeight: 500,
    backgroundColor: deprecatedTones.blue9,
    border: `1px solid ${deprecatedTones.white}`,
    borderRadius: 8,
    padding: "0 32px 0 12px",
    height: 32,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    appearance: "none",
    position: "relative",
    outline: "none",
    cursor: "pointer",
  },
  caret: {
    position: "absolute",
    right: 4,
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  },
});

export default LanguageSelectorDropdown;
