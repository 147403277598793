import { BrandSelectOption } from "@src/hooks/useAIAssistBrandSelector";
import { AIAssistForm } from "@src/components/forms/AIAssistForm";
import { AIAssistBaseModal } from "./AIAssistBaseModal";

export type AIAssistContextModalProps = {
  brandSelectOption: BrandSelectOption;
  closeModal: () => void;
};

export const AIAssistContextModal: React.FC<AIAssistContextModalProps> = ({
  closeModal,
  brandSelectOption,
}) => {
  return (
    <AIAssistBaseModal
      brandSelectOption={brandSelectOption}
      closeModal={closeModal}
      title={"AI Assist Context"}
      renderForm={function (
        closeModal: () => void,
        brandSelectOption: BrandSelectOption,
      ): JSX.Element {
        return (
          <AIAssistForm
            aiOrgContext={brandSelectOption.aiOrgContext}
            onCancel={closeModal}
            onSaveDone={closeModal}
            orgBrandName={brandSelectOption.name}
            brandDetailsId={brandSelectOption.brandDetailsId}
          />
        );
      }}
    />
  );
};

export default AIAssistContextModal;
