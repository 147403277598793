import React from "react";
import ClickablePdfPreview from "../libraryItemDetailPages/course/ClickablePdfPreview";

type Props = {
  mediaUrl: string;
};

const PdfPreview: React.FC<Props> = ({ mediaUrl }) => {
  return (
    <div className="flex flex-col">
      <div className="flex aspect-[128/180] w-[180px] items-center justify-center overflow-hidden rounded-2xl border border-gray-3">
        <ClickablePdfPreview mediaUrl={mediaUrl} />
      </div>
    </div>
  );
};

export default PdfPreview;
