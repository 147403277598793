import { CSSProperties, FC } from "react";
import { css, StyleSheet, StyleDeclaration } from "aphrodite";
import { deprecatedTones } from "../styles/deprecatedColors";
import { shadows } from "../styles/shadows";

type KnobSize = "24px" | "28px" | "50px";

type Props = {
  selected: boolean;
  onClick: () => void;
  manualKey?: string;
  disabled?: boolean;
  size?: KnobSize;
  type?: "fill" | "outline";
  id?: string;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
};

const ToggleButton: FC<Props> = ({
  selected,
  onClick,
  manualKey = "toggle-button",
  disabled = false,
  type = "fill",
  size = "28px",
  id,
  styleDeclaration,
  style,
}) => {
  const containerKnobSize = {
    "24px": "10px",
    "28px": "12px",
    "50px": "20px",
  };

  return (
    <div
      id={id}
      className={css(
        styles.container,
        selected &&
          disabled &&
          type === "fill" &&
          styles.selectedDisabledFillContainer,
        selected &&
          !disabled &&
          type === "fill" &&
          styles.selectedNotDisabledFillContainer,
        !selected &&
          !disabled &&
          type === "fill" &&
          styles.notSelectedNotDisabledFillContainer,
        !selected &&
          disabled &&
          type === "fill" &&
          styles.notSelectedDisabledFillContainer,
        selected &&
          disabled &&
          type === "outline" &&
          styles.selectedDisabledOutlineContainer,
        selected &&
          !disabled &&
          type === "outline" &&
          styles.selectedNotDisabledOutlineContainer,
        !selected &&
          !disabled &&
          type === "outline" &&
          styles.notSelectedNotDisabledOutlineContainer,
        !selected &&
          disabled &&
          type === "outline" &&
          styles.notSelectedDisabledOutlineContainer,
        styleDeclaration,
      )}
      style={{ boxSizing: "border-box", width: size, ...style }}
      onClick={disabled ? () => {} : onClick}
    >
      <div
        key={manualKey}
        className={css(
          styles.knob,
          !selected && styles.unselectedKnobPosition,
          selected && size === "24px" && styles.selected24pxKnobPosition,
          selected && size === "28px" && styles.selected28pxKnobPosition,
          selected && size === "50px" && styles.selected50pxKnobPosition,
          selected &&
            disabled &&
            type === "fill" &&
            styles.selectedDisabledFillKnob,
          selected &&
            !disabled &&
            type === "fill" &&
            styles.selectedNotDisabledFillKnob,
          !selected &&
            !disabled &&
            type === "fill" &&
            styles.notSelectedNotDisabledFillKnob,
          !selected &&
            disabled &&
            type === "fill" &&
            styles.notSelectedDisabledFillKnob,
          selected &&
            disabled &&
            type === "outline" &&
            styles.selectedDisabledOutlineKnob,
          selected &&
            !disabled &&
            type === "outline" &&
            styles.selectedNotDisabledOutlineKnob,
          !selected &&
            !disabled &&
            type === "outline" &&
            styles.notSelectedNotDisabledOutlineKnob,
          !selected &&
            disabled &&
            type === "outline" &&
            styles.notSelectedDisabledOutlineKnob,
        )}
        style={{
          boxSizing: "border-box",
          width: containerKnobSize[size],
          height: containerKnobSize[size],
        }}
      />
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 2,
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 50,
    transitionProperty: "background-color border-color",
    transitionDuration: "0.2s",
    transitionTimingFunction: "cubic-bezier(0.45, 0.25, 0.25, 1)",
    pointerEvents: "auto",
    cursor: "pointer",
  },
  knob: {
    borderRadius: 20,
    transitionProperty: "background-color box-shadow transform",
    transitionDuration: "0.2s",
    transitionTimingFunction: "cubic-bezier(0.45, 0.25, 0.25, 1)",
    boxShadow:
      "0px 0px 2px rgba(142, 145, 146, 0.3), 0px 0px 6px 2px rgba(142, 145, 146, 0.15)",
  },
  selectedNotDisabledFillContainer: {
    backgroundColor: deprecatedTones.blue9,
    borderColor: "transparent",
  },
  notSelectedDisabledFillContainer: {
    backgroundColor: deprecatedTones.gray5,
    borderColor: "transparent",
    pointerEvents: "none",
    cursor: "auto",
  },
  selectedDisabledFillContainer: {
    backgroundColor: deprecatedTones.blue3,
    borderColor: "transparent",
    pointerEvents: "none",
    cursor: "auto",
  },
  notSelectedNotDisabledFillContainer: {
    backgroundColor: deprecatedTones.gray5Alpha,
    borderColor: "transparent",
  },
  notSelectedNotDisabledOutlineContainer: {
    borderColor: deprecatedTones.blue5,
  },
  notSelectedDisabledOutlineContainer: {
    backgroundColor: deprecatedTones.white,
    borderColor: deprecatedTones.gray5Alpha,
  },
  selectedDisabledOutlineContainer: {
    backgroundColor: deprecatedTones.blue3,
    borderColor: "transparent",
    pointerEvents: "none",
    cursor: "auto",
  },
  selectedNotDisabledOutlineContainer: {
    backgroundColor: deprecatedTones.blue9,
    borderColor: "transparent",
    pointerEvents: "auto",
    cursor: "pointer",
  },
  unselectedKnobPosition: {
    transform: "translateX(0)",
  },
  selected24pxKnobPosition: {
    transform: "translateX(6px)",
  },
  selected28pxKnobPosition: {
    transform: "translateX(8px)",
  },
  selected50pxKnobPosition: {
    transform: "translateX(22px)",
  },
  notSelectedDisabledFillKnob: {
    backgroundColor: deprecatedTones.gray4Alpha,
    boxShadow: "none",
  },
  notSelectedNotDisabledFillKnob: {
    backgroundColor: deprecatedTones.white,
    boxShadow: shadows.elevation2,
  },
  selectedDisabledFillKnob: {
    backgroundColor: deprecatedTones.white,
    boxShadow: "none",
  },
  selectedNotDisabledFillKnob: {
    backgroundColor: deprecatedTones.white,
    boxShadow: shadows.elevation2,
  },
  notSelectedDisabledOutlineKnob: {
    backgroundColor: deprecatedTones.gray5Alpha,
    boxShadow: "none",
  },
  notSelectedNotDisabledOutlineKnob: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  selectedDisabledOutlineKnob: {
    backgroundColor: deprecatedTones.white,
    boxShadow: "none",
  },
  selectedNotDisabledOutlineKnob: {
    backgroundColor: deprecatedTones.white,
    boxShadow: "none",
  },
});

export default ToggleButton;
