import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";

import { FC } from "react";
import { FileInput } from "./BulkUploadTrainingResourceModal";
import { UploadedFile } from "./UploadedFile";

type Props = {
  files: FileInput[];
  updateFileName: (id: string, language: Language, value: string) => void;
  updateFileDescription: (
    id: string,
    language: Language,
    value: string,
  ) => void;
  removeFile: (id: string) => void;
  language: Language;
};
export const UploadedFileList: FC<Props> = ({
  files,
  updateFileDescription,
  updateFileName,
  removeFile,
  language,
}) => {
  return (
    <AutoLayout
      direction="vertical"
      padding={12}
      flex={1}
      spaceBetweenItems={12}
    >
      {files.map((file) => {
        return (
          <UploadedFile
            key={file.id}
            file={file}
            updateFileName={updateFileName}
            updateFileDescription={updateFileDescription}
            removeFile={removeFile}
            language={language}
          />
        );
      })}
    </AutoLayout>
  );
};
