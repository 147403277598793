import React, { FC, IframeHTMLAttributes } from "react";
import { OgData } from "@src/components/libraryItemDetailPages/course/OpenGraphLinkPreview";

type Props = {
  mediaUrl: string;
  ogData: OgData | undefined;
  ogVideoIframeProps?: Partial<IframeHTMLAttributes<HTMLIFrameElement>>;
};

const OpenGraphVideoPlayerIframe: FC<Props> = (props) => {
  const ogVideoUrl = props.ogData?.["og:video:url"] || props.mediaUrl;
  if (!ogVideoUrl) return null;
  const videoHeight = props.ogData?.["og:video:height"] || 1;
  const videoWidth = props.ogData?.["og:video:width"] || 1;
  return (
    <iframe
      id={"video-iframe"}
      key={ogVideoUrl}
      title={props.ogData?.["og:title"]}
      src={ogVideoUrl}
      {...props.ogVideoIframeProps}
      style={{
        borderRadius: 12,
        outline: "none",
        border: "none",
        cursor: "pointer",
        alignSelf: "stretch",
        aspectRatio: `${videoWidth}/${videoHeight}`,
      }}
    />
  );
};

export default OpenGraphVideoPlayerIframe;
