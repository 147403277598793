import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillingInitializedBillingAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BillingInitializedBillingAccountsQuery = { __typename?: 'Query', BillingAccounts: Array<{ __typename?: 'BillingAccount', id: string }> };


export const BillingInitializedBillingAccountsDocument = gql`
    query BillingInitializedBillingAccounts {
  BillingAccounts(input: {}) {
    id
  }
}
    `;

/**
 * __useBillingInitializedBillingAccountsQuery__
 *
 * To run a query within a React component, call `useBillingInitializedBillingAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingInitializedBillingAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingInitializedBillingAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingInitializedBillingAccountsQuery(baseOptions?: Apollo.QueryHookOptions<BillingInitializedBillingAccountsQuery, BillingInitializedBillingAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingInitializedBillingAccountsQuery, BillingInitializedBillingAccountsQueryVariables>(BillingInitializedBillingAccountsDocument, options);
      }
export function useBillingInitializedBillingAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingInitializedBillingAccountsQuery, BillingInitializedBillingAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingInitializedBillingAccountsQuery, BillingInitializedBillingAccountsQueryVariables>(BillingInitializedBillingAccountsDocument, options);
        }
export type BillingInitializedBillingAccountsQueryHookResult = ReturnType<typeof useBillingInitializedBillingAccountsQuery>;
export type BillingInitializedBillingAccountsLazyQueryHookResult = ReturnType<typeof useBillingInitializedBillingAccountsLazyQuery>;
export type BillingInitializedBillingAccountsQueryResult = Apollo.QueryResult<BillingInitializedBillingAccountsQuery, BillingInitializedBillingAccountsQueryVariables>;