import { BrandSelectOption } from "@src/hooks/useAIAssistBrandSelector";
import { AIAssistForm } from "@src/components/forms/AIAssistForm";
import { AIAssistBaseModal } from "./AIAssistBaseModal";
import { AIAssistContextModalProps } from "./AIAssistContextModal";

export const AIAssistReviewContextModal: React.FC<
  AIAssistContextModalProps
> = ({ closeModal, brandSelectOption }) => {
  return (
    <AIAssistBaseModal
      brandSelectOption={brandSelectOption}
      closeModal={closeModal}
      title={"Review AI Assist Context"}
      renderForm={function (
        closeModal: () => void,
        brandSelectOption: BrandSelectOption,
      ): JSX.Element {
        return (
          <AIAssistForm
            aiOrgContext={brandSelectOption.aiOrgContext}
            onSaveDone={closeModal}
            orgBrandName={brandSelectOption.name}
            brandDetailsId={brandSelectOption.brandDetailsId}
          />
        );
      }}
    />
  );
};
