import { ImageUrlsFragment, MediaFragment } from "@src/fragments.generated";
import { CoverImageFragment } from "@src/fragments.generated";

export const getThumbnailUrl = (media: MediaFragment): string => {
  return (
    media.imageUrls?.thumb ||
    media.imageUrls?.medium ||
    media.imageUrls?.original ||
    media.mediaUrls.en
  );
};

export const getImageThumbnailUrl = (imageUrls: ImageUrlsFragment): string => {
  return (
    [imageUrls.thumb, imageUrls.medium, imageUrls.original].find(
      (url) => !!url,
    ) || ""
  );
};

export const getPreviewImageUrl = (
  coverImage: Pick<CoverImageFragment, "imageUrls">,
): string => {
  return coverImage.imageUrls?.medium || coverImage.imageUrls?.original || "";
};
