import * as React from "react";
import { IconProps } from "../types";

const TextSizeIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="19"
        height="18"
        fill="none"
        viewBox="0 0 19 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M9.818 13.25L6.248 4.75H6.07L2.5 13.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.34 11.25H8.978"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.234 8.234C12.72 7.711 13.412 7.5 14.115 7.5C15.294 7.5 16.249 8.456 16.249 9.634V13.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 10.25H16.25V10.5C16.25 12.018 15.018 13.25 13.5 13.25C12.672 13.25 12 12.578 12 11.75C12 10.922 12.672 10.25 13.5 10.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  },
);

export default TextSizeIcon;
