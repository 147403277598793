import {
  CSSProperties,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import RelativePortal from "react-relative-portal";
import { css, StyleSheet } from "aphrodite";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";

type Props = {
  component?: ReactNode | null;
  text?: string;
  children: ReactNode;
  style?: CSSProperties;
  placement?: "top" | "bottom" | "right";
  horizontalOffset?: number;
  verticalOffset?: number;
  arrow?: boolean;
  shouldHide?: boolean;
  delay?: number;
};

const DEFAULT_VERTICAL_OFFSET = 44;

export const Tooltip: FC<Props> = ({
  children,
  component,
  text,
  style = {},
  placement = "top",
  horizontalOffset = 8,
  verticalOffset = 0,
  arrow = false,
  shouldHide = false,
  delay = 300,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState<number>();
  const onMouseEnter = useCallback(() => {
    const timeoutId: number = window.setTimeout(() => {
      setVisible(true);
    }, delay);
    setTimeoutId(timeoutId);
  }, [delay]);
  const onMouseLeave = useCallback(() => {
    clearTimeout(timeoutId);
    setVisible(false);
  }, [timeoutId]);

  const top = useMemo(() => {
    if (placement === "top") {
      return -DEFAULT_VERTICAL_OFFSET - verticalOffset;
    } else if (placement === "bottom") {
      return DEFAULT_VERTICAL_OFFSET + verticalOffset;
    }
    return verticalOffset;
  }, [placement, verticalOffset]);

  const left = useMemo(() => {
    const horizontalOffsetInPx = `${horizontalOffset}px`;
    if (placement === "right") {
      return `calc(100% + ${horizontalOffsetInPx})`;
    }
    return horizontalOffset;
  }, [placement, horizontalOffset]);

  return (
    <span
      style={{ position: "relative", ...style }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {visible && !shouldHide && (component || text) && (
        <span
          style={{
            position: "absolute",
            top,
            left,
            cursor: "unset",
            zIndex: 1000,
          }}
        >
          {/* @ts-ignore-next-line */}
          <RelativePortal component="div">
            {component ? (
              <div
                className={css(
                  styles.tooltip,
                  arrow && [
                    styles.arrow,
                    ["top", "bottom"].includes(placement) &&
                      styles.verticalTooltip,
                    placement === "top" && styles.arrowTop,
                    placement === "bottom" && styles.arrowBottom,
                    placement === "right" && styles.arrowRight,
                  ],
                )}
              >
                {component}
              </div>
            ) : (
              <div
                className={css(
                  styles.tooltip,
                  arrow && [
                    styles.arrow,
                    ["top", "bottom"].includes(placement) &&
                      styles.verticalTooltip,
                    placement === "top" && styles.arrowTop,
                    placement === "bottom" && styles.arrowBottom,
                    placement === "right" && styles.arrowRight,
                  ],
                )}
              >
                <Text
                  type="P2"
                  fontWeight="Medium"
                  color={deprecatedTones.black}
                >
                  {text}
                </Text>
              </div>
            )}
          </RelativePortal>
        </span>
      )}
      {children}
    </span>
  );
};

const styles = StyleSheet.create({
  tooltip: {
    position: "absolute",
    backgroundColor: deprecatedColors.background,
    color: deprecatedColors.background,
    padding: "8px 12px",
    whiteSpace: "nowrap",
    boxShadow: shadows.dropdownShadow,
    borderRadius: "12px",
    marginLeft: "auto",
    marginRight: "auto",
    left: "50%",
    zIndex: 10000,
  },
  verticalTooltip: {
    transform: "translateX(-50%)",
  },
  arrow: {
    "::after": {
      zIndex: 10000,
      content: '""',
      position: "absolute",
      marginLeft: "-6px",
      width: "0",
      height: "0",
      border: "solid 6px transparent",
    },
  },
  arrowTop: {
    "::after": {
      left: "50%",
      top: "100%",
      borderTop: `solid 6px ${deprecatedColors.background}`,
    },
  },
  arrowBottom: {
    "::after": {
      left: "50%",
      bottom: "100%",
      borderBottom: `solid 6px ${deprecatedColors.background}`,
    },
  },
  arrowRight: {
    "::after": {
      right: "100%",
      marginTop: -12,
      borderRight: `solid 6px ${deprecatedColors.background}`,
    },
  },
  arrowLeft: {
    "::after": {
      left: "100%",
      marginTop: 2,
      borderLeft: `solid 6px ${deprecatedColors.onBackground}`,
    },
  },
});

export default Tooltip;
