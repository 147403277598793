import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import { MediaFragmentDoc, TranslationSetFragmentDoc, CoverImageFragmentDoc, ContentTagMembershipFragmentDoc, ContentQrCodeFragmentDoc, ContentSharingPermissionFragmentDoc, AutomationFragmentDoc, ImageUrlsFragmentDoc, AiGenerateModuleRequestFragmentDoc, UserSelectionFragmentDoc, PathInstanceThinFragmentDoc, BlastSentRecordFragmentDoc, MyTranslationSetFragmentDoc } from '../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageBlastModalCreateMediaMutationVariables = Types.Exact<{
  input: Types.MediaInput;
}>;


export type MessageBlastModalCreateMediaMutation = { __typename?: 'Mutation', createMedia: { __typename?: 'MediaMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, media?: { __typename?: 'Media', id: number, loopVideo: boolean, hideVideoControls: boolean, unoptimizedUrl?: string | null, createdAt: string, updatedAt: string, thumbnailImageUrl?: string | null, name?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, description?: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> } | null, mediaUrls: { __typename?: 'TranslationSet', uuid: string, en: string, translationsDisabled?: boolean | null, translationOverrides: Array<{ __typename?: 'TranslationOverride', id: string, text: string, sourceText: string, language: Language }> }, imageUrls?: { __typename?: 'ImageUrls', id: string, original: string, wide?: string | null, medium?: string | null, thumb?: string | null } | null, trainingResource?: { __typename?: 'TrainingResource', id: string } | null } | null } };


export const MessageBlastModalCreateMediaDocument = gql`
    mutation MessageBlastModalCreateMedia($input: MediaInput!) {
  createMedia(input: $input) {
    success
    error {
      code
    }
    media {
      ...Media
    }
  }
}
    ${MediaFragmentDoc}`;
export type MessageBlastModalCreateMediaMutationFn = Apollo.MutationFunction<MessageBlastModalCreateMediaMutation, MessageBlastModalCreateMediaMutationVariables>;

/**
 * __useMessageBlastModalCreateMediaMutation__
 *
 * To run a mutation, you first call `useMessageBlastModalCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageBlastModalCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageBlastModalCreateMediaMutation, { data, loading, error }] = useMessageBlastModalCreateMediaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageBlastModalCreateMediaMutation(baseOptions?: Apollo.MutationHookOptions<MessageBlastModalCreateMediaMutation, MessageBlastModalCreateMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageBlastModalCreateMediaMutation, MessageBlastModalCreateMediaMutationVariables>(MessageBlastModalCreateMediaDocument, options);
      }
export type MessageBlastModalCreateMediaMutationHookResult = ReturnType<typeof useMessageBlastModalCreateMediaMutation>;
export type MessageBlastModalCreateMediaMutationResult = Apollo.MutationResult<MessageBlastModalCreateMediaMutation>;
export type MessageBlastModalCreateMediaMutationOptions = Apollo.BaseMutationOptions<MessageBlastModalCreateMediaMutation, MessageBlastModalCreateMediaMutationVariables>;