import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@src/hooks/useToast";
import { launchConfetti } from "@src/utils/confetti";
import { useForm } from "react-hook-form";
import { Form, FormField } from "@src/ui/form";
import Button from "@src/deprecatedDesignSystem/components/Button";
import { InlineTextAreaField } from "../formFields/InlineTextAreaField";
import { InlineSelectField } from "../formFields/InlineSelectField";
import { z } from "zod";
import useActionPermissions from "@src/hooks/useActionPermissions";
import { AiOrgContextFragment } from "@src/hooks/useBranding.generated";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { useUpdateAiOrgContextMutation } from "../settings/aiAssist/AIAssistSettings.generated";
import { businessTypeOptions } from "../settings/aiAssist/AIAssistSettings";
import { useEffect } from "react";
import { gql } from "graphql-request";
import {
  useScrapeFoundingStoryMutation,
  useScrapeMissionStatementMutation,
  useScrapeSellsMutation,
} from "./AIAssistForm.generated";

export const AIAssistForm = ({
  aiOrgContext,
  orgBrandName,
  onCancel,
  onSaveDone,
  brandDetailsId,
}: {
  aiOrgContext: AiOrgContextFragment;
  orgBrandName?: string | null;
  onCancel?: () => void;
  onSaveDone?: () => void;
  brandDetailsId: string;
}): JSX.Element => {
  const { userActionPermissions } = useActionPermissions();
  const { addErrorToast } = useToast();
  const isReadOnly = userActionPermissions?.changeOrgSettings ? false : true;
  const [updateAIOrgContext, { loading }] = useUpdateAiOrgContextMutation({
    onCompleted: (res) => {
      if (res.updateAIOrgContext.success) {
        launchConfetti();
      } else {
        addErrorToast({ ...res, callsite: "update_ai_org_context" });
      }
    },
  });
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      businessType: aiOrgContext.businessType,
      foundingStory: aiOrgContext.foundingStory,
      missionStatement: aiOrgContext.missionStatement,
      sells: aiOrgContext.sells,
    },
  });
  useEffect(() => {
    form.reset({
      businessType: aiOrgContext.businessType,
      foundingStory: aiOrgContext.foundingStory,
      missionStatement: aiOrgContext.missionStatement,
      sells: aiOrgContext.sells,
    });
  }, [
    aiOrgContext.businessType,
    aiOrgContext.foundingStory,
    aiOrgContext.missionStatement,
    aiOrgContext.sells,
    form,
  ]);
  const [scrapeFoundingStory, { loading: scrapeFoundingStoryLoading }] =
    useScrapeFoundingStoryMutation();
  const [scrapeMissionStatement, { loading: scrapeMissionStatementLoading }] =
    useScrapeMissionStatementMutation();
  const [scrapeSells, { loading: scrapeSellsLoading }] =
    useScrapeSellsMutation();

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          updateAIOrgContext({
            variables: {
              input: {
                id: aiOrgContext.id,
                businessType: {
                  value: data.businessType ?? "",
                },
                foundingStory: {
                  value: data.foundingStory ?? "",
                },
                sells: {
                  value: data.sells ?? "",
                },
                missionStatement: {
                  value: data.missionStatement ?? "",
                },
                isApproved: {
                  value: true,
                },
              },
            },
          });
          onSaveDone?.();
        })}
        className="inline-flex w-full flex-col items-end justify-center gap-4"
      >
        <FormField
          disabled={isReadOnly}
          name="businessType"
          render={({ field }) => (
            <InlineSelectField
              label={`What type of business is ${orgBrandName}`}
              options={businessTypeOptions}
              onChange={field.onChange}
              defaultValue={field.value}
              disabled={field.disabled}
            />
          )}
          control={form.control}
        />
        <FormField
          name="foundingStory"
          disabled={isReadOnly || scrapeFoundingStoryLoading}
          render={({ field }) => {
            return (
              <>
                <InlineTextAreaField
                  {...field}
                  label={`What is the founding story of ${orgBrandName}`}
                />
                <Button
                  text="Generate with AI"
                  variant="AI"
                  leftIcon="sparkles"
                  loading={scrapeFoundingStoryLoading}
                  onClick={() => {
                    scrapeFoundingStory({
                      variables: {
                        brandDetailsId,
                      },
                    }).then((res) => {
                      if (res.data?.scrapeAIBrandContextFoundingStory.value) {
                        form.setValue(
                          "foundingStory",
                          res.data?.scrapeAIBrandContextFoundingStory?.value,
                        );
                      }
                    });
                  }}
                />
              </>
            );
          }}
          control={form.control}
        />
        <FormField
          name="missionStatement"
          disabled={isReadOnly}
          render={({ field }) => (
            <>
              <InlineTextAreaField
                {...field}
                label={`What is the mission statement for ${orgBrandName}`}
              />
              <Button
                text="Generate with AI"
                variant="AI"
                leftIcon="sparkles"
                loading={scrapeMissionStatementLoading}
                onClick={() => {
                  scrapeMissionStatement({
                    variables: {
                      brandDetailsId,
                    },
                  }).then((res) => {
                    if (res.data?.scrapeAIBrandContextMissionStatement.value) {
                      form.setValue(
                        "missionStatement",
                        res.data?.scrapeAIBrandContextMissionStatement?.value,
                      );
                    }
                  });
                }}
              />
            </>
          )}
          control={form.control}
        />
        <FormField
          name="sells"
          disabled={isReadOnly}
          render={({ field }) => (
            <>
              <InlineTextAreaField
                {...field}
                label={`What does ${orgBrandName} sell?`}
              />
              <Button
                text="Generate with AI"
                loading={scrapeSellsLoading}
                variant="AI"
                leftIcon="sparkles"
                onClick={() => {
                  scrapeSells({
                    variables: {
                      brandDetailsId,
                    },
                  }).then((res) => {
                    if (res.data?.scrapeAIBrandContextSells.value) {
                      form.setValue(
                        "sells",
                        res.data?.scrapeAIBrandContextSells?.value,
                      );
                    }
                  });
                }}
              />
            </>
          )}
          control={form.control}
        />
        <AutoLayout
          width={"100%"}
          flex={1}
          marginTop={32}
          direction="horizontal"
          spaceBetweenItems={12}
        >
          {onCancel && (
            <Button
              text="Cancel"
              onClick={onCancel}
              variant="Outline"
              style={{
                height: "40px",
                width: "94px",
              }}
            />
          )}
          <Button
            disabled={isReadOnly}
            variant={"Primary"}
            text={"Save"}
            loading={loading}
            type="submit"
            style={{
              width: "132px",
              height: "40px",
              padding: "4px 8px",
              borderRadius: "8px",
            }}
          />
        </AutoLayout>
      </form>
    </Form>
  );
};

export const formSchema = z.object({
  businessType: z.string().optional(),
  foundingStory: z.string().optional(),
  missionStatement: z.string().optional(),
  sells: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

gql`
  mutation ScrapeFoundingStory($brandDetailsId: UUID!) {
    scrapeAIBrandContextFoundingStory(brandDetailsId: $brandDetailsId) {
      success
      value
    }
  }

  mutation ScrapeSells($brandDetailsId: UUID!) {
    scrapeAIBrandContextSells(brandDetailsId: $brandDetailsId) {
      success
      value
    }
  }

  mutation ScrapeMissionStatement($brandDetailsId: UUID!) {
    scrapeAIBrandContextMissionStatement(brandDetailsId: $brandDetailsId) {
      success
      value
    }
  }
`;
