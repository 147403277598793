import React from "react";

import Text from "@ui/text";
import { Input } from "@ui/input";
import { updateTranslationSet } from "@src/utils/translationSets";
import { TranslationSetObjectInput } from "@src/types.generated";
import { TranslationSetFragment } from "@src/fragments.generated";
import RichTextEditorWithEditorActions from "@src/components/messaging/RichTextEditorWithEditorActions";
import { SentMessage_MessageBlastMediaFragment } from "./SentMessage.generated";

type Props = {
  subjectText: TranslationSetFragment;
  subjectTranslation?: string | null;
  setSubjectText: (x: TranslationSetObjectInput) => void;
  messageText: TranslationSetFragment;
  messageTranslation?: string | null;
  setMessageText: (x: TranslationSetObjectInput) => void;
  pinnedMedia: SentMessage_MessageBlastMediaFragment | null;
  messageBlastMedias: Set<SentMessage_MessageBlastMediaFragment>;
  setMessageBlastMedias: (
    newSet: Set<SentMessage_MessageBlastMediaFragment>,
  ) => void;
  isTranslationModeEnabled: boolean;
  setIsTranslationModeEnabled: (x: boolean) => void;
  nonEnglishLanguage: Language;
  setNonEnglishLanguage: (x: Language) => void;
};

const MessageBlastSubjectAndMessage: React.FC<Props> = (props) => {
  const [isEnglishEditorActive, setIsEnglishEditorActive] =
    React.useState(false);
  const [isTranslationEditorActive, setIsTranslationEditorActive] =
    React.useState(false);
  const handleSubjectTextChange = React.useCallback(
    (text: string) => {
      const lang = props.isTranslationModeEnabled
        ? props.nonEnglishLanguage
        : "en";
      props.setSubjectText(
        updateTranslationSet({
          ts: props.subjectText,
          lang,
          text,
        }),
      );
    },
    [props],
  );
  const handleMessageTextChange = React.useCallback(
    (text: string) => {
      const lang = props.isTranslationModeEnabled
        ? props.nonEnglishLanguage
        : "en";
      props.setMessageText(
        updateTranslationSet({
          ts: props.messageText,
          lang,
          text,
        }),
      );
    },
    [props],
  );
  return (
    <div className="flex flex-col gap-2 pb-3">
      <Text type="P2" className="mt-2" fontWeight="SemiBold">
        Your message
      </Text>
      {!props.isTranslationModeEnabled ? (
        <div className="flex flex-col gap-2 self-stretch">
          <Input
            placeholder="Subject (Optional)"
            value={props.subjectText["en"]}
            onChange={(e) => handleSubjectTextChange(e.target.value)}
          />
          <RichTextEditorWithEditorActions
            value={props.messageText["en"]}
            placeholder={"Type your message here..."}
            setIsEditorActive={setIsEnglishEditorActive}
            isEditorActive={isEnglishEditorActive}
            onChange={handleMessageTextChange}
            setTranslationModeEnabled={props.setIsTranslationModeEnabled}
            translationModeEnabled={props.isTranslationModeEnabled}
            setMessageBlastMedias={props.setMessageBlastMedias}
            messageBlastMedias={props.messageBlastMedias}
            setMessageText={props.setMessageText}
            messageText={props.messageText}
            pinnedMedia={props.pinnedMedia}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-2 self-stretch">
          <Input
            placeholder="Subject (Optional)"
            value={props.subjectTranslation || ""}
            onChange={(e) => handleSubjectTextChange(e.target.value)}
          />
          <RichTextEditorWithEditorActions
            key={`${props.nonEnglishLanguage}-editor`}
            placeholder={"Type your message here..."}
            setTranslationModeEnabled={props.setIsTranslationModeEnabled}
            translationModeEnabled={props.isTranslationModeEnabled}
            value={props.messageTranslation || ""}
            isEditorActive={isTranslationEditorActive}
            setIsEditorActive={setIsTranslationEditorActive}
            onChange={handleMessageTextChange}
            setMessageText={props.setMessageText}
            messageText={props.messageText}
            nonEnglishLanguage={props.nonEnglishLanguage}
            setNonEnglishLanguage={props.setNonEnglishLanguage}
          />
        </div>
      )}
    </div>
  );
};

export default MessageBlastSubjectAndMessage;
