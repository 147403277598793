import { useState, useEffect, useCallback, Dispatch } from "react";

export const useLocalStorage = (
  key: string,
  initialValue = "",
): [string, Dispatch<string>] => {
  const [value, setValue] = useState(
    () => window.localStorage.getItem(key) || initialValue,
  );

  const setItem = useCallback(
    (newValue: string) => {
      setValue(newValue);
      window.localStorage.setItem(key, newValue);
    },
    [setValue, key],
  );

  useEffect(() => {
    const newValue = window.localStorage.getItem(key);
    if (value !== newValue) {
      setValue(newValue || initialValue);
    }
  }, [initialValue, key, value]);

  const handleStorage = useCallback(
    (event: StorageEvent) => {
      if (event.key === key && event.newValue !== value) {
        setValue(event.newValue || initialValue);
      }
    },
    [value, initialValue, key],
  );

  useEffect(() => {
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, [handleStorage]);

  return [value, setItem];
};

export const useBooleanLocalStorage = (
  key: string,
  initialValue = false,
): [boolean, Dispatch<boolean>] => {
  const [value, setValue] = useLocalStorage(key, initialValue.toString());

  const wrapper = useCallback(
    (newVal: boolean) => {
      return setValue(newVal.toString());
    },
    [setValue],
  );
  return [value === "true", wrapper];
};
