import Modal from "@src/deprecatedDesignSystem/components/Modal";
import MultiSelectProTipBanner from "@src/components/contentDetail/shared/MultiSelectProTipBanner";
import Spacer from "@src/components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { useModal } from "@src/hooks/useModal";
import { pluralize } from "@src/utils/strings";
import { FC } from "react";

type Props = {
  numberOfResourcesUploaded: number;
};

const TrainingResourceBulkUploadConfirmationModal: FC<Props> = ({
  numberOfResourcesUploaded,
}) => {
  const { closeModal } = useModal();

  return (
    <Modal growHeight style={{ padding: 24, paddingBottom: 32 }}>
      <AutoLayout direction="vertical" alignmentHorizontal="center">
        <DeprecatedIcon
          type="thumbs-up"
          iconSize={32}
          containerSize={56}
          style={{ backgroundColor: deprecatedTones.green2, borderRadius: 8 }}
        />
        <Spacer size={32} />
        <Text type="P1" fontWeight="SemiBold">
          {numberOfResourcesUploaded}{" "}
          {pluralize("Resource", numberOfResourcesUploaded)} have been added to
          your library
        </Text>
        <Spacer size={4} />
        <MultiSelectProTipBanner />
        <Spacer size={48} />
        <Button
          text="Close"
          variant="Black"
          onClick={() => {
            closeModal();
          }}
        />
      </AutoLayout>
    </Modal>
  );
};

export default TrainingResourceBulkUploadConfirmationModal;
