import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import { FC } from "react";

type Tone = {
  formality: number;
  seriousness: number;
};

const TONE_CHOICE_LABELS: { [key: string]: Tone } = {
  "💼 Formal": {
    formality: 0,
    seriousness: 2,
  },
  "😀 Funny": {
    formality: 2,
    seriousness: 0,
  },
  "✍️ Neutral": {
    formality: 2,
    seriousness: 2,
  },
  "🎓 Serious": {
    formality: 2,
    seriousness: 4,
  },
  "💬 Conversational": {
    formality: 4,
    seriousness: 2,
  },
};

type ToneOptionProps = {
  toneFormality: number;
  toneSeriousness: number;
  setToneFormality: (tone: number) => void;
  setToneSeriousness: (seriousness: number) => void;
  disabled?: boolean;
};

const ToneOptions: FC<ToneOptionProps> = ({
  toneFormality,
  toneSeriousness,
  setToneFormality,
  setToneSeriousness,
  disabled = false,
}) => {
  return (
    <AutoLayout spaceBetweenItems={16} wrap="wrap">
      {Object.keys(TONE_CHOICE_LABELS).map((label) => (
        <Button
          disabled={disabled}
          key={label}
          text={label}
          variant={
            toneFormality === TONE_CHOICE_LABELS[label].formality &&
            toneSeriousness === TONE_CHOICE_LABELS[label].seriousness
              ? "Secondary"
              : "Outline"
          }
          fontColor={
            toneFormality === TONE_CHOICE_LABELS[label].formality &&
            toneSeriousness === TONE_CHOICE_LABELS[label].seriousness
              ? deprecatedTones.white
              : deprecatedTones.gray8
          }
          onClick={() => {
            setToneFormality(TONE_CHOICE_LABELS[label].formality);
            setToneSeriousness(TONE_CHOICE_LABELS[label].seriousness);
          }}
        />
      ))}
    </AutoLayout>
  );
};

export default ToneOptions;
