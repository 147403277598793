import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import useClickOutside from "@hooks/useClickOutside";
import { useToast } from "@hooks/useToast";
import { css, StyleSheet } from "aphrodite";
import { FC, useRef, useState } from "react";
import RelativePortal from "react-relative-portal";
import isURL from "validator/lib/isURL";

type Props = {
  onAddLink: (url: string) => void;
  isDragActive: boolean;
  openFileSelector: () => void;
};

const UploadFilesDefaultState: FC<Props> = ({
  onAddLink,
  isDragActive,
  openFileSelector,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  return (
    <AutoLayout
      margin={10}
      width={500}
      direction="vertical"
      alignmentHorizontal="center"
      alignmentVertical="center"
      styleDeclaration={[
        styles.innerContainer,
        isDragActive && styles.draggingFileOverDropArea,
      ]}
    >
      <AutoLayout
        margin={10}
        direction="vertical"
        alignmentHorizontal="center"
        alignmentVertical="center"
      >
        <img
          src="/images/upload-files.svg"
          alt="help-center"
          className={css(styles.imageContainer)}
        />
        <Text
          type="H1"
          multiline
          fontWeight="SemiBold"
          style={{ marginTop: 12 }}
        >
          Upload your resources
        </Text>

        <Text
          type="P2"
          fontWeight="Regular"
          multiline
          styleDeclaration={styles.paragraph}
        >
          You can upload multiple files at once
        </Text>
        <AutoLayout spaceBetweenItems={12} marginTop={48} marginBottom={48}>
          <Button
            onClick={openFileSelector}
            variant="Primary"
            text={"Browse files"}
            height="40px"
            leftIcon="eye"
          />
          <Button
            onClick={() => setMenuVisible(true)}
            variant="Outline"
            text={"Paste link"}
            height="40px"
            leftIcon="link"
          />
        </AutoLayout>
        {menuVisible && (
          <PasteLinkTooltip
            visible={menuVisible}
            setVisibility={setMenuVisible}
            onAddLink={onAddLink}
          />
        )}
      </AutoLayout>
    </AutoLayout>
  );
};

type PasteLinkTooltipProps = {
  onAddLink: (link: string) => void;
  setVisibility: (visibility: boolean) => void;
  visible: boolean;
};

const PasteLinkTooltip: FC<PasteLinkTooltipProps> = ({
  onAddLink,
  setVisibility,
  visible,
}) => {
  const [link, setLink] = useState("");
  const ref = useRef(null);
  useClickOutside(ref, () => {
    if (visible) {
      setVisibility(false);
    }
  });
  const { addErrorToast } = useToast();
  return (
    // @ts-ignore-next-line
    <RelativePortal>
      <div ref={ref} className={css(styles.pasteLink)}>
        <TextField
          text={link}
          onTextChange={setLink}
          placeholder="Paste link here"
          containerStyleDeclaration={styles.textFieldStyles}
        />
        <Button
          text="Add"
          disabled={!link}
          onClick={() => {
            if (isURL(link, { protocols: ["https"] })) {
              onAddLink(link);
              setVisibility(false);
            } else {
              addErrorToast({
                message: "Invalid link. Please paste a valid link.",
                callsite: "paste_media_link",
              });
            }
          }}
        />
      </div>
    </RelativePortal>
  );
};

export default UploadFilesDefaultState;

const styles = StyleSheet.create({
  pasteLink: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    padding: "16px 15px 23px",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: 8,
    boxShadow: shadows.elevation5,
    backgroundColor: deprecatedTones.white,
    zIndex: 1000,
    top: -35,
    left: 10,
  },
  textFieldStyles: {
    width: 250,
    marginRight: 8,
    marginLeft: 12,
  },
  paragraph: {
    width: 275,
    textAlign: "center",
  },
  imageContainer: {
    width: 136,
    height: 136,
    marginBotton: 22,
  },
  innerContainer: {
    border: `2px dashed ${deprecatedTones.gray5}`,
    borderRadius: 16,
  },
  draggingFileOverDropArea: {
    focus: {
      boxShadow: `0 0 0 3px ${deprecatedColors.primaryContainer}`,
      border: `1px solid ${deprecatedTones.blue5}`,
    },
    focusError: {
      boxShadow: `0 0 0 3px ${deprecatedColors.errorContainer}`,
      border: `1px solid ${deprecatedTones.red11}`,
    },
    dropdownShadow: {
      boxShadow:
        "0px 8px 12px 6px rgba(142, 145, 146, 0.15), 0px 4px 4px rgba(142, 145, 146, 0.3)",
    },
    backgroundColor: deprecatedTones.gray4Alpha,
  },
});
