import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScrapeFoundingStoryMutationVariables = Types.Exact<{
  brandDetailsId: Types.Scalars['UUID'];
}>;


export type ScrapeFoundingStoryMutation = { __typename?: 'Mutation', scrapeAIBrandContextFoundingStory: { __typename?: 'StringMutationResult', success: boolean, value?: string | null } };

export type ScrapeSellsMutationVariables = Types.Exact<{
  brandDetailsId: Types.Scalars['UUID'];
}>;


export type ScrapeSellsMutation = { __typename?: 'Mutation', scrapeAIBrandContextSells: { __typename?: 'StringMutationResult', success: boolean, value?: string | null } };

export type ScrapeMissionStatementMutationVariables = Types.Exact<{
  brandDetailsId: Types.Scalars['UUID'];
}>;


export type ScrapeMissionStatementMutation = { __typename?: 'Mutation', scrapeAIBrandContextMissionStatement: { __typename?: 'StringMutationResult', success: boolean, value?: string | null } };


export const ScrapeFoundingStoryDocument = gql`
    mutation ScrapeFoundingStory($brandDetailsId: UUID!) {
  scrapeAIBrandContextFoundingStory(brandDetailsId: $brandDetailsId) {
    success
    value
  }
}
    `;
export type ScrapeFoundingStoryMutationFn = Apollo.MutationFunction<ScrapeFoundingStoryMutation, ScrapeFoundingStoryMutationVariables>;

/**
 * __useScrapeFoundingStoryMutation__
 *
 * To run a mutation, you first call `useScrapeFoundingStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScrapeFoundingStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scrapeFoundingStoryMutation, { data, loading, error }] = useScrapeFoundingStoryMutation({
 *   variables: {
 *      brandDetailsId: // value for 'brandDetailsId'
 *   },
 * });
 */
export function useScrapeFoundingStoryMutation(baseOptions?: Apollo.MutationHookOptions<ScrapeFoundingStoryMutation, ScrapeFoundingStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScrapeFoundingStoryMutation, ScrapeFoundingStoryMutationVariables>(ScrapeFoundingStoryDocument, options);
      }
export type ScrapeFoundingStoryMutationHookResult = ReturnType<typeof useScrapeFoundingStoryMutation>;
export type ScrapeFoundingStoryMutationResult = Apollo.MutationResult<ScrapeFoundingStoryMutation>;
export type ScrapeFoundingStoryMutationOptions = Apollo.BaseMutationOptions<ScrapeFoundingStoryMutation, ScrapeFoundingStoryMutationVariables>;
export const ScrapeSellsDocument = gql`
    mutation ScrapeSells($brandDetailsId: UUID!) {
  scrapeAIBrandContextSells(brandDetailsId: $brandDetailsId) {
    success
    value
  }
}
    `;
export type ScrapeSellsMutationFn = Apollo.MutationFunction<ScrapeSellsMutation, ScrapeSellsMutationVariables>;

/**
 * __useScrapeSellsMutation__
 *
 * To run a mutation, you first call `useScrapeSellsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScrapeSellsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scrapeSellsMutation, { data, loading, error }] = useScrapeSellsMutation({
 *   variables: {
 *      brandDetailsId: // value for 'brandDetailsId'
 *   },
 * });
 */
export function useScrapeSellsMutation(baseOptions?: Apollo.MutationHookOptions<ScrapeSellsMutation, ScrapeSellsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScrapeSellsMutation, ScrapeSellsMutationVariables>(ScrapeSellsDocument, options);
      }
export type ScrapeSellsMutationHookResult = ReturnType<typeof useScrapeSellsMutation>;
export type ScrapeSellsMutationResult = Apollo.MutationResult<ScrapeSellsMutation>;
export type ScrapeSellsMutationOptions = Apollo.BaseMutationOptions<ScrapeSellsMutation, ScrapeSellsMutationVariables>;
export const ScrapeMissionStatementDocument = gql`
    mutation ScrapeMissionStatement($brandDetailsId: UUID!) {
  scrapeAIBrandContextMissionStatement(brandDetailsId: $brandDetailsId) {
    success
    value
  }
}
    `;
export type ScrapeMissionStatementMutationFn = Apollo.MutationFunction<ScrapeMissionStatementMutation, ScrapeMissionStatementMutationVariables>;

/**
 * __useScrapeMissionStatementMutation__
 *
 * To run a mutation, you first call `useScrapeMissionStatementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScrapeMissionStatementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scrapeMissionStatementMutation, { data, loading, error }] = useScrapeMissionStatementMutation({
 *   variables: {
 *      brandDetailsId: // value for 'brandDetailsId'
 *   },
 * });
 */
export function useScrapeMissionStatementMutation(baseOptions?: Apollo.MutationHookOptions<ScrapeMissionStatementMutation, ScrapeMissionStatementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScrapeMissionStatementMutation, ScrapeMissionStatementMutationVariables>(ScrapeMissionStatementDocument, options);
      }
export type ScrapeMissionStatementMutationHookResult = ReturnType<typeof useScrapeMissionStatementMutation>;
export type ScrapeMissionStatementMutationResult = Apollo.MutationResult<ScrapeMissionStatementMutation>;
export type ScrapeMissionStatementMutationOptions = Apollo.BaseMutationOptions<ScrapeMissionStatementMutation, ScrapeMissionStatementMutationVariables>;