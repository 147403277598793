import React, { FC, useMemo, useRef, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import DateTimeField from "@src/deprecatedDesignSystem/components/DateTimeField";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import {
  formatDate,
  getThreeDaysFromNow,
  getTodaysDate,
} from "@src/utils/dates";
import useClickOutside from "@src/hooks/useClickOutside";
import { Button } from "@ui/button";
import RadioButton from "@src/deprecatedDesignSystem/components/RadioButton";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";
import Text from "@ui/text";
import BellOutlineIcon from "@src/ui/icons/18px/bell-outline";
import ChevronsOpenIcon from "@src/ui/icons/12px/chevrons-open";

type Props = {
  notifyUntilDate: Date | null | undefined;
  onUpdateNotifyUntilDate: (val: Date | undefined) => void;
  scheduledDateTime?: Date | null | undefined;
};

const NotificationSchedulePicker: FC<Props> = ({
  notifyUntilDate,
  onUpdateNotifyUntilDate,
  scheduledDateTime,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const scheduledDateTimeAsDate = useMemo(() => {
    if (notifyUntilDate) {
      return new Date(notifyUntilDate);
    }
    return null;
  }, [notifyUntilDate]);

  const ref = useRef(null);
  useClickOutside(ref, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });
  const minCalendarDate = useMemo(() => {
    return scheduledDateTime
      ? new Date(scheduledDateTime).toISOString().split("T")[0]
      : getTodaysDate();
  }, [scheduledDateTime]);
  return (
    <div>
      <div className={css(styles.container)}>
        <Button variant="outline" onClick={() => setMenuVisible(true)}>
          <BellOutlineIcon />
          {scheduledDateTimeAsDate
            ? `Notify daily until ${formatDate(scheduledDateTimeAsDate)}`
            : "Notify once"}
          <ChevronsOpenIcon />
        </Button>
        {menuVisible && (
          <div className={css(styles.menu)} ref={ref}>
            <RadioButton
              label="Notify once"
              checked={!scheduledDateTimeAsDate}
              onClick={() => onUpdateNotifyUntilDate(undefined)}
              styleDeclaration={[styles.checkboxRow, styles.now]}
            />
            <HorizontalDivider
              styleDeclaration={styles.horizontalDividerContainer}
            />
            <div
              className={css(styles.checkboxRow)}
              onClick={() => {
                if (scheduledDateTimeAsDate) {
                  onUpdateNotifyUntilDate(scheduledDateTimeAsDate);
                } else {
                  onUpdateNotifyUntilDate(getThreeDaysFromNow());
                }
              }}
            >
              <div className={css(styles.laterRow)}>
                <RadioButton
                  label="Notify once a day until opened"
                  checked={!!scheduledDateTimeAsDate}
                  onClick={() => {
                    if (scheduledDateTimeAsDate) {
                      onUpdateNotifyUntilDate(scheduledDateTimeAsDate);
                    } else {
                      onUpdateNotifyUntilDate(getThreeDaysFromNow());
                    }
                  }}
                />
              </div>
              <Text
                type="P3"
                fontWeight="SemiBold"
                styleDeclaration={styles.dateLabelContainer}
              >
                Stop notifying on:
              </Text>
              <DateTimeField
                value={scheduledDateTimeAsDate}
                onChange={onUpdateNotifyUntilDate}
                styleDeclaration={[
                  styles.dateTimePickerStyles,
                  scheduledDateTimeAsDate && styles.laterSelected,
                ]}
                type={"date"}
                min={minCalendarDate}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationSchedulePicker;

const styles = StyleSheet.create({
  container: {
    position: "relative",
    marginLeft: 8,
  },
  buttonSelected: {
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    background: deprecatedColors.background,
    cursor: "pointer",
    fontSize: 14,
    borderRadius: 16,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 16px",
    color: deprecatedColors.onBackground,
    fontWeight: 500,
    ":focus": {
      boxShadow: `0 0 0 3px ${deprecatedColors.primaryContainer}`,
      border: `1px solid ${deprecatedTones.blue5}`,
    },
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
      border: `1px solid ${deprecatedColors.disabled}`,
    },
  },
  span: {
    marginLeft: 4,
    fontWeight: 400,
  },
  iconContainer: {
    marginLeft: 8,
  },
  menu: {
    position: "absolute",
    backgroundColor: deprecatedColors.background,
    bottom: 48,
    right: 0,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    boxShadow: "0 9px 39px -21px rgba(0, 0, 0, 0.49)",
    padding: "8px 12px",
    borderRadius: 8,
    width: 304,
    display: "flex",
    flexWrap: "wrap",
  },
  checkboxRow: {
    minHeight: 32,
    fontSize: 14,
    cursor: "pointer",
    borderRadius: 4,
    padding: "6px 12px",
  },
  now: {
    display: "flex",
    alignItems: "center",
  },
  dateLabelContainer: {
    marginTop: 8,
    marginLeft: 24,
  },
  selected: {
    fontWeight: 500,
    fontSize: 14,
  },
  horizontalDividerContainer: {
    margin: "8px 0px",
  },
  laterRow: {
    display: "flex",
    alignItems: "center",
    padding: "6px 0px",
  },
  labelRow: {
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dateTimePickerStyles: {
    width: "100%",
    marginTop: 8,
    marginLeft: 24,
    opacity: 0.7,
  },
  laterSelected: {
    opacity: 1,
  },
  text: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 25,
  },
  laterText: {
    fontSize: 14,
    fontWeight: 400,
    marginLeft: 12,
    marginBottom: 5,
  },
});
