import RichTextEditorActiveStylesContextProvider from "@src/components/libraryItemDetailPages/course/contexts/RichTextEditorActiveStylesContext";
import EditorActionsOld from "@src/components/libraryItemDetailPages/course/EditorActionsOld";
import useClickOutside from "@hooks/useClickOutside";
import { StyleSheet } from "aphrodite";
import { FC, useRef } from "react";
import RichTextEditor from "../libraryItemDetailPages/course/RichTextEditor";
import TiptapEditorContextProvider from "../libraryItemDetailPages/course/contexts/TiptapEditorContext";
import {
  MediaFragment,
  TranslationSetFragment,
} from "@src/fragments.generated";
import { Button } from "@src/ui/button";
import PinTackOutlineIcon from "@src/ui/icons/18px/pin-tack-outline";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@src/ui/tooltip";
import Text from "@ui/text";
import LanguageSelect from "@src/ui/language-select";
import { SentMessage_MessageBlastMediaFragment } from "./SentMessage.generated";
import MessageBlastMediaPreview from "./MessageBlastMediaPreview";

type Props = {
  value: string;
  placeholder: string;
  isEditorActive: boolean;
  onMediaChange?: (media?: MediaFragment) => void;
  setIsEditorActive: (isEditorActive: boolean) => void;
  onChange: (val: string) => void;
  disabled?: boolean;
  messageText?: TranslationSetFragment;
  setMessageText?: (text: TranslationSetFragment) => void;
  pinnedMedia?: SentMessage_MessageBlastMediaFragment | null;
  messageBlastMedias?: Set<SentMessage_MessageBlastMediaFragment>;
  setMessageBlastMedias?: (
    newSet: Set<SentMessage_MessageBlastMediaFragment>,
  ) => void;
  translationModeEnabled?: boolean;
  setTranslationModeEnabled?: (v: boolean) => void;
  nonEnglishLanguage?: Language;
  setNonEnglishLanguage?: (x: Language) => void;
};

const RichTextEditorWithEditorActions: FC<Props> = ({
  value,
  pinnedMedia,
  placeholder,
  isEditorActive,
  setIsEditorActive,
  onMediaChange,
  onChange,
  disabled = false,
  messageText,
  setMessageText,
  messageBlastMedias,
  setMessageBlastMedias,
  translationModeEnabled,
  setTranslationModeEnabled,
  nonEnglishLanguage,
  setNonEnglishLanguage,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  useClickOutside(containerRef, () => {
    if (isEditorActive) {
      setIsEditorActive(false);
    }
  });
  return (
    <TooltipProvider>
      <TiptapEditorContextProvider>
        <RichTextEditorActiveStylesContextProvider>
          <div
            ref={containerRef}
            className="relative mb-1 w-full rounded-lg border border-input bg-background shadow-sm"
          >
            <div className="flex flex-col gap-2 p-3">
              {pinnedMedia && (
                <div className="group relative overflow-hidden rounded">
                  <MessageBlastMediaPreview
                    id={pinnedMedia.id.toString()}
                    mediaUrl={pinnedMedia.media.mediaUrls.en}
                  />
                  <div className="absolute inset-0 flex w-40 items-center justify-center">
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          size="icon"
                          variant="outline"
                          className="hidden group-hover:flex"
                          onClick={() => {
                            const mediaArray = messageBlastMedias
                              ? Array.from(messageBlastMedias)
                              : [];
                            const updatedArray = Array.from(mediaArray).map(
                              (x) =>
                                x.media.id.toString() ===
                                pinnedMedia.media.id.toString()
                                  ? { ...x, pinned: false }
                                  : x,
                            );
                            setMessageBlastMedias?.(new Set(updatedArray));
                          }}
                        >
                          <PinTackOutlineIcon />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top">Unpin</TooltipContent>
                    </Tooltip>
                  </div>
                </div>
              )}
              <RichTextEditor
                text={value}
                placeholder={placeholder}
                hasEditContentPermission={true}
                styles={[styles.messageBlastEditor, styles.editorHeight]}
                onFocus={() => setIsEditorActive(true)}
                onChange={onChange}
                disabled={disabled}
              />
            </div>
            {translationModeEnabled &&
              nonEnglishLanguage &&
              setNonEnglishLanguage && (
                <div className="flex items-center justify-end bg-muted px-3 py-2">
                  <Text
                    type="P2"
                    fontWeight="SemiBold"
                    className="w-full text-muted-foreground"
                  >
                    Select a language
                  </Text>
                  <LanguageSelect
                    selectedLanguage={nonEnglishLanguage}
                    onLanguageChange={setNonEnglishLanguage}
                  />
                </div>
              )}
            <div className="border-t border-input py-1">
              <EditorActionsOld
                onMediaChange={onMediaChange}
                messageText={messageText}
                setMessageText={setMessageText}
                translationModeEnabled={translationModeEnabled}
                setTranslationModeEnabled={setTranslationModeEnabled}
              />
            </div>
          </div>
        </RichTextEditorActiveStylesContextProvider>
      </TiptapEditorContextProvider>
    </TooltipProvider>
  );
};

const styles = StyleSheet.create({
  messageBlastEditor: {
    fontSize: 14,
  },
  editorHeight: {
    minHeight: 100,
  },
});

export default RichTextEditorWithEditorActions;
