import { useMemo, useState } from "react";
import { LocationGroupType } from "@src/types.generated";
import { getImageThumbnailUrl } from "@src/utils/images";
import { gql } from "@apollo/client";
import { useBrandSelectorQuery } from "./useAIAssistBrandSelector.generated";
import { AiOrgContextFragment } from "./useBranding.generated";
export type BrandSelectOption = {
  name: string;
  aiOrgContextId: string;
  logoUrl: string;
  aiOrgContext: AiOrgContextFragment;
  brandDetailsId: string;
};

export interface AIAssistBrandSelector {
  brandSelectOptions: BrandSelectOption[];
  selectedAIBrandContextId: string | undefined;
  setSelectedAIBrandContextId: (val: string) => void;
  selectedBrandItem: BrandSelectOption | undefined;
}

export function useAIAssistBrandSelector(): AIAssistBrandSelector {
  const [selectedBrandIndex, setSelectedBrandIndex] = useState<number>(0);
  const { data } = useBrandSelectorQuery({
    variables: {
      locationGroupInput: {
        types: [LocationGroupType.Brand],
      },
    },
  });
  const brandSelectOptions: BrandSelectOption[] = useMemo(() => {
    const orgBrandDetails = data?.Me?.user.org?.brandDetails;
    return [
      ...(orgBrandDetails &&
      orgBrandDetails.aiBrandContext &&
      orgBrandDetails.id
        ? [
            {
              name: data?.Me?.user.org?.name ?? "Unknown",
              aiOrgContextId: orgBrandDetails?.aiBrandContext?.id,
              aiOrgContext:
                orgBrandDetails.aiBrandContext as AiOrgContextFragment,
              logoUrl: orgBrandDetails?.logoUrls
                ? getImageThumbnailUrl(orgBrandDetails?.logoUrls)
                : "",
              brandDetailsId: orgBrandDetails.id as string,
            },
          ]
        : []),
      ...(data?.LocationGroups?.objects
        ?.filter((locationGrop) => {
          return !!locationGrop.brandDetails?.aiBrandContext;
        })
        .filter((locationGroup) => !!locationGroup.brandDetails?.id)
        .map((locationGroup) => {
          return {
            name: locationGroup.name,
            aiOrgContextId:
              locationGroup.brandDetails?.aiBrandContext?.id ?? "",
            aiOrgContext: locationGroup.brandDetails
              ?.aiBrandContext as AiOrgContextFragment,
            logoUrl: locationGroup?.brandDetails?.logoUrls
              ? getImageThumbnailUrl(locationGroup.brandDetails?.logoUrls)
              : "",
            brandDetailsId: locationGroup.brandDetails?.id as string,
          };
        }) ?? []),
    ];
  }, [
    data?.LocationGroups?.objects,
    data?.Me?.user.org?.brandDetails,
    data?.Me?.user.org?.name,
  ]);
  const selectedBrandItem: BrandSelectOption | undefined =
    brandSelectOptions?.[selectedBrandIndex];
  return {
    selectedAIBrandContextId: selectedBrandItem?.aiOrgContextId,
    setSelectedAIBrandContextId: (aiBrandContextId: string) => {
      const index = brandSelectOptions.findIndex(
        (brand) => brand.aiOrgContextId === aiBrandContextId,
      );
      setSelectedBrandIndex(index);
    },
    brandSelectOptions,
    selectedBrandItem,
  };
}

gql(`

fragment LocationGroupObjectFragment on LocationGroup {
  id
  name
  brandDetails {
    id
    logoUrls {
      ...ImageUrls
    }
    aiBrandContext {
      ...AIOrgContext
    }
  }
}

query BrandSelector($locationGroupInput: LocationGroupsInput) {
  LocationGroups(input: $locationGroupInput) {
    objects {
     ...LocationGroupObjectFragment 
  }
  }
  Me {
     user {
      org {
        id
        name
        brandDetails {
          id
          logoUrls {
            ...ImageUrls
          }
          aiBrandContext {
            ...AIOrgContext
          }
        }
      }
     }
  }
}
`);
