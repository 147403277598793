import React, { FC, RefObject, useCallback, useMemo, useState } from "react";

interface VideoRefMapState {
  getRef: (key: string) => RefObject<HTMLVideoElement> | null;
  addRef: (key: string, ref: RefObject<HTMLVideoElement>) => void;
}

export const VideoRefMapContext = React.createContext<VideoRefMapState>({
  getRef: () => null,
  addRef: () => null,
});

type Props = {
  children: JSX.Element;
};
const VideoRefMapContextProvider: FC<Props> = ({ children }) => {
  const [refMap, setRefMap] = useState<{
    [key: string]: RefObject<HTMLVideoElement>;
  }>({});
  const addRef = useCallback(
    (key: string, ref: RefObject<HTMLVideoElement>) => {
      setRefMap((prev) => ({ ...prev, [key]: ref }));
    },
    [],
  );
  const getRef = useCallback(
    (key: string) => {
      return refMap[key];
    },
    [refMap],
  );
  const contextState = useMemo(() => {
    return {
      getRef,
      addRef,
    };
  }, [getRef, addRef]);
  return (
    <VideoRefMapContext.Provider value={contextState}>
      {children}
    </VideoRefMapContext.Provider>
  );
};
export default VideoRefMapContextProvider;
