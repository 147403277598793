import * as React from "react";
import { IconProps } from "../types";

const UnorderedListIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M3.75 7.5C4.99264 7.5 6 6.49264 6 5.25C6 4.00736 4.99264 3 3.75 3C2.50736 3 1.5 4.00736 1.5 5.25C1.5 6.49264 2.50736 7.5 3.75 7.5Z"
          fill={color}
        />
        <path
          d="M3.75 15C4.99264 15 6 13.9926 6 12.75C6 11.5074 4.99264 10.5 3.75 10.5C2.50736 10.5 1.5 11.5074 1.5 12.75C1.5 13.9926 2.50736 15 3.75 15Z"
          fill={color}
        />
        <path
          d="M16.25 6H8.75C8.336 6 8 5.664 8 5.25C8 4.836 8.336 4.5 8.75 4.5H16.25C16.664 4.5 17 4.836 17 5.25C17 5.664 16.664 6 16.25 6Z"
          fill={color}
        />
        <path
          d="M16.25 13.5H8.75C8.336 13.5 8 13.164 8 12.75C8 12.336 8.336 12 8.75 12H16.25C16.664 12 17 12.336 17 12.75C17 13.164 16.664 13.5 16.25 13.5Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default UnorderedListIcon;
