import React, { FC, useMemo, useState } from "react";

export interface RichTextEditorActiveStylesState {
  isBold: boolean;
  setIsBold: (isBold: boolean) => void;
  isItalic: boolean;
  setIsItalic: (isItalic: boolean) => void;
  isOrderedList: boolean;
  setIsOrderedList: (isOrderedList: boolean) => void;
  isBulletList: boolean;
  setIsBulletList: (isBulletList: boolean) => void;
}

export const RichTextEditorActiveStylesContext =
  React.createContext<RichTextEditorActiveStylesState>({
    isBold: false,
    setIsBold: () => {},
    isItalic: false,
    setIsItalic: () => {},
    isOrderedList: false,
    setIsOrderedList: () => {},
    isBulletList: false,
    setIsBulletList: () => {},
  });

type Props = {
  children: JSX.Element;
};
const RichTextEditorActiveStylesContextProvider: FC<Props> = ({ children }) => {
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isOrderedList, setIsOrderedList] = useState(false);
  const [isBulletList, setIsBulletList] = useState(false);

  const contextState = useMemo(() => {
    return {
      isBold,
      setIsBold,
      isItalic,
      setIsItalic,
      isOrderedList,
      setIsOrderedList,
      isBulletList,
      setIsBulletList,
    };
  }, [
    isBold,
    setIsBold,
    isItalic,
    setIsItalic,
    isOrderedList,
    setIsOrderedList,
    isBulletList,
    setIsBulletList,
  ]);
  return (
    <RichTextEditorActiveStylesContext.Provider value={contextState}>
      {children}
    </RichTextEditorActiveStylesContext.Provider>
  );
};
export default RichTextEditorActiveStylesContextProvider;
