import React, { useMemo } from "react";

import Text from "@ui/text";
import TranslationModeContextProvider from "@src/components/libraryItemDetailPages/course/contexts/TranslationModeContextProvider";
import { Button } from "@src/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@src/ui/tooltip";
import PinTackOutlineIcon from "@src/ui/icons/18px/pin-tack-outline";
import PinTackFillIcon from "@src/ui/icons/18px/pin-tack-fill";
import MessageBlastFileDropZone from "@src/components/messaging/MessageBlastFileDropZone";
import XIcon from "@src/ui/icons/18px/x";
import MessageBlastMediaPreview from "@src/components/messaging/MessageBlastMediaPreview";
import { SentMessage_MessageBlastMediaFragment } from "./SentMessage.generated";

type Props = {
  messageBlastMedias: Set<SentMessage_MessageBlastMediaFragment>;
  setMessageBlastMedias?: (
    newSet: Set<SentMessage_MessageBlastMediaFragment>,
  ) => void;
  disabled?: boolean;
};

const MessageBlastAttachments: React.FC<Props> = ({
  disabled = false,
  ...props
}) => {
  const messageBlastMediasArray = Array.from(props.messageBlastMedias);

  const pinnedMediaId = useMemo(() => {
    const pinnedMedia = messageBlastMediasArray.find((media) => media.pinned);
    return pinnedMedia?.media?.id.toString();
  }, [messageBlastMediasArray]);

  return (
    <TranslationModeContextProvider>
      <TooltipProvider>
        <div className="flex flex-col gap-2 self-stretch">
          <Text type="P2" className="mt-2" fontWeight="SemiBold">
            Attachments
          </Text>
          <div className="flex flex-col self-stretch">
            {messageBlastMediasArray.map((media) => (
              <div
                key={media.id}
                className="flex flex-1 items-center justify-between self-stretch border-b border-input bg-background py-1"
              >
                <div className="flex flex-1 items-center gap-2">
                  <div className="flex size-12 items-center justify-center overflow-hidden rounded bg-foreground">
                    <MessageBlastMediaPreview
                      id={media.id.toString()}
                      mediaUrl={media.media.mediaUrls.en}
                    />
                  </div>
                  <Text
                    type="P2"
                    multiline
                    onClick={() => {
                      window.open(media.media.mediaUrls.en, "_blank");
                    }}
                  >
                    {media.media?.name?.en || media.media?.mediaUrls.en}
                  </Text>
                </div>
                {!disabled && props.setMessageBlastMedias && (
                  <div className="flex gap-2">
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant={media.pinned ? "secondary" : "ghost"}
                          size="icon"
                          onClick={() => {
                            if (props.setMessageBlastMedias) {
                              const updatedArray = Array.from(
                                props.messageBlastMedias,
                              ).map((x) => ({
                                ...x,
                                pinned: media === x ? !media.pinned : false,
                              }));
                              props.setMessageBlastMedias(
                                new Set(updatedArray),
                              );
                            }
                          }}
                        >
                          {media.pinned ? (
                            <PinTackFillIcon className="text-muted-foreground" />
                          ) : (
                            <PinTackOutlineIcon className="text-muted-foreground" />
                          )}
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top">
                        {media.pinned ? "Unpin from top" : "Pin to top"}
                      </TooltipContent>
                    </Tooltip>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => {
                            if (props.setMessageBlastMedias) {
                              const newSet = new Set(props.messageBlastMedias);
                              newSet.delete(media);
                              props.setMessageBlastMedias(newSet);
                            }
                          }}
                        >
                          <XIcon className="text-muted-foreground" />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="top" className="max-w-64">
                        <Text type="P3" multiline>
                          Remove attachment
                        </Text>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                )}
              </div>
            ))}
          </div>
          {!disabled && props.setMessageBlastMedias && (
            <MessageBlastFileDropZone
              pinnedMediaId={pinnedMediaId}
              messageBlastMedias={props.messageBlastMedias}
              setMessageBlastMedias={props.setMessageBlastMedias}
            />
          )}
        </div>
      </TooltipProvider>
    </TranslationModeContextProvider>
  );
};

export default MessageBlastAttachments;
