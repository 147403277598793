import { FC, useCallback, useEffect, useMemo, useState } from "react";
import * as Modal from "@src/deprecatedDesignSystem/components/ModalComposition";
import {
  GraphqlOperations,
  MessageBlastInput,
  UserSelectionInput,
  UserType,
} from "@src/types.generated";
import Text from "@src/ui/text";
import { emptyTranslationSet } from "@src/utils/prompts";
import { useToast } from "@src/hooks/useToast";
import useUserSelectionInputState from "@src/components/contentLibrary/assignmentModals/hooks/useUserSelectionInputState";
import { Label } from "@src/ui/label";
import useUser from "@src/hooks/useUser";
import ButtonWithPreview from "@src/deprecatedDesignSystem/components/ButtonWithPreview";
import useUserSelections from "@src/components/contentLibrary/assignmentModals/hooks/useUserSelections";
import useUserSelectionPreviewState from "@src/components/contentLibrary/assignmentModals/hooks/useUserSelectionPreviewState";
import {
  convertLocalDateTimeWithoutTzToUTCStringWithoutTz,
  isValidSendUntilDate,
} from "@src/utils/dates";
import { useTracking } from "@src/hooks/useTracking";
import { pluralize } from "@src/utils/strings";
import { launchConfetti } from "@src/utils/confetti";
import NowOrScheduledPicker from "@src/components/ui/NowOrScheduledPicker";
import NotificationSchedulePicker from "@src/components/ui/NotificationSchedulePicker";
import { formatISO9075 } from "date-fns";
import { useModal } from "@src/hooks/useModal";
import SelectUsersModal from "@src/components/contentLibrary/assignmentModals/SelectUsersModal";
import UserSelectionPreview from "@src/components/contentLibrary/assignmentModals/UserSelectionPreview";
import { RadioGroup, RadioGroupItem } from "@src/ui/radio-group";
import MessageBlastAttachments from "@src/components/messaging/MessageBlastAttachments";
import { useAvailableLanguages } from "@src/hooks/useAvailableLanguages";
import MessageBlastSubjectAndMessage from "@src/components/messaging/MessageBlastSubjectAndMessage";
import TranslationModeContextProvider from "@src/components/libraryItemDetailPages/course/contexts/TranslationModeContextProvider";
import { useTranslation } from "@src/utils/translationSets";
import { isBlastInValid } from "@src/components/modals/utils";
import { gql } from "@apollo/client";
import {
  useCreateMessageBlastMutation,
  useUpdateMessageBlastMutation,
} from "./MessageBlastModal.generated";
import {
  MessageBlastFragment,
  SentMessage_MessageBlastMediaFragment,
} from "./SentMessage.generated";
import Button from "@src/deprecatedDesignSystem/components/Button";

const REFETCH_QUERIES = [
  GraphqlOperations.Query.MessageBlasts,
  GraphqlOperations.Query.getMessageBlast,
];

type Props = {
  setSelectedBlastId?: (id: number) => void;
  currentBlast?: MessageBlastFragment | null;
  userSelection?: UserSelectionInput;
  onSubmit?: () => void;
  duplicate?: boolean;
};

const MessageBlastModal: FC<Props> = ({
  setSelectedBlastId,
  currentBlast,
  userSelection,
  onSubmit,
  duplicate = false,
}) => {
  const { addToast, addErrorToast } = useToast();
  const { user } = useUser();
  const { showModal, closeModal } = useModal();
  const trackEventState = useTracking();
  const { trackEvent } = trackEventState;
  const messageBlastId = currentBlast?.id;
  const initialScheduledTime = currentBlast?.sendAt;
  const initialFromOrg = currentBlast?.fromOrg;
  const initialSubject = currentBlast?.subject;
  const initialMessageText = currentBlast?.messageText;
  const initialSendReminderUntilTime =
    currentBlast?.notificationSchedule?.sendUntil;
  const initialMedias = currentBlast?.messageBlastMedias;

  const [messageBlastMedias, setMessageBlastMedias] = useState<
    Set<SentMessage_MessageBlastMediaFragment>
  >(
    initialMedias
      ? new Set(initialMedias.filter((media) => media != null))
      : new Set(),
  );

  const [sendFrom, setSendFrom] = useState<string>(
    initialFromOrg ? "org" : "user",
  );
  const onSendFromChange = (value: string) => {
    setSendFrom(value);
  };
  const [subjectText, setSubjectText] = useState(
    initialSubject || emptyTranslationSet(),
  );
  const [messageText, setMessageText] = useState(
    initialMessageText || emptyTranslationSet(),
  );

  const [scheduledTime, setScheduledTime] = useState<Date | null | undefined>(
    initialScheduledTime ? new Date(initialScheduledTime) : undefined,
  );

  const [sendReminderUntilTime, setSendReminderUntilTime] = useState<
    Date | undefined | null
  >(
    initialSendReminderUntilTime
      ? new Date(initialSendReminderUntilTime)
      : null,
  );
  const [isTranslationModeEnabled, setIsTranslationModeEnabled] =
    useState(false);
  const { mostPopularNonEnglishLanguage } = useAvailableLanguages();
  const [nonEnglishLanguage, setNonEnglishLanguage] = useState<Language>(
    mostPopularNonEnglishLanguage,
  );
  const { text: subjectTextTranslation } = useTranslation(
    subjectText,
    nonEnglishLanguage,
  );
  const { text: messageTextTranslation } = useTranslation(
    messageText,
    nonEnglishLanguage,
  );
  const sendAt = useMemo(() => {
    if (!scheduledTime) return undefined;
    return convertLocalDateTimeWithoutTzToUTCStringWithoutTz(scheduledTime);
  }, [scheduledTime]);
  const sendUntil = useMemo(() => {
    if (!sendReminderUntilTime) return undefined;
    return formatISO9075(new Date(sendReminderUntilTime));
  }, [sendReminderUntilTime]);
  const { userSelectionInput, setUserSelectionInput } =
    useUserSelectionInputState({
      input: userSelection,
      allowUserTypes: true,
    });
  const userSelections = useUserSelections(userSelectionInput);
  const { hasSelection } = useUserSelectionPreviewState({
    input: userSelectionInput,
    showUserTypeOptions: true,
  });
  const handleOnSelect = useCallback(
    (newInput: UserSelectionInput) => {
      setUserSelectionInput(newInput);
    },
    [setUserSelectionInput],
  );
  const openSelectUsersModal = useCallback(() => {
    showModal(
      <SelectUsersModal
        input={userSelectionInput}
        onSelect={handleOnSelect}
        showUserTypeOptions={true}
      />,
    );
  }, [handleOnSelect, showModal, userSelectionInput]);
  const messageBlastMediasList = useMemo(() => {
    return Array.from(messageBlastMedias).map((mbm) => {
      return {
        id: mbm.media.id,
        pinned: mbm.pinned,
      };
    });
  }, [messageBlastMedias]);
  const blastInput: MessageBlastInput = useMemo(() => {
    return {
      subject: subjectText,
      messageText: messageText,
      messageBlastMedias: messageBlastMediasList,
      userSelection: {
        allLocations: userSelectionInput.allLocations,
        allManagers: userSelectionInput.allManagers,
        allTrainees: userSelectionInput.allTrainees,
        allAdmins: userSelectionInput.allAdmins,
        locationIds: userSelectionInput.locationIds,
        locationGroupIds: userSelectionInput.locationGroupIds,
        roleGroupIds: userSelectionInput.roleGroupIds,
        roleIds: userSelectionInput.roleIds,
        userIds: userSelectionInput.userIds,
      },
      fromOrg: sendFrom === "org" ? true : false,
      sendAt: sendAt,
      sendUntil: sendUntil,
    };
  }, [
    subjectText,
    messageText,
    userSelectionInput.allLocations,
    userSelectionInput.allManagers,
    userSelectionInput.allTrainees,
    userSelectionInput.allAdmins,
    userSelectionInput.locationIds,
    userSelectionInput.locationGroupIds,
    userSelectionInput.roleGroupIds,
    userSelectionInput.roleIds,
    userSelectionInput.userIds,
    sendFrom,
    sendAt,
    sendUntil,
    messageBlastMediasList,
  ]);

  const pinnedMedia = useMemo(() => {
    return Array.from(messageBlastMedias).find((x) => x.pinned) || null;
  }, [messageBlastMedias]);

  const [createMessageBlastMutation, { loading: createMessageBlastLoading }] =
    useCreateMessageBlastMutation({
      variables: { input: blastInput },
      refetchQueries: REFETCH_QUERIES,
      onError: (error) => {
        addErrorToast({
          ...error,
          callsite: "create_blast",
          input: blastInput,
        });
      },
      onCompleted: (data) => {
        if (!data.createMessageBlast.success) {
          addErrorToast({
            data,
            callsite: "create_blast",
            input: blastInput,
          });
        } else {
          if (data.createMessageBlast.messageBlast?.id) {
            setSelectedBlastId?.(data.createMessageBlast.messageBlast?.id);
          }
          onSubmit && onSubmit();
          trackEvent("message_blast_success", {
            ...blastInput,
          });
          const recipientCount =
            data?.createMessageBlast?.messageBlast?.recipientCount;
          const messageIsDraft = data?.createMessageBlast?.messageBlast?.draft;
          if (!messageIsDraft) {
            launchConfetti();
          }
          if (scheduledTime) {
            addToast({
              iconType: "message",
              message: "Message scheduled",
            });
          } else if (messageIsDraft) {
            addToast({
              iconType: "message",
              message: `Message saved as draft`,
            });
          } else if (recipientCount) {
            addToast({
              iconType: "message",
              message: `Message sent to ${recipientCount} ${pluralize(
                "trainee",
                recipientCount,
              )}!`,
            });
          } else {
            addToast({
              iconType: "message",
              message: `Message sent!`,
            });
          }
          closeModal();
        }
      },
    });
  const [updateMessageBlastMutation, { loading: updateMessageBlastLoading }] =
    useUpdateMessageBlastMutation({
      refetchQueries: REFETCH_QUERIES,
      onError: (error) => {
        addErrorToast({
          ...error,
          callsite: "update_blast",
          input: blastInput,
        });
      },
      onCompleted: (data) => {
        if (!data.updateMessageBlast.success) {
          addErrorToast({
            data,
            callsite: "update_blast",
            input: blastInput,
          });
        } else {
          if (data.updateMessageBlast.messageBlast?.id) {
            setSelectedBlastId?.(data.updateMessageBlast.messageBlast?.id);
          }
          onSubmit && onSubmit();
          trackEvent("update_message_blast_success", {
            num: userSelections.selectedUsersCount,
            ...blastInput,
          });
          const recipientCount =
            data?.updateMessageBlast?.messageBlast?.recipientCount;
          const messageIsDraft = data?.updateMessageBlast?.messageBlast?.draft;
          if (!messageIsDraft) {
            launchConfetti();
          }
          if (scheduledTime) {
            addToast({ iconType: "message", message: "Message updated" });
          } else if (messageIsDraft) {
            addToast({
              iconType: "message",
              message: `Message saved as draft`,
            });
          } else if (recipientCount) {
            addToast({
              iconType: "message",
              message: `Message sent to ${recipientCount} ${pluralize(
                "trainee",
                recipientCount,
              )}!`,
            });
          } else {
            addToast({
              iconType: "message",
              message: `Message sent!`,
            });
          }
          closeModal();
        }
      },
    });
  const sendButtonDisabled = isBlastInValid(
    userSelectionInput.allLocations,
    userSelectionInput.allTrainees,
    userSelectionInput.allManagers,
    !!userSelectionInput.allAdmins,
    userSelections.selectedUsers,
    userSelections.selectedLocations,
    userSelections.selectedRoles,
    userSelections.selectedRoleGroups,
    messageText,
    scheduledTime,
  );
  useEffect(() => {
    setNonEnglishLanguage(mostPopularNonEnglishLanguage);
  }, [mostPopularNonEnglishLanguage]);
  return (
    <TranslationModeContextProvider>
      <Modal.Modal height={"90vh"} width={850}>
        <Modal.ModalHeader>New Message</Modal.ModalHeader>
        <Modal.ModalBody>
          <div className="self-stretch">
            {user?.userType === UserType.Admin && (
              <div className="flex gap-4 pb-2">
                <Text type="P2" fontWeight="SemiBold">
                  From
                </Text>
                <RadioGroup
                  defaultValue={sendFrom}
                  onValueChange={onSendFromChange}
                  className="flex gap-4 align-middle"
                >
                  <div className="flex items-center gap-1">
                    <RadioGroupItem value="user" id="user" />
                    <Label htmlFor="user" className="cursor-pointer">
                      {user?.name || "You"}
                    </Label>
                  </div>
                  <div className="flex items-center gap-1">
                    <RadioGroupItem value="org" id="org" />
                    <Label htmlFor="org" className="cursor-pointer">
                      {user?.org?.name || "Your Organization"}
                    </Label>
                  </div>
                </RadioGroup>
              </div>
            )}
            <div className="flex flex-col">
              <Text type="P2" fontWeight="SemiBold" className="mt-2 pb-1">
                Add recipients
              </Text>
              <div className="flex flex-1 flex-col self-stretch pb-2">
                <ButtonWithPreview
                  leftIcon="message"
                  label={hasSelection ? "Sending message to:" : undefined}
                  placeholder="Select recipients"
                  onClick={openSelectUsersModal}
                />
                {hasSelection && (
                  <UserSelectionPreview
                    input={userSelectionInput}
                    showUserTypeOptions={true}
                  />
                )}
              </div>
            </div>
            <MessageBlastSubjectAndMessage
              subjectText={subjectText}
              subjectTranslation={subjectTextTranslation}
              messageText={messageText}
              messageTranslation={messageTextTranslation}
              pinnedMedia={pinnedMedia}
              setSubjectText={setSubjectText}
              setMessageText={setMessageText}
              nonEnglishLanguage={nonEnglishLanguage}
              setNonEnglishLanguage={setNonEnglishLanguage}
              messageBlastMedias={messageBlastMedias}
              setMessageBlastMedias={setMessageBlastMedias}
              isTranslationModeEnabled={isTranslationModeEnabled}
              setIsTranslationModeEnabled={setIsTranslationModeEnabled}
            />
            <MessageBlastAttachments
              messageBlastMedias={messageBlastMedias}
              setMessageBlastMedias={setMessageBlastMedias}
            />
          </div>
        </Modal.ModalBody>
        <Modal.ModalFooter>
          <div className="flex">
            <NowOrScheduledPicker
              messageSchedulingEnabled
              scheduledDateTime={scheduledTime}
              onScheduledDateTimeChange={setScheduledTime}
            />
            <NotificationSchedulePicker
              notifyUntilDate={sendReminderUntilTime}
              scheduledDateTime={scheduledTime ? new Date(scheduledTime) : null}
              onUpdateNotifyUntilDate={setSendReminderUntilTime}
            />
          </div>
          <div className="flex flex-1 justify-end gap-2">
            <Button
              text="Cancel"
              variant="Outline"
              onClick={() => closeModal()}
            />
            {!currentBlast?.sentAt && (
              <Button
                text="Save Draft"
                variant="Outline"
                loading={createMessageBlastLoading || updateMessageBlastLoading}
                onClick={() => {
                  if (
                    blastInput.sendUntil &&
                    !isValidSendUntilDate(
                      new Date(blastInput.sendUntil!),
                      scheduledTime,
                    )
                  ) {
                    addErrorToast({
                      message:
                        "Notification reminder date must be later than scheduled date",
                      callsite: "message_blast_list_preview",
                    });
                  } else {
                    if (messageBlastId && !duplicate) {
                      updateMessageBlastMutation({
                        variables: {
                          id: messageBlastId,
                          input: {
                            ...blastInput,
                            draft: true,
                          },
                        },
                      });
                    } else {
                      createMessageBlastMutation({
                        variables: {
                          input: {
                            ...blastInput,
                            draft: true,
                          },
                        },
                      });
                    }
                  }
                }}
              />
            )}
            <Button
              disabled={sendButtonDisabled}
              text={
                currentBlast?.sentAt && !duplicate ? "Update and Send" : "Send"
              }
              loading={createMessageBlastLoading || updateMessageBlastLoading}
              onClick={() => {
                if (
                  blastInput.sendUntil &&
                  !isValidSendUntilDate(
                    new Date(blastInput.sendUntil!),
                    scheduledTime,
                  )
                ) {
                  addErrorToast({
                    message:
                      "Notification reminder date must be later than scheduled date",
                    callsite: "message_blast_list_preview",
                  });
                } else {
                  if (messageBlastId && !duplicate) {
                    updateMessageBlastMutation({
                      variables: { id: messageBlastId, input: blastInput },
                    });
                  } else {
                    createMessageBlastMutation();
                  }
                }
              }}
            />
          </div>
        </Modal.ModalFooter>
      </Modal.Modal>
    </TranslationModeContextProvider>
  );
};

export default MessageBlastModal;

gql`
  mutation CreateMessageBlast($input: MessageBlastInput!) {
    createMessageBlast(input: $input) {
      success
      error {
        code
      }
      messageBlast {
        id
        recipientCount
        draft
      }
    }
  }

  mutation UpdateMessageBlast($id: Int!, $input: MessageBlastInput!) {
    updateMessageBlast(id: $id, input: $input) {
      success
      error {
        code
      }
      messageBlast {
        id
        recipientCount
        draft
      }
    }
  }
`;
