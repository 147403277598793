import * as React from "react";
import { IconProps } from "../types";

const BookOpenOutlineIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9.00001 15.0509C9.17001 15.0509 9.33901 15.0059 9.49401 14.9169C10.137 14.5459 11.226 14.0699 12.635 14.0719C13.534 14.0729 14.302 14.2689 14.905 14.5069C15.553 14.7619 16.249 14.2669 16.249 13.5699V4.48692C16.249 4.13292 16.068 3.80692 15.763 3.62692C15.126 3.25092 14.037 2.76392 12.623 2.76392C10.733 2.76392 9.42501 3.63592 8.99901 3.94592"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00001 15.0509C8.83001 15.0509 8.66101 15.0059 8.50601 14.9169C7.86301 14.5459 6.77401 14.0699 5.36501 14.0719C4.46601 14.0729 3.69801 14.2689 3.09501 14.5069C2.44701 14.7619 1.75101 14.2699 1.75101 13.5739C1.75101 10.9809 1.75101 6.10189 1.75101 4.48389C1.75101 4.12989 1.93201 3.80789 2.23701 3.62789C2.87401 3.25189 3.96301 2.76489 5.37701 2.76489C7.26701 2.76489 8.57501 3.63689 9.00101 3.94689V15.0509H9.00001Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);

export default BookOpenOutlineIcon;
