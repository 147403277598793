import { BrandSelectOption } from "@src/hooks/useAIAssistBrandSelector";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@src/ui/select";
import { BrandSelectOptionItem } from "./BrandSelectOptionItem";

export const BrandSelector = ({
  brandSelectOptions,
  onSelectValueChange,
  selectedBrandItem,
  defaultValue,
}: {
  brandSelectOptions: BrandSelectOption[];
  onSelectValueChange: (value: string) => void;
  selectedBrandItem?: BrandSelectOption;
  defaultValue: string;
}): JSX.Element => {
  return (
    <>
      <Select defaultValue={defaultValue} onValueChange={onSelectValueChange}>
        <SelectTrigger className="w-[310px]">
          <SelectValue>
            <BrandSelectOptionItem brandSelectOption={selectedBrandItem!} />
          </SelectValue>
        </SelectTrigger>
        <SelectContent
          style={{
            zIndex: 603,
          }}
        >
          {brandSelectOptions.map((option) => (
            <SelectItem
              key={option.aiOrgContextId}
              value={option.aiOrgContextId}
            >
              <BrandSelectOptionItem brandSelectOption={option} />
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};
