import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { BrandSelectOption } from "@src/hooks/useAIAssistBrandSelector";

export const BrandSelectOptionItem = ({
  brandSelectOption,
}: {
  brandSelectOption: BrandSelectOption;
}): JSX.Element => {
  return (
    <AutoLayout
      flex={1}
      direction="horizontal"
      spaceBetweenItems={8}
      alignmentVertical="center"
    >
      <div style={{ borderRadius: "6.857px" }}>
        {brandSelectOption.logoUrl ? (
          <div
            style={{
              height: 24,
              width: 24,
              background: `url(${brandSelectOption.logoUrl}) lightgray 50% / cover no-repeat`,
              borderRadius: 24,
            }}
          />
        ) : (
          <DeprecatedIcon
            type="registered-trademark"
            iconSize={24}
            containerSize={24}
            color={deprecatedTones.gray10}
          />
        )}
      </div>
      <div>{brandSelectOption.name}</div>
    </AutoLayout>
  );
};
