import { Option } from "@src/deprecatedDesignSystem/components/InlineEditSingleSelectField";
import { useAvailableLanguages } from "@hooks/useAvailableLanguages";
import { langCodeToEnglishName } from "@utils/copy";
import { useMemo } from "react";

const useOrderedLanguageChoices = (): Option<Language>[] => {
  const { allLanguages: orderedLanguages } = useAvailableLanguages();
  return useMemo(() => {
    return orderedLanguages.map((lang) => ({
      value: lang,
      label: langCodeToEnglishName(lang) || lang,
    }));
  }, [orderedLanguages]);
};

export default useOrderedLanguageChoices;
