import * as React from "react";
import { IconProps } from "../types";

const LanguageIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M8.55299 10.658C8.61899 10.676 8.68499 10.684 8.74999 10.684C9.07999 10.684 9.38199 10.465 9.47299 10.131C9.58199 9.732 9.34599 9.319 8.94599 9.211C8.36699 9.053 7.83399 8.805 7.35699 8.485C8.10799 7.613 8.68599 6.47 8.91199 5H10.25C10.664 5 11 4.664 11 4.25C11 3.836 10.664 3.5 10.25 3.5H6.99999V2.25C6.99999 1.836 6.66399 1.5 6.24999 1.5C5.83599 1.5 5.49999 1.836 5.49999 2.25V3.5H2.24999C1.83599 3.5 1.49999 3.836 1.49999 4.25C1.49999 4.664 1.83599 5 2.24999 5H3.58299C3.76599 6.308 4.30399 7.5 5.12499 8.469C3.76399 9.365 2.30799 9.494 2.19899 9.502C1.78599 9.53 1.47299 9.887 1.50099 10.301C1.52799 10.697 1.85699 11.001 2.24899 11.001C2.26599 11.001 2.28299 11.001 2.29999 11C3.35999 10.929 4.90399 10.508 6.24599 9.516C6.92499 10.023 7.69399 10.425 8.55299 10.659V10.658ZM7.39499 5C7.19599 6.053 6.77099 6.858 6.24699 7.477C5.65999 6.776 5.25499 5.93 5.08799 5H7.39499Z"
          fill={color}
        />
        <path
          d="M16.452 15.487L13.452 7.487C13.342 7.194 13.062 7 12.75 7H12.25C11.938 7 11.658 7.194 11.548 7.487L8.54799 15.487C8.40199 15.875 8.59899 16.307 8.98699 16.452C9.37299 16.597 9.80699 16.401 9.95199 16.013L10.707 14H14.293L15.048 16.013C15.161 16.314 15.446 16.5 15.75 16.5C15.837 16.5 15.927 16.485 16.013 16.452C16.401 16.306 16.597 15.874 16.452 15.487ZM11.27 12.5L12.5 9.219L13.73 12.5H11.269H11.27Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default LanguageIcon;
