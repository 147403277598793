import { FC, ReactElement } from "react";
import { StyleSheet } from "aphrodite";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import {
  DeprecatedColor,
  DeprecateTone,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import {
  curves,
  durations,
  properties,
} from "@src/deprecatedDesignSystem/styles/transitions";

type Props = {
  title: string;
  subtitle: string;
  color: DeprecateTone | DeprecatedColor;
  image: ReactElement;
  onClick: () => void;
  topLeftBadge?: ReactElement;
  dataTestId?: string;
};

const NewContentCard: FC<Props> = ({
  title,
  subtitle,
  color,
  image,
  onClick,
  topLeftBadge,
  dataTestId,
}) => {
  return (
    <AutoLayout
      flex={1}
      alignSelf="stretch"
      direction="vertical"
      styleDeclaration={styles.card}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <AutoLayout flex={1} alignSelf="stretch" spacingMode="space-between">
        {topLeftBadge}
        {image}
      </AutoLayout>
      <AutoLayout direction="vertical" styleDeclaration={styles.content}>
        <Text
          type="P1"
          fontWeight="SemiBold"
          color={color}
          styleDeclaration={styles.title}
        >
          {title}
        </Text>
        <Text type="P2" fontWeight="Medium" color={deprecatedTones.gray6}>
          {subtitle}
        </Text>
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  card: {
    width: 290,
    borderRadius: 20,
    backgroundColor: deprecatedTones.white,
    cursor: "pointer",
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04)",
    transitionProperty: properties.boxShadow,
    transitionDuration: durations.S015,
    transitionTimingFunction: curves.bezier,
    ":hover": {
      boxShadow:
        "0px 16px 32px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.02), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.02)",
    },
  },
  content: {
    padding: 16,
  },
  title: {
    marginBottom: 4,
  },
});

export default NewContentCard;
