import { gql } from "@apollo/client";
import { useIsStaffQuery } from "./useIsStaff.generated";

export const useIsStaff = (): { isStaff: boolean; loading: boolean } => {
  const { data, loading } = useIsStaffQuery({
    fetchPolicy: "cache-first",
  });
  return {
    loading,
    isStaff: data?.Me?.isStaff || false,
  };
};

gql`
  query IsStaff {
    Me {
      isStaff
    }
  }
`;
