import { CSSProperties, FC, ReactNode } from "react";
import { StyleSheet } from "aphrodite";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import {
  curves,
  durations,
} from "@src/deprecatedDesignSystem/styles/transitions";

type Props = {
  avatar: ReactNode;
  title: string;
  subtitle: string;
  onClick: () => void;
  rowType?: "Card" | "Row";
  style?: CSSProperties;
  isDisabled?: boolean;
  showRemoveIcon?: boolean;
};

const LibraryItemRow: FC<Props> = ({
  avatar,
  title,
  subtitle,
  onClick,
  isDisabled = false,
  rowType = "Card",
  style,
  showRemoveIcon,
}) => {
  return (
    <AutoLayout
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
      paddingHorizontal={8}
      paddingVertical={4}
      direction={"horizontal"}
      alignmentVertical={"center"}
      alignSelf="stretch"
      data-test-id="add-content-to-list-row"
      styleDeclaration={[
        styles.row,
        rowType === "Card" && styles.cardStyle,
        rowType === "Row" && styles.rowStyle,
        isDisabled && styles.disabled,
      ]}
      style={style}
    >
      {avatar}
      <AutoLayout direction={"vertical"} flex={1} marginLeft={8}>
        <Text
          type="P3"
          fontWeight="Medium"
          color={isDisabled ? deprecatedTones.gray7 : deprecatedTones.gray12}
          ellipsis={true}
          style={{ alignSelf: "stretch" }}
        >
          {title}
        </Text>
        <Text type="P3" color={deprecatedTones.gray7}>
          {subtitle}
        </Text>
      </AutoLayout>
      {showRemoveIcon && (
        <DeprecatedIcon type="trash-2" color={deprecatedTones.gray5} />
      )}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  row: {
    cursor: "pointer",
    backgroundColor: deprecatedTones.white,
    transition: `background-color ${durations.S025} ${curves.bezier}`,
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
  rowStyle: {
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  cardStyle: {
    borderRadius: 8,
  },
  disabled: {
    backgroundColor: deprecatedTones.gray4Alpha,
    cursor: "auto",
  },
});

export default LibraryItemRow;
