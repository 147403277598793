import SearchBar from "@components/ui/SearchBar";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleSheet } from "aphrodite";
import Fuse from "fuse.js";
import React, { useMemo, useState } from "react";
import Spacer from "@components/ui/Spacer";
import Checkbox from "@src/deprecatedDesignSystem/components/Checkbox";
import { deprecatedColors } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props<T extends string> = {
  allChoices: Choice<T>[];
  selectedChoices: Choice<T>[];
  onSelectedChoicesChange: (newChoices: Choice<T>[]) => void;
  loading?: boolean;
  maxMenuHeight?: React.CSSProperties["maxHeight"];
  removeClearButton?: boolean;
  searchEnabled?: boolean;
};

const fuseConfig = {
  keys: ["label"],
  threshold: 0.1,
};

export const MultiSelectMenuContent = <T extends string>({
  allChoices,
  selectedChoices,
  onSelectedChoicesChange,
  loading,
  maxMenuHeight = 240,
  removeClearButton,
  searchEnabled = true,
}: Props<T>): JSX.Element => {
  const [search, setSearch] = useState("");
  const fuse = useMemo(() => {
    return new Fuse(allChoices, fuseConfig);
  }, [allChoices]);
  const searchResults = useMemo(() => {
    let filteredGroups = allChoices;
    if (search.length > 0) {
      filteredGroups = fuse.search(search).map((result) => result.item);
    }
    return filteredGroups;
  }, [fuse, search, allChoices]);
  const selectedChoiceValues = useMemo(
    () => selectedChoices.map((c) => c.value),
    [selectedChoices],
  );
  return (
    <div
      className={css(styles.multiSelectMenuContainer)}
      style={{ maxHeight: maxMenuHeight }}
    >
      {searchEnabled && (
        <div className={css(styles.searchContainer)}>
          <SearchBar
            className={css(styles.searchInput)}
            inputClassName={css(styles.searchInput)}
            placeholder={`Search...`}
            value={search}
            onChange={setSearch}
          />
        </div>
      )}
      <div className={css(styles.divider)} />
      <div className={css(styles.inputGroup)}>
        {allChoices.length === 0 && loading && (
          <div className={css(styles.message)}>Loading...</div>
        )}
        {allChoices.length > 0 && searchResults.length === 0 && (
          <div className={css(styles.message)}>No results</div>
        )}
        {searchResults.map((choice) => (
          <Checkbox
            key={choice.value}
            label={choice.label}
            checked={selectedChoiceValues.includes(choice.value)}
            onChange={() => {
              const newSelectedChoices = [...selectedChoices];
              const index = selectedChoiceValues.indexOf(choice.value);
              if (index === -1) {
                newSelectedChoices.push(choice);
              } else {
                newSelectedChoices.splice(index, 1);
              }
              onSelectedChoicesChange(newSelectedChoices);
            }}
            styleDeclaration={styles.checkbox}
          />
        ))}
        <Spacer size={searchEnabled ? 16 : 4} />
      </div>
      {!removeClearButton && allChoices.length > 0 && (
        <div className={css(styles.clearSection)}>
          <div
            className={css(styles.clearText)}
            onClick={() => onSelectedChoicesChange([])}
          >
            Clear
          </div>
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  multiSelectMenuContainer: {
    display: "flex",
    flexDirection: "column",
    width: 240,
  },
  searchContainer: {
    padding: 8,
  },
  searchInput: {
    borderRadius: 12,
    width: "100%",
    fontSize: 14,
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  message: {
    marginLeft: 20,
    fontSize: 14,
    paddingTop: 14,
    paddingBottom: 14,
    color: deprecatedTones.gray11,
  },
  clearSection: {
    height: 40,
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  clearText: {
    color: deprecatedTones.blue9,
    fontSize: 14,
    marginRight: 12,
    ":hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  checkbox: {
    fontSize: "30px",
    padding: "6px 12px 8px",
    borderRadius: "12px",
    ":hover": {
      backgroundColor: deprecatedTones.gray4Alpha,
      cursor: "pointer",
    },
  },
  inputGroup: {
    borderRadius: 8,
    maxHeight: 184,
    overflowY: "auto",
    backgroundColor: deprecatedColors.background,
  },
});

export default MultiSelectMenuContent;

export type Choice<T extends string | boolean = string> = {
  value: T;
  label: string;
  type?: string;
};
