import { sharedStyles } from "@src/deprecatedDesignSystem/styles/sharedStyles";
import { css } from "aphrodite";
import React, { ComponentProps, CSSProperties, FC, useMemo } from "react";

type Props = ComponentProps<"div"> & {
  overflow?: CSSProperties["overflow"];
  flex?: CSSProperties["flex"];
  alignSelf?: CSSProperties["alignSelf"];
  innerStyle?: CSSProperties;
  innerClassName?: string;
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  hideScrollbar?: boolean;
};

const ScrollableFlexGrow: FC<Props> = (
  {
    innerStyle,
    style,
    overflow = "auto",
    flex = 1,
    alignSelf,
    children,
    onScroll,
    hideScrollbar,
    ...props
  },
  ref,
) => {
  const hideScrollbarStyles = useMemo(() => {
    return hideScrollbar ? sharedStyles.hideScrollbar : undefined;
  }, [hideScrollbar]);

  return (
    <div
      id={"sfg-outer"}
      ref={ref}
      style={{
        position: "relative",
        flex: flex,
        alignSelf: alignSelf,
        ...style,
      }}
      {...props}
    >
      <div
        id={"sfg-inner"}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: overflow,
          display: "flex",
          flexDirection: "column",
          ...innerStyle,
        }}
        className={css(hideScrollbarStyles)}
        onScroll={onScroll}
      >
        {children}
      </div>
    </div>
  );
};

// @ts-ignore
export default React.forwardRef(ScrollableFlexGrow);
