import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { CSSProperties, FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
  maxWidth?: number;
  overflow?: CSSProperties["overflow"];
  style?: CSSProperties;
  dataTestId?: string;
};

const DetailPage: FC<Props> = ({
  children,
  maxWidth = 1024,
  overflow = "auto",
  style,
  dataTestId,
}) => {
  return (
    <AutoLayout
      flex={1}
      alignSelf="stretch"
      style={{ overflow, ...style }}
      alignmentHorizontal="center"
      data-testid={dataTestId}
    >
      <AutoLayout
        marginHorizontal={32}
        style={{ maxWidth, minWidth: 0 }}
        direction="vertical"
        flex={1}
        alignSelf="stretch"
      >
        {children}
      </AutoLayout>
    </AutoLayout>
  );
};

export default DetailPage;
