import * as React from "react";
import { IconProps } from "../types";

const TextItalicIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12 3C12.5523 3 13 2.55228 13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2C11 2.55228 11.4477 3 12 3Z"
          fill={color}
        />
        <path
          d="M10.75 13.5H9.253L11.47 5.96102C11.536 5.73402 11.492 5.48902 11.351 5.30002C11.209 5.11102 10.987 4.99902 10.75 4.99902H8.25C7.836 4.99902 7.5 5.33502 7.5 5.74902C7.5 6.16302 7.836 6.49902 8.25 6.49902H9.748L7.689 13.499H5.75C5.336 13.499 5 13.835 5 14.249C5 14.663 5.336 14.999 5.75 14.999H10.75C11.164 14.999 11.5 14.663 11.5 14.249C11.5 13.835 11.164 13.5 10.75 13.5Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default TextItalicIcon;
