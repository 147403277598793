import { FC } from "react";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { StyleDeclaration, StyleSheet } from "aphrodite";
import RichTextEditor from "../libraryItemDetailPages/course/RichTextEditor";
import TiptapEditorContextProvider from "../libraryItemDetailPages/course/contexts/TiptapEditorContext";
import { cn } from "@src/ui/lib/utils";

type Props = {
  text: string;
  isSelected: boolean;
  maxWidth?: number;
  styleOverrides?: StyleDeclaration;
  className?: string;
};

const RichTextPreview: FC<Props> = ({
  text,
  isSelected,
  styleOverrides,
  className,
}) => {
  return (
    <TiptapEditorContextProvider>
      <RichTextEditor
        text={text}
        disabled={true}
        hasEditContentPermission={false}
        styles={[isSelected && styles.selectedTextColor, styleOverrides]}
        className={cn(
          "m-0 truncate text-[14px] leading-[20px] tracking-[-0.006em] text-foreground",
          className,
        )}
      />
    </TiptapEditorContextProvider>
  );
};

const styles = StyleSheet.create({
  selectedTextColor: {
    color: deprecatedTones.gray11,
  },
});

export default RichTextPreview;
