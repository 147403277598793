import { Tooltip } from "@components/ui/Tooltip";
import FlexRow from "@components/ui/flexBox/FlexRow";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Button from "@src/deprecatedDesignSystem/components/Button";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import LanguageSelectorDropdown from "@src/deprecatedDesignSystem/components/LanguageSelectorDropdown";
import Text from "@ui/text";
import { StyleSheet, css } from "aphrodite";
import { AnimatePresence, motion } from "framer-motion";
import { FC } from "react";

type Props = {
  selectedLanguage: Language;
  translationModeEnabled: boolean;
  onLanguageChange: (language: Language) => void;
  onClose?: () => void;
  retranslate?: (lang: Language) => void;
  retranslateLoading?: boolean;
};

const TranslationBanner: FC<Props> = ({
  selectedLanguage,
  translationModeEnabled,
  onLanguageChange,
  onClose,
  retranslate,
  retranslateLoading,
}) => {
  return (
    <AnimatePresence initial={false}>
      <motion.div
        className={css(styles.container)}
        animate={
          translationModeEnabled
            ? "translationModeEnabled"
            : "translationModeDisabled"
        }
        variants={{
          translationModeEnabled: {
            height: 48,
            opacity: 1,
            transition: {
              delay: 0,
              duration: 0.3,
            },
          },
          translationModeDisabled: {
            height: 0,
            opacity: 0,
            transition: {
              delay: 0,
              duration: 0.3,
            },
          },
        }}
      >
        <FlexRow jc={"center"} flex={1}>
          <Text
            color={deprecatedColors.onPrimary}
            fontWeight="Medium"
            type="P2"
          >
            English
          </Text>
        </FlexRow>
        <div className={css(styles.border)} />
        <FlexRow jc={"center"} flex={1}>
          <LanguageSelectorDropdown
            selectedLanguage={selectedLanguage}
            onLanguageChange={onLanguageChange}
          />
          {translationModeEnabled && retranslate && (
            <Tooltip
              text={retranslateLoading ? "Retranslating..." : "Retranslate"}
              arrow
              placement="top"
              verticalOffset={7}
              horizontalOffset={28}
              style={{ display: "flex" }}
            >
              <motion.div
                className={css(styles.retranslateContainer)}
                animate={retranslateLoading ? "loading" : undefined}
                variants={{
                  loading: {
                    rotate: -360,
                    transition: {
                      delay: 0,
                      duration: 1.2,
                      repeat: Infinity,
                      type: "spring",
                    },
                  },
                }}
              >
                <DeprecatedIcon
                  type="rotate-2"
                  color={deprecatedTones.white}
                  onClick={() => retranslate(selectedLanguage)}
                />
              </motion.div>
            </Tooltip>
          )}
        </FlexRow>
        {onClose && (
          <Button
            leftIcon="close"
            onClick={onClose}
            styleDeclaration={styles.close}
          />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    backgroundColor: deprecatedTones.blue9,
    justifyContent: "space-evenly",
    alignItems: "center",
    alignSelf: "stretch",
    position: "relative",
    overflow: "hidden",
  },
  border: {
    width: "1px",
    background: deprecatedTones.white,
    borderRadius: 1,
    height: 32,
  },
  close: {
    position: "absolute",
    right: 12,
    top: "50%",
    transform: "translateY(-50%)",
  },
  retranslateContainer: {
    marginLeft: 12,
  },
});

export default TranslationBanner;
