import Button from "@src/deprecatedDesignSystem/components/Button";
import { useModal } from "@src/hooks/useModal";
import { BrandSelectOption } from "@src/hooks/useAIAssistBrandSelector";
import AIAssistContextModal from "../modals/ai/AIAssistContextModal";

export const ViewBrandContextButton = ({
  brandSelectOption,
}: {
  brandSelectOption: BrandSelectOption;
}): JSX.Element => {
  const { showModal, closeModal } = useModal();
  return (
    <Button
      style={{ height: "36px" }}
      text="View Brand Context"
      variant="Outline"
      onClick={() => {
        showModal(
          <AIAssistContextModal
            brandSelectOption={brandSelectOption}
            closeModal={closeModal}
          />,
        );
      }}
    />
  );
};
