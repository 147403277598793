import gql from "graphql-tag";
import { useBillingInitializedBillingAccountsQuery } from "./useBillingInitialized.generated";

export const useBillingInitialized = (): boolean => {
  const { data } = useBillingInitializedBillingAccountsQuery({});
  return !!data?.BillingAccounts && data?.BillingAccounts?.length > 0;
};

gql`
  query BillingInitializedBillingAccounts {
    BillingAccounts(input: {}) {
      id
    }
  }
`;
