import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsStaffQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IsStaffQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', isStaff: boolean } | null };


export const IsStaffDocument = gql`
    query IsStaff {
  Me {
    isStaff
  }
}
    `;

/**
 * __useIsStaffQuery__
 *
 * To run a query within a React component, call `useIsStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsStaffQuery(baseOptions?: Apollo.QueryHookOptions<IsStaffQuery, IsStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsStaffQuery, IsStaffQueryVariables>(IsStaffDocument, options);
      }
export function useIsStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsStaffQuery, IsStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsStaffQuery, IsStaffQueryVariables>(IsStaffDocument, options);
        }
export type IsStaffQueryHookResult = ReturnType<typeof useIsStaffQuery>;
export type IsStaffLazyQueryHookResult = ReturnType<typeof useIsStaffLazyQuery>;
export type IsStaffQueryResult = Apollo.QueryResult<IsStaffQuery, IsStaffQueryVariables>;