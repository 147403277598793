import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import { FC } from "react";

const INTERACTIVITY_CHOICE_LABELS: { [key: string]: string } = {
  "0": "No Interactivity",
  "1": "Some Interactivity",
  "2": "Medium",
  "4": "Most Interactivity",
};

type InteractionOptionProps = {
  interactivity: number;
  setInteractivity: (interactivity: number) => void;
};

const InteractionOptions: FC<InteractionOptionProps> = ({
  interactivity,
  setInteractivity,
}) => {
  return (
    <AutoLayout spaceBetweenItems={12} wrap="wrap">
      {Object.keys(INTERACTIVITY_CHOICE_LABELS).map((k) => (
        <Button
          key={k}
          text={INTERACTIVITY_CHOICE_LABELS[k]}
          variant={interactivity === Number(k) ? "Secondary" : "Outline"}
          fontColor={
            interactivity === Number(k)
              ? deprecatedTones.white
              : deprecatedTones.gray8
          }
          onClick={() => setInteractivity(Number(k))}
        />
      ))}
    </AutoLayout>
  );
};

export default InteractionOptions;
