import Button from "@src/deprecatedDesignSystem/components/Button";
import { useModal } from "@src/hooks/useModal";
import { BrandSelectOption } from "@src/hooks/useAIAssistBrandSelector";
import { AIAssistReviewContextModal } from "../modals/ai/AIAssistReviewContextModal";

export const AddBrandContextButton = ({
  brandSelectOption,
}: {
  brandSelectOption: BrandSelectOption;
}): JSX.Element => {
  const { showModal, closeModal } = useModal();
  return (
    <Button
      style={{ height: "36px" }}
      text="Add Brand Context"
      variant="Primary"
      onClick={() => {
        showModal(
          <AIAssistReviewContextModal
            brandSelectOption={brandSelectOption}
            closeModal={closeModal}
          />,
        );
      }}
    />
  );
};
