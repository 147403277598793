import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import ContentAvatar from "@src/deprecatedDesignSystem/components/ContentAvatar";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Spinner from "@src/deprecatedDesignSystem/components/Spinner";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { StyleSheet } from "aphrodite";
import { FC } from "react";

type Props = {
  url?: string;
};
export const MediaPreview: FC<Props> = ({ url }) => {
  return (
    <AutoLayout
      width={56}
      height={56}
      styleDeclaration={styles.container}
      alignmentHorizontal="center"
      alignmentVertical="center"
    >
      {!url && <Spinner size={32} />}
      {url && (
        <div
          style={{ position: "relative", cursor: "pointer" }}
          onClick={() => {
            window.open(url, "_blank");
          }}
        >
          <ContentAvatar
            coverImage={{
              imageUrls: {
                original: url,
              },
            }}
            size={"40px"}
            contentType={"Resource"}
            defaultBackgroundHashKey={url}
          />
          <div
            style={{
              width: 22,
              position: "absolute",
              top: 0,
              right: 0,
              height: 22,
              borderRadius: "0 4px 0 4px",
              backgroundColor: deprecatedTones.gray7Alpha,
            }}
          >
            <DeprecatedIcon type="arrow-up-right" iconSize={16} />
          </div>
        </div>
      )}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    backgroundColor: deprecatedTones.gray5Alpha,
  },
});
