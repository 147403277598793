import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AiAddEmojisToTextMutationVariables = Types.Exact<{
  text: Types.Scalars['String'];
}>;


export type AiAddEmojisToTextMutation = { __typename?: 'Mutation', AIAddEmojisToText: { __typename?: 'StringMutationResult', value?: string | null, success: boolean } };

export type AiSuggestSpellingAndGrammarChangesToTextMutationVariables = Types.Exact<{
  text: Types.Scalars['String'];
}>;


export type AiSuggestSpellingAndGrammarChangesToTextMutation = { __typename?: 'Mutation', AISuggestSpellingAndGrammarChangesToText: { __typename?: 'StringMutationResult', value?: string | null, success: boolean } };

export type AiSuggestReadingLevelChangesToTextMutationVariables = Types.Exact<{
  text: Types.Scalars['String'];
}>;


export type AiSuggestReadingLevelChangesToTextMutation = { __typename?: 'Mutation', AISuggestReadingLevelChangesToText: { __typename?: 'StringMutationResult', value?: string | null, success: boolean } };


export const AiAddEmojisToTextDocument = gql`
    mutation AIAddEmojisToText($text: String!) {
  AIAddEmojisToText(text: $text) {
    value
    success
  }
}
    `;
export type AiAddEmojisToTextMutationFn = Apollo.MutationFunction<AiAddEmojisToTextMutation, AiAddEmojisToTextMutationVariables>;

/**
 * __useAiAddEmojisToTextMutation__
 *
 * To run a mutation, you first call `useAiAddEmojisToTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiAddEmojisToTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiAddEmojisToTextMutation, { data, loading, error }] = useAiAddEmojisToTextMutation({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAiAddEmojisToTextMutation(baseOptions?: Apollo.MutationHookOptions<AiAddEmojisToTextMutation, AiAddEmojisToTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AiAddEmojisToTextMutation, AiAddEmojisToTextMutationVariables>(AiAddEmojisToTextDocument, options);
      }
export type AiAddEmojisToTextMutationHookResult = ReturnType<typeof useAiAddEmojisToTextMutation>;
export type AiAddEmojisToTextMutationResult = Apollo.MutationResult<AiAddEmojisToTextMutation>;
export type AiAddEmojisToTextMutationOptions = Apollo.BaseMutationOptions<AiAddEmojisToTextMutation, AiAddEmojisToTextMutationVariables>;
export const AiSuggestSpellingAndGrammarChangesToTextDocument = gql`
    mutation AISuggestSpellingAndGrammarChangesToText($text: String!) {
  AISuggestSpellingAndGrammarChangesToText(text: $text) {
    value
    success
  }
}
    `;
export type AiSuggestSpellingAndGrammarChangesToTextMutationFn = Apollo.MutationFunction<AiSuggestSpellingAndGrammarChangesToTextMutation, AiSuggestSpellingAndGrammarChangesToTextMutationVariables>;

/**
 * __useAiSuggestSpellingAndGrammarChangesToTextMutation__
 *
 * To run a mutation, you first call `useAiSuggestSpellingAndGrammarChangesToTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiSuggestSpellingAndGrammarChangesToTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiSuggestSpellingAndGrammarChangesToTextMutation, { data, loading, error }] = useAiSuggestSpellingAndGrammarChangesToTextMutation({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAiSuggestSpellingAndGrammarChangesToTextMutation(baseOptions?: Apollo.MutationHookOptions<AiSuggestSpellingAndGrammarChangesToTextMutation, AiSuggestSpellingAndGrammarChangesToTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AiSuggestSpellingAndGrammarChangesToTextMutation, AiSuggestSpellingAndGrammarChangesToTextMutationVariables>(AiSuggestSpellingAndGrammarChangesToTextDocument, options);
      }
export type AiSuggestSpellingAndGrammarChangesToTextMutationHookResult = ReturnType<typeof useAiSuggestSpellingAndGrammarChangesToTextMutation>;
export type AiSuggestSpellingAndGrammarChangesToTextMutationResult = Apollo.MutationResult<AiSuggestSpellingAndGrammarChangesToTextMutation>;
export type AiSuggestSpellingAndGrammarChangesToTextMutationOptions = Apollo.BaseMutationOptions<AiSuggestSpellingAndGrammarChangesToTextMutation, AiSuggestSpellingAndGrammarChangesToTextMutationVariables>;
export const AiSuggestReadingLevelChangesToTextDocument = gql`
    mutation AISuggestReadingLevelChangesToText($text: String!) {
  AISuggestReadingLevelChangesToText(text: $text) {
    value
    success
  }
}
    `;
export type AiSuggestReadingLevelChangesToTextMutationFn = Apollo.MutationFunction<AiSuggestReadingLevelChangesToTextMutation, AiSuggestReadingLevelChangesToTextMutationVariables>;

/**
 * __useAiSuggestReadingLevelChangesToTextMutation__
 *
 * To run a mutation, you first call `useAiSuggestReadingLevelChangesToTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAiSuggestReadingLevelChangesToTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [aiSuggestReadingLevelChangesToTextMutation, { data, loading, error }] = useAiSuggestReadingLevelChangesToTextMutation({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAiSuggestReadingLevelChangesToTextMutation(baseOptions?: Apollo.MutationHookOptions<AiSuggestReadingLevelChangesToTextMutation, AiSuggestReadingLevelChangesToTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AiSuggestReadingLevelChangesToTextMutation, AiSuggestReadingLevelChangesToTextMutationVariables>(AiSuggestReadingLevelChangesToTextDocument, options);
      }
export type AiSuggestReadingLevelChangesToTextMutationHookResult = ReturnType<typeof useAiSuggestReadingLevelChangesToTextMutation>;
export type AiSuggestReadingLevelChangesToTextMutationResult = Apollo.MutationResult<AiSuggestReadingLevelChangesToTextMutation>;
export type AiSuggestReadingLevelChangesToTextMutationOptions = Apollo.BaseMutationOptions<AiSuggestReadingLevelChangesToTextMutation, AiSuggestReadingLevelChangesToTextMutationVariables>;