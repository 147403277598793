import * as React from "react";
import { IconProps } from "../types";

const TextBoldIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.653 8.471C13.473 7.738 14 6.684 14 5.5C14 3.294 12.206 1.5 10 1.5H6.25C5.836 1.5 5.5 1.836 5.5 2.25C5.5 2.664 5.836 3 6.25 3H10C11.379 3 12.5 4.122 12.5 5.5C12.5 6.878 11.379 8 10 8H6.25C5.836 8 5.5 8.336 5.5 8.75C5.5 9.164 5.836 9.5 6.25 9.5H10.75C12.267 9.5 13.5 10.733 13.5 12.25C13.5 13.767 12.267 15 10.75 15H6.25C5.836 15 5.5 15.336 5.5 15.75C5.5 16.164 5.836 16.5 6.25 16.5H10.75C13.094 16.5 15 14.593 15 12.25C15 10.594 14.039 9.172 12.653 8.471Z"
          fill={color}
        />
        <path
          d="M6 1.5H4.75C3.784 1.5 3 2.284 3 3.25V14.75C3 15.716 3.784 16.5 4.75 16.5H6V1.5Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default TextBoldIcon;
