import {
  CreateAiCourseResultFragment,
  useCreateAiCourseMutation,
} from "@components/modals/ai/AIGenerateCourseModal.generated";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { useToast } from "@hooks/useToast";
import { StyleSheet } from "aphrodite";
import gql from "graphql-tag";
import { FC } from "react";
import { AIGenerateCourseAIAssist } from "./AIGenerateCourseAIAssist";

type Props = {
  closeModal: () => void;
  onCourseCreated: (createdCourse: CreateAiCourseResultFragment) => void;
  initialText?: string;
};

const AIGenerateCourseModal: FC<Props> = ({
  closeModal,
  initialText,
  onCourseCreated,
}) => {
  const { addErrorToast } = useToast();
  const [createAICourseMutation, { loading }] = useCreateAiCourseMutation({
    onCompleted: (data) => {
      if (data.createAICourse.success && data.createAICourse.course) {
        onCourseCreated(data.createAICourse.course);
        closeModal();
      } else {
        addErrorToast({
          callsite: "create_ai_course_modal",
        });
      }
    },
    onError: () => {
      addErrorToast({
        callsite: "create_ai_course_modal",
      });
    },
  });

  return (
    <AIGenerateCourseAIAssist
      title="New Course"
      closeModal={closeModal}
      placeholder="Paste text from an existing course or give a brief description or prompt, for example, “How to make a pepperoni pizza” or “The basics of pizza making”."
      loading={loading}
      generate={createAICourseMutation}
      initialText={initialText}
      question={"What is your course about?"}
      buttonText={"Generate course"}
    />
  );
};

export const styles = StyleSheet.create({
  modal: {
    maxHeight: "min(95vh, 800px)",
  },
  gridChoice: {
    backgroundColor: deprecatedTones.blue2,
    borderRadius: 16,
    width: "100%",
  },
  choiceContainer: {
    border: `1px solid #EBEBEB`,
    borderRadius: 16,
    height: 278,
    width: "100%",
  },
  choiceInside: {
    position: "relative",
  },
  yMinLabel: {
    position: "absolute",
    bottom: "calc(100% + 21px)",
    left: "50%",
    transform: "translateX(-50%)",
  },
  yMaxLabel: {
    position: "absolute",
    top: "calc(100% + 21px)",
    left: "50%",
    transform: "translateX(-50%)",
  },
  xMinLabel: {
    position: "absolute",
    right: "calc(100% + 21px)",
    top: "50%",
    transform: "translateY(-50%)",
  },
  xMaxLabel: {
    position: "absolute",
    left: "calc(100% + 21px)",
    top: "50%",
    transform: "translateY(-50%)",
  },
  footer: {
    borderTop: "1px solid #EBEBEB",
  },
  textField: {
    width: "100%",
    padding: 20,
    fontSize: 16,
    letterSpacing: "-0.01em",
    lineHeight: "22px",
  },
  aiAssistBadge: {
    padding: "6px 8px",
    borderRadius: 40,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
});

gql`
  mutation CreateAICourse($input: AICourseInput!) {
    createAICourse(input: $input) {
      success
      error {
        code
      }
      course {
        ...CreateAICourseResult
      }
    }
  }

  fragment CreateAICourseResult on Course {
    id
    draftVersion {
      id
    }
    libraryItem {
      id
    }
  }
`;

export default AIGenerateCourseModal;
