import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { CSSProperties, ReactElement, useMemo, useState } from "react";
import * as Select from "@radix-ui/react-select";
import useRefHeightAndWidth from "@src/hooks/useRefHeightAndWidth";
import DeprecatedIcon, {
  DeprecatedIconType,
} from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text, { TextFontWeight } from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { curves, durations, properties } from "../styles/transitions";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Fuse from "fuse.js";
import { shadows } from "../styles/shadows";

export interface Option<T extends string> {
  value: T;
  label: string;
  labelComponent?: React.ReactNode;
}

interface Props<T extends string> {
  value: T | undefined;
  options: Option<T>[];
  includeNoneOption?: boolean;
  searchEnabled?: boolean;
  placeholder: string;
  placeholderTextProps?: Partial<React.ComponentProps<typeof Text>>;
  onValueChange: (newValue: T | undefined) => void;
  icon?: DeprecatedIconType;
  label?: string;
  fontWeight?: TextFontWeight;
  dropdownHeight?: number;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  labelStyle?: CSSProperties;
}

function getValueLabel<T extends string>(
  value: T,
  options: Option<T>[],
): React.ReactNode {
  return options.filter((option) => option.value === value)[0]?.label;
}

function SingleSelectField<T extends string>({
  value,
  options,
  includeNoneOption,
  searchEnabled,
  placeholder,
  placeholderTextProps,
  onValueChange,
  icon,
  label,
  fontWeight = "SemiBold",
  dropdownHeight,
  style,
  styleDeclaration,
  containerStyle,
  labelStyle,
}: Props<T>): ReactElement {
  const [ref, dimensions] = useRefHeightAndWidth();
  const [isEditing, setIsEditing] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const optionsToDisplay = useMemo(() => {
    if (!searchEnabled || searchValue === "") {
      return options;
    }
    return new Fuse(options, {
      keys: ["label"],
      threshold: 0.3,
    })
      .search(searchValue)
      .map((item) => item.item);
  }, [options, searchEnabled, searchValue]);
  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [options, value]);
  const dropdownComponent = useMemo(() => {
    return (
      <>
        {includeNoneOption && !searchValue && (
          <AutoLayout
            className={css(styles.optionContainer)}
            onClick={() => {
              onValueChange(undefined);
              setIsEditing(false);
              setSearchValue("");
            }}
          >
            <Text
              type="P2"
              fontWeight={fontWeight}
              color={deprecatedTones.gray7}
            >
              None
            </Text>
          </AutoLayout>
        )}
        {optionsToDisplay
          .filter((option) => option.value !== value)
          .map((option) => (
            <AutoLayout
              key={option.value}
              className={css(styles.optionContainer)}
              onClick={() => {
                onValueChange(option.value);
                setIsEditing(false);
                setSearchValue("");
              }}
            >
              {option.labelComponent || (
                <Text type="P2" fontWeight={fontWeight}>
                  {option.label}
                </Text>
              )}
            </AutoLayout>
          ))}
      </>
    );
  }, [
    fontWeight,
    includeNoneOption,
    onValueChange,
    optionsToDisplay,
    searchValue,
    value,
  ]);
  return (
    <div style={containerStyle}>
      {label && (
        <label style={labelStyle}>
          <Text
            type="P3"
            fontWeight={"SemiBold"}
            inline
            style={{ marginBottom: 4 }}
          >
            {label}
          </Text>
        </label>
      )}
      <Select.Root open={isEditing}>
        <Select.Trigger asChild>
          <div
            ref={ref}
            className={css(styles.singleSelectContainer, styleDeclaration)}
            onClick={() => setIsEditing(true)}
            style={style}
          >
            <div className={css(styles.valueContainer)}>
              {icon && (
                <DeprecatedIcon type={icon} color={deprecatedTones.gray7} />
              )}
              {selectedOption?.labelComponent || (
                <>
                  {value && (
                    <Text
                      type="P2"
                      fontWeight={fontWeight}
                      color={deprecatedTones.black}
                    >
                      {getValueLabel(value, options)}
                    </Text>
                  )}
                  {!value && (
                    <Text
                      type="P2"
                      fontWeight={fontWeight}
                      color={deprecatedTones.gray5}
                      {...placeholderTextProps}
                    >
                      {placeholder}
                    </Text>
                  )}
                </>
              )}
            </div>
            <DeprecatedIcon
              type="chevron-down"
              iconSize={24}
              color={deprecatedTones.gray5}
            />
          </div>
        </Select.Trigger>
        <Select.Content
          onPointerDownOutside={() => {
            setIsEditing(false);
            setSearchValue("");
          }}
          style={{ zIndex: 1000 }}
        >
          <div
            className={css(styles.contentContainer)}
            style={{
              width: dimensions.width || undefined,
              maxWidth: dimensions.width || undefined,
            }}
          >
            {searchEnabled && (
              <AutoLayout paddingHorizontal={8} paddingTop={8}>
                <TextField
                  leftIcon={"search"}
                  placeholder={"Search"}
                  text={searchValue}
                  onTextChange={setSearchValue}
                  autoFocus={true}
                />
              </AutoLayout>
            )}
            {options.length === 0 && (
              <AutoLayout alignmentVertical={"center"} height={40}>
                <Text
                  type={"P3"}
                  fontWeight={"SemiBold"}
                  color={deprecatedTones.gray7}
                  style={{
                    marginLeft: 8,
                  }}
                >
                  No options
                </Text>
              </AutoLayout>
            )}
            {options.length > 0 &&
              optionsToDisplay.length === 0 &&
              searchValue && (
                <AutoLayout alignmentVertical={"center"} height={40}>
                  <Text
                    type={"P3"}
                    fontWeight={"SemiBold"}
                    color={deprecatedTones.gray7}
                    style={{
                      marginLeft: 8,
                    }}
                  >
                    No results
                  </Text>
                </AutoLayout>
              )}
            {!searchEnabled && options.length > 0 && (
              <Select.Viewport
                className={css(styles.optionsContainer)}
                style={{ maxHeight: `${dropdownHeight}px` }}
              >
                {dropdownComponent}
              </Select.Viewport>
            )}
            {searchEnabled && options.length > 0 && (
              <AutoLayout
                direction={"vertical"}
                alignSelf={"stretch"}
                flex={1}
                className={css(styles.optionsContainer)}
                style={{ maxHeight: `${dropdownHeight}px` }}
              >
                {dropdownComponent}
              </AutoLayout>
            )}
          </div>
        </Select.Content>
      </Select.Root>
    </div>
  );
}

const styles = StyleSheet.create({
  singleSelectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 6px",
    paddingRight: 12,
    backgroundColor: deprecatedTones.white,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    borderRadius: 8,
    cursor: "pointer",
    width: "100%",
  },
  valueContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    marginRight: 2,
    marginLeft: 4,
  },
  contentContainer: {
    overflow: "hidden",
    borderRadius: 8,
    backgroundColor: deprecatedTones.white,
    boxShadow: shadows.dropdownShadow,
  },
  optionsContainer: {
    padding: "6px 0",
    overflowY: "scroll",
    borderRadius: 12,
  },
  optionContainer: {
    alignSelf: "stretch",
    display: "flex",
    padding: 8,
    backgroundColor: deprecatedTones.white,
    transitionDuration: durations.S015,
    transitionProperty: properties.backgroundColor,
    transitionTimingFunction: curves.bezier,
    ":focus": {
      border: "none",
      outline: "none",
      backgroundColor: deprecatedTones.gray4Alpha,
    },
    ":hover": {
      cursor: "pointer",
      border: "none",
      outline: "none",
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
});

export default SingleSelectField;
