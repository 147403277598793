import {
  addDeprecatedOpacityToHexColor,
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { css, StyleSheet } from "aphrodite";
import React, { FC, IframeHTMLAttributes, useMemo } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import Spacer from "@components/ui/Spacer";
import useBrandColor from "@hooks/useBrandColor";
import OpenLinkPill from "./OpenLinkPill";

type Props = {
  url: string;
  loading: boolean;
  ogData: OgData | undefined;
  ogVideoIframeProps?: Partial<IframeHTMLAttributes<HTMLIFrameElement>>;
  ogImageProps?: Partial<React.ImgHTMLAttributes<HTMLImageElement>>;
};

const OpenGraphLinkPreview: FC<Props> = ({
  url,
  ogData,
  ogVideoIframeProps,
  ogImageProps,
}) => {
  const brandColor = useBrandColor();
  const centerContent = useMemo(() => {
    const ogTitle = ogData?.["og:title"];
    if (ogData?.["og:video:url"]) {
      const url = ogData?.["og:video:url"];
      return (
        <iframe
          id={"video-iframe"}
          className={css(styles.iframe)}
          title={ogTitle}
          src={url}
          {...ogVideoIframeProps}
        />
      );
    } else if (ogData?.["og:image"]) {
      let img = ogData?.["og:image"];
      if (img.startsWith("/")) {
        img = ogData?.["og:url"] + img;
      }
      return (
        <img
          title={ogTitle}
          src={img}
          alt={ogData?.["og:description"] || ogTitle}
          {...ogImageProps}
          style={{
            maxWidth: "100%",
            maxHeight: 200,
            objectFit: "cover",
            ...ogImageProps?.style,
          }}
        />
      );
    } else {
      return (
        <AutoLayout
          width={300}
          flex={1}
          alignmentVertical={"center"}
          alignmentHorizontal={"center"}
          style={{
            aspectRatio: "327/179",
            backgroundColor: addDeprecatedOpacityToHexColor(brandColor, 0.2),
          }}
        >
          <DeprecatedIcon
            type="link"
            color={brandColor}
            iconSize={100}
            containerSize={100}
          />
        </AutoLayout>
      );
    }
  }, [brandColor, ogData, ogImageProps, ogVideoIframeProps]);
  return (
    <AutoLayout
      id={"PromptMediaFileAndLinkContainer"}
      flex={1}
      alignSelf={"stretch"}
      direction={"vertical"}
      alignmentHorizontal={"center"}
      padding={18}
      style={{
        backgroundColor: deprecatedTones.gray5Alpha,
      }}
    >
      <AutoLayout flex={1} />
      <AutoLayout
        externalUrl={url}
        linkOpenInNewTab={true}
        direction={"vertical"}
        style={{
          cursor: "pointer",
          borderRadius: 20,
          overflow: "hidden",
          boxShadow:
            "0px 0px 3.58904px rgba(0, 0, 0, 0.14), 0px 7.17809px 54.36px rgba(0, 0, 0, 0.11)",
        }}
      >
        <AutoLayout
          padding={8}
          style={{ backgroundColor: deprecatedTones.white }}
        >
          <AutoLayout
            style={{
              borderRadius: 16,
              overflow: "hidden",
              backgroundColor: deprecatedTones.gray4Alpha,
            }}
          >
            {centerContent}
          </AutoLayout>
        </AutoLayout>
        <AutoLayout
          direction={"vertical"}
          height={ogData?.["og:title"] ? 59 : 42}
          alignmentVertical={"center"}
          paddingHorizontal={16}
          paddingBottom={8}
          alignSelf={"stretch"}
          style={{
            backgroundColor: deprecatedTones.white,
          }}
        >
          <Text
            type={"P1"}
            fontWeight={"SemiBold"}
            ellipsis={true}
            style={{
              alignSelf: "stretch",
            }}
          >
            {ogData?.["og:title"] || url}
          </Text>
          {ogData?.["og:title"] && (
            <>
              <Spacer size={2} />
              <Text
                type={"P2"}
                fontWeight={"SemiBold"}
                color={deprecatedTones.gray7}
                ellipsis={true}
                style={{
                  alignSelf: "stretch",
                }}
              >
                {url}
              </Text>
            </>
          )}
        </AutoLayout>
      </AutoLayout>
      <AutoLayout flex={1} direction={"vertical"}>
        <Spacer size={24} />
        <OpenLinkPill url={url} text={"View Link"} />
      </AutoLayout>
    </AutoLayout>
  );
};

export default OpenGraphLinkPreview;

const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    display: "flex",
    flexDirection: "column",
    backgroundColor: deprecatedTones.gray12,
    marginBottom: 5,
  },
  placeholder: {
    borderRadius: "12px 12px 0 0",
  },
  iframe: {
    borderRadius: 12,
    outline: "none",
    border: "none",
    cursor: "pointer",
  },
  title: {
    color: deprecatedColors.background,
    padding: 12,
    fontWeight: 500,
    fontSize: 13,
    cursor: "pointer",
    textDecoration: "none",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "40px 0 5px 0",
    fontSize: 15,
    borderRadius: 12,
    alignSelf: "flex-start",
    backgroundColor: deprecatedColors.primaryContainer,
    padding: "16px 24px",
    textDecoration: "none",
    color: deprecatedColors.onBackground,
  },
});

export type OgData = {
  "og:title": string | undefined;
  "og:description": string | undefined;
  "og:image": string | undefined;
  "og:url": string | undefined;
  "og:video:url": string | undefined;
  "og:image:height": string | undefined;
  "og:image:width": string | undefined;
  "og:site_name": string | undefined;
  "og:type": string | undefined;
  "og:video:height": string | undefined;
  "og:video:secure_url": string | undefined;
  "og:video:tag": string | undefined;
  "og:video:type": string | undefined;
  "og:video:width": string | undefined;
};
