import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMessageBlastMutationVariables = Types.Exact<{
  input: Types.MessageBlastInput;
}>;


export type CreateMessageBlastMutation = { __typename?: 'Mutation', createMessageBlast: { __typename?: 'MessageBlastMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, messageBlast?: { __typename?: 'MessageBlast', id: number, recipientCount: number, draft: boolean } | null } };

export type UpdateMessageBlastMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
  input: Types.MessageBlastInput;
}>;


export type UpdateMessageBlastMutation = { __typename?: 'Mutation', updateMessageBlast: { __typename?: 'MessageBlastMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, messageBlast?: { __typename?: 'MessageBlast', id: number, recipientCount: number, draft: boolean } | null } };


export const CreateMessageBlastDocument = gql`
    mutation CreateMessageBlast($input: MessageBlastInput!) {
  createMessageBlast(input: $input) {
    success
    error {
      code
    }
    messageBlast {
      id
      recipientCount
      draft
    }
  }
}
    `;
export type CreateMessageBlastMutationFn = Apollo.MutationFunction<CreateMessageBlastMutation, CreateMessageBlastMutationVariables>;

/**
 * __useCreateMessageBlastMutation__
 *
 * To run a mutation, you first call `useCreateMessageBlastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageBlastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageBlastMutation, { data, loading, error }] = useCreateMessageBlastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageBlastMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageBlastMutation, CreateMessageBlastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageBlastMutation, CreateMessageBlastMutationVariables>(CreateMessageBlastDocument, options);
      }
export type CreateMessageBlastMutationHookResult = ReturnType<typeof useCreateMessageBlastMutation>;
export type CreateMessageBlastMutationResult = Apollo.MutationResult<CreateMessageBlastMutation>;
export type CreateMessageBlastMutationOptions = Apollo.BaseMutationOptions<CreateMessageBlastMutation, CreateMessageBlastMutationVariables>;
export const UpdateMessageBlastDocument = gql`
    mutation UpdateMessageBlast($id: Int!, $input: MessageBlastInput!) {
  updateMessageBlast(id: $id, input: $input) {
    success
    error {
      code
    }
    messageBlast {
      id
      recipientCount
      draft
    }
  }
}
    `;
export type UpdateMessageBlastMutationFn = Apollo.MutationFunction<UpdateMessageBlastMutation, UpdateMessageBlastMutationVariables>;

/**
 * __useUpdateMessageBlastMutation__
 *
 * To run a mutation, you first call `useUpdateMessageBlastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMessageBlastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMessageBlastMutation, { data, loading, error }] = useUpdateMessageBlastMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMessageBlastMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMessageBlastMutation, UpdateMessageBlastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMessageBlastMutation, UpdateMessageBlastMutationVariables>(UpdateMessageBlastDocument, options);
      }
export type UpdateMessageBlastMutationHookResult = ReturnType<typeof useUpdateMessageBlastMutation>;
export type UpdateMessageBlastMutationResult = Apollo.MutationResult<UpdateMessageBlastMutation>;
export type UpdateMessageBlastMutationOptions = Apollo.BaseMutationOptions<UpdateMessageBlastMutation, UpdateMessageBlastMutationVariables>;