import React, { FC, useCallback, useRef } from "react";
import { deprecatedTones } from "../styles/deprecatedColors";
import { StyleDeclaration, StyleSheet } from "aphrodite";
import useClickOutside from "@src/hooks/useClickOutside";
import { useModal } from "@src/hooks/useModal";
import AutoLayout from "./AutoLayout";
import Text from "@ui/text";
import { Button } from "@ui/button";
import XIcon from "@src/ui/icons/12px/x";
import { cn } from "@src/ui/lib/utils";
import { shadows } from "@src/deprecatedDesignSystem/styles/shadows";

type Props = {
  children: React.ReactNode;
  height?: React.CSSProperties["height"];
  width?: React.CSSProperties["width"];
  styleDeclaration?: StyleDeclaration;
  closeOnClickOutside?: boolean;
};

export const Modal: FC<Props> = ({
  children,
  height,
  width,
  styleDeclaration,
  closeOnClickOutside = false,
}) => {
  const { closeModal } = useModal();
  const ref = useRef<HTMLDivElement>(null);
  const onClickOutside = useCallback(() => {
    if (closeOnClickOutside) {
      closeModal();
    }
  }, [closeOnClickOutside, closeModal]);

  useClickOutside(ref, onClickOutside);
  return (
    <AutoLayout
      minWidth={400}
      width={width}
      direction="vertical"
      styleDeclaration={[styleDeclaration, styles.modalContainer]}
      height={height}
    >
      {children}
    </AutoLayout>
  );
};

type ModalHeaderProps = {
  children?: React.ReactNode;
};
export const ModalHeader: FC<ModalHeaderProps> = ({ children }) => {
  const { closeModal } = useModal();
  return (
    <div className="relative flex items-center justify-between self-stretch border-b border-input px-5 py-4">
      {typeof children === "string" ? (
        <Text fontWeight="SemiBold" type="H4" className="text-foreground">
          {children}
        </Text>
      ) : (
        children
      )}
      <Button
        size="icon"
        variant="ghost"
        onClick={() => closeModal()}
        className="absolute right-2 top-2"
      >
        <XIcon />
      </Button>
    </div>
  );
};

type ModalBodyProps = {
  children?: React.ReactNode;
  className?: string;
};
export const ModalBody: FC<ModalBodyProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "flex flex-1 flex-col self-stretch overflow-auto p-5",
        className,
      )}
    >
      {children}
    </div>
  );
};

type ModalFooterProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ModalFooter: FC<ModalFooterProps> = ({ children, className }) => {
  return (
    <div
      className={cn(
        "flex self-stretch py-3 px-5 gap-8 border-t border-input",
        className,
      )}
    >
      {children}
    </div>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    borderRadius: 12,
    shadow: shadows.elevation3,
    backgroundColor: deprecatedTones.white,
    maxHeight: "max(90vh, 400px)",
  },
  closeBtn: {
    borderRadius: 12,
    width: 32,
    height: 32,
    cursor: "pointer",
    ":hover": {
      backgroundColor: deprecatedTones.gray5Alpha,
    },
  },
});

export default Modal;
