import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import CircleDivider from "@src/deprecatedDesignSystem/components/CircleDivider";
import HorizontalDivider from "@src/deprecatedDesignSystem/components/HorizontalDivider";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

import { getLinkType, humanFileSize, linkTypeCopy } from "@src/utils/files";
import { FC, useState } from "react";
import { FileInput } from "./BulkUploadTrainingResourceModal";
import { MediaPreview } from "./MediaPreview";
import { useTranslation } from "@utils/translationSets";

type Props = {
  file: FileInput;
  updateFileName: (id: string, language: Language, value: string) => void;
  updateFileDescription: (
    id: string,
    language: Language,
    value: string,
  ) => void;
  removeFile: (id: string) => void;
  language: Language;
};
export const UploadedFile: FC<Props> = ({
  file,
  updateFileDescription,
  updateFileName,
  removeFile,
  language,
}) => {
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const type = file.url && getLinkType(file.url);
  const fileNameTranslation = useTranslation(file.name, language).text;
  const fileDescriptionTranslation = useTranslation(
    file.description,
    language,
  ).text;
  return (
    <AutoLayout
      direction="vertical"
      alignSelf={"stretch"}
      style={{
        backgroundColor: deprecatedTones.white,
        borderRadius: 16,
        border: `1px solid ${deprecatedTones.gray5Alpha}`,
      }}
    >
      <AutoLayout padding={12} flex={1} alignSelf="stretch">
        <MediaPreview url={file.url} />
        <AutoLayout
          direction="vertical"
          marginLeft={10}
          alignSelf="center"
          flex={1}
        >
          <Text type="P2" fontWeight="Medium" color={deprecatedTones.gray12}>
            {file.name.en}
          </Text>
          {!file.url && (
            <Text type="P3" color={deprecatedTones.gray5}>
              Uploading....
            </Text>
          )}
          {file.url && (
            <AutoLayout alignmentVertical="center" spaceBetweenItems={4}>
              <Text type="P3" color={deprecatedTones.gray5}>
                {type ? linkTypeCopy[type] : "File"}
              </Text>
              <CircleDivider color={deprecatedTones.gray5} />
              <Text type="P3" color={deprecatedTones.gray5}>
                {file.size ? humanFileSize(file.size) : file.url}
              </Text>
            </AutoLayout>
          )}
        </AutoLayout>
        <DeprecatedIcon type="close" onClick={() => removeFile(file.id)} />
      </AutoLayout>

      <HorizontalDivider />
      <AutoLayout
        alignSelf={"stretch"}
        padding={12}
        direction="vertical"
        spaceBetweenItems={8}
      >
        <TextField
          text={fileNameTranslation || ""}
          onTextChange={(name) => {
            updateFileName(file.id, language, name);
          }}
          label="Name"
        />
        {descriptionVisible && (
          <TextField
            text={fileDescriptionTranslation || ""}
            onTextChange={(description) => {
              updateFileDescription(file.id, language, description);
            }}
            label="Description"
            autoFocus
          />
        )}
        {!descriptionVisible && (
          <Button
            text="+ Add description"
            variant="No Outline"
            textType="P2"
            textFontWeight="Regular"
            fontColor={deprecatedTones.gray6}
            onClick={() => setDescriptionVisible(true)}
          />
        )}
      </AutoLayout>
    </AutoLayout>
  );
};
