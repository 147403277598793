import * as React from "react";
import { IconProps } from "../types";

const OrderedListIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
        ref={forwardedRef}
        {...props}
      >
        <path
          d="M6.548 16.5H2.5C2.292 16.5 2.093 16.414 1.951 16.261C1.809 16.108 1.737 15.904 1.752 15.697C1.874 13.978 3.241 13.419 4.239 13.011C4.836 12.768 5.831 12.275 5.797 11.706C5.759 11.067 4.875 11.004 4.606 11C4.049 10.975 3.405 11.214 3.222 11.863C3.111 12.261 2.702 12.497 2.297 12.383C1.899 12.271 1.666 11.857 1.778 11.458C2.108 10.281 3.228 9.5 4.574 9.5C4.593 9.5 4.61 9.5 4.628 9.5C6.149 9.522 7.221 10.373 7.295 11.616C7.363 12.762 6.525 13.699 4.806 14.4C4.253 14.626 3.883 14.802 3.642 15H6.548C6.962 15 7.298 15.336 7.298 15.75C7.298 16.164 6.962 16.5 6.548 16.5Z"
          fill={color}
        />
        <path
          d="M16.25 6H9.5C9.086 6 8.75 5.664 8.75 5.25C8.75 4.836 9.086 4.5 9.5 4.5H16.25C16.664 4.5 17 4.836 17 5.25C17 5.664 16.664 6 16.25 6Z"
          fill={color}
        />
        <path
          d="M16.25 13.5H9.5C9.086 13.5 8.75 13.164 8.75 12.75C8.75 12.336 9.086 12 9.5 12H16.25C16.664 12 17 12.336 17 12.75C17 13.164 16.664 13.5 16.25 13.5Z"
          fill={color}
        />
        <path
          d="M4.74999 8.24999C4.33599 8.24999 3.99999 7.91399 3.99999 7.49999V3.70799C3.69299 3.87899 3.33599 4.02099 2.92399 4.10099C2.52599 4.17499 2.12399 3.91199 2.04699 3.50499C1.96899 3.09799 2.23499 2.70599 2.64299 2.62799C3.60599 2.44399 4.08199 1.65799 4.10199 1.62499C4.27499 1.33399 4.62599 1.19099 4.94699 1.28099C5.27299 1.36999 5.50099 1.66299 5.50099 2.00099V7.49999C5.50099 7.91399 5.16399 8.24999 4.74999 8.24999Z"
          fill={color}
        />
      </svg>
    );
  }
);

export default OrderedListIcon;
