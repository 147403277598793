import { FormControl, FormItem, FormLabel, FormMessage } from "@src/ui/form";
import {
  Select,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectContent,
} from "@src/ui/select";

export const InlineSelectField = ({
  label,
  options,
  onChange,
  defaultValue,
  disabled = false,
}: {
  label: string;
  options: { label: string; value: string }[];
  onChange: (value: string) => void;
  defaultValue?: string;
  disabled?: boolean;
}): JSX.Element => {
  return (
    <FormItem className="flex w-full items-start gap-4">
      <FormLabel className="w-[216px] leading-normal">{label}</FormLabel>
      <Select
        disabled={disabled}
        autoComplete="both"
        onValueChange={onChange}
        defaultValue={defaultValue}
      >
        <FormControl className="w-full flex-1">
          <SelectTrigger>
            <SelectValue placeholder="Business Type" />
          </SelectTrigger>
        </FormControl>
        <SelectContent className="z-[701]">
          {options.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  );
};
