import React from "react";
import useMediaMetadata from "@src/components/libraryItemDetailPages/course/hooks/useMediaMetadata";
import { useOpenGraphDataQuery } from "@src/components/ui/MediaPreview.generated";
import useTranslationModeContext from "@src/components/libraryItemDetailPages/course/hooks/useTranslationModeContext";
import { VideoRefMapContext } from "@src/components/libraryItemDetailPages/course/contexts/VideoRefMapContext";
import OpenGraphLinkPreview, {
  OgData,
} from "@src/components/libraryItemDetailPages/course/OpenGraphLinkPreview";
import { LinkType } from "@src/utils/files";
import VideoPlayer from "@src/deprecatedDesignSystem/components/VideoPlayer";
import { isVimeoRedirectLink } from "@src/utils/media";
import OpenGraphVideoPlayerIframe from "@src/components/libraryItemDetailPages/course/OpenGraphVideoPlayerIframe";
import PdfPreview from "@src/components/messaging/PdfPreview";
import ImagePreview from "@src/components/messaging/ImagePreview";

type Props = {
  id: string;
  mediaUrl: string;
  className?: string;
};

const defaultStyles = { width: "100%", height: "100%" };

const MessageBlastMediaPreview: React.FC<Props> = (props) => {
  const { linkType, isVideoOrMuxVideo } = useMediaMetadata(props.mediaUrl);
  const openGraphQuery = useOpenGraphDataQuery({
    variables: {
      url: props.mediaUrl,
    },
  });
  const translationModeContext = useTranslationModeContext();
  const selectedLanguage = React.useMemo(() => {
    if (translationModeContext.translationModeEnabled) {
      return translationModeContext.translationLanguage;
    }
    return "en";
  }, [
    translationModeContext.translationLanguage,
    translationModeContext.translationModeEnabled,
  ]);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const { addRef: addVideoRef } = React.useContext(VideoRefMapContext);
  const ogData = openGraphQuery.data?.OpenGraphData as OgData | undefined;
  React.useEffect(() => {
    if (isVideoOrMuxVideo) {
      addVideoRef(props.id, videoRef);
    }
  }, [addVideoRef, isVideoOrMuxVideo, props.id]);
  if (!linkType) return null;
  if ([LinkType.IMAGE, LinkType.GIF].includes(linkType)) {
    return <ImagePreview url={props.mediaUrl} className={props.className} />;
  } else if (isVideoOrMuxVideo || isVimeoRedirectLink(props.mediaUrl)) {
    return (
      <VideoPlayer
        _ref={videoRef}
        videoProps={{
          controls: true,
          style: defaultStyles,
        }}
        src={props.mediaUrl}
        language={selectedLanguage}
      />
    );
  } else if (linkType === LinkType.PDF) {
    return <PdfPreview mediaUrl={props.mediaUrl} />;
  } else if (linkType === LinkType.HOSTED_VIDEO) {
    return (
      <OpenGraphVideoPlayerIframe ogData={ogData} mediaUrl={props.mediaUrl} />
    );
  } else {
    return (
      <OpenGraphLinkPreview
        loading={openGraphQuery.loading}
        ogData={ogData}
        url={props.mediaUrl}
        ogImageProps={defaultStyles}
      />
    );
  }
};

export default MessageBlastMediaPreview;
