import { getLinkType, LinkType } from "@utils/files";
import { useMemo } from "react";
import { useOpenGraphDataQuery } from "@components/ui/MediaPreview.generated";

type Return = {
  linkType: LinkType;
  isBareLink: boolean;
  isVideoOrMuxVideo: boolean;
  loading: boolean;
};

const useMediaMetadata = (mediaUrl: string): Return => {
  const linkType: LinkType = useMemo(() => getLinkType(mediaUrl), [mediaUrl]);
  const { data, loading } = useOpenGraphDataQuery({
    variables: {
      url: mediaUrl,
    },
  });
  const isBareLink = useMemo(
    () =>
      !loading &&
      [LinkType.HOSTED_VIDEO, LinkType.LINK].includes(linkType) &&
      (!data?.OpenGraphData || Object.keys(data.OpenGraphData).length === 0),
    [loading, data?.OpenGraphData, linkType],
  );
  const isVideoOrMuxVideo = [LinkType.MUX_VIDEO, LinkType.VIDEO].includes(
    linkType,
  );
  return useMemo(() => {
    return {
      linkType,
      isBareLink: isBareLink,
      isVideoOrMuxVideo,
      loading: loading,
    };
  }, [isBareLink, linkType, isVideoOrMuxVideo, loading]);
};

export default useMediaMetadata;
