import AiAssistBadge from "@src/components/marketplace/AiAssistBadge";
import InteractionOptions from "@src/components/modals/ai/InteractionOptions";
import ToneOptions from "@src/components/modals/ai/ToneOptions";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import Modal from "@src/deprecatedDesignSystem/components/Modal";
import Text from "@ui/text";
import TextField from "@src/deprecatedDesignSystem/components/TextField";
import TitleHeader from "@src/deprecatedDesignSystem/components/TitleHeader";
import { FC, useState } from "react";
import { useAIAssistBrandSelector } from "../../../hooks/useAIAssistBrandSelector";
import { AIAssistModalTopSection } from "./AIAssistModalTopSection";
import { styles } from "./AIGenerateCourseModal";
import { CreateAiCourseMutationFn } from "./AIGenerateCourseModal.generated";
export type Props = {
  closeModal: () => void;
  initialText?: string;
  title: string;
  loading: boolean;
  generate: CreateAiCourseMutationFn;
  placeholder: string;
  question: string;
  buttonText: string;
};

export const AIGenerateCourseAIAssist: FC<Props> = ({
  closeModal,
  initialText,
  title,
  loading,
  generate,
  placeholder,
  question,
  buttonText,
}) => {
  const [toneSeriousness, setToneSeriousness] = useState(2);
  const [toneFormality, setToneFormality] = useState(2);
  const [interactivity, setInteractivity] = useState(2);
  const [text, setText] = useState(initialText || "");
  const {
    brandSelectOptions,
    selectedAIBrandContextId: selectedBrandId,
    setSelectedAIBrandContextId: setSelectedBrandId,
    selectedBrandItem,
  } = useAIAssistBrandSelector();
  return (
    <Modal
      styleDeclaration={styles.modal}
      header={
        <TitleHeader
          title={title}
          onCancelClick={closeModal}
          element={
            <AutoLayout marginLeft={12} styleDeclaration={styles.aiAssistBadge}>
              <AiAssistBadge />
            </AutoLayout>
          }
        />
      }
      footer={
        <AutoLayout
          direction="horizontal"
          spaceBetweenItems={15}
          alignmentHorizontal="right"
          padding={12}
          styleDeclaration={styles.footer}
        >
          <Button
            text={buttonText}
            leftIcon="sparkles-2"
            loading={loading}
            disabled={!text}
            onClick={() =>
              generate({
                variables: {
                  input: {
                    toneSeriousness,
                    toneFormality,
                    interactivity,
                    textList: [text],
                    brandDetailsId: selectedBrandItem?.brandDetailsId,
                  },
                },
              })
            }
          />
        </AutoLayout>
      }
    >
      <AutoLayout padding={20} direction="vertical" width={672}>
        <div className="pb-2">
          <AIAssistModalTopSection
            brandSelectOptions={brandSelectOptions}
            selectedBrandId={selectedBrandId}
            setSelectedBrandId={setSelectedBrandId}
            selectedBrandItem={selectedBrandItem}
          />
        </div>

        <Text type="P2" fontWeight="Medium" style={{ marginBottom: 4 }}>
          {question}
        </Text>
        <TextField
          text={text}
          placeholder={placeholder}
          onTextChange={setText}
          disabled={loading}
          multiline
          width="100%"
          height="40px"
          minRows={12}
          maxRows={12}
          inputStyleDeclaration={styles.textField}
        />
        <AutoLayout direction="vertical" marginBottom={16}>
          <Text type="P2" fontWeight="Medium" style={{ marginBottom: 4 }}>
            Tone
          </Text>
          <ToneOptions
            toneFormality={toneFormality}
            toneSeriousness={toneSeriousness}
            setToneFormality={setToneFormality}
            setToneSeriousness={setToneSeriousness}
          />
        </AutoLayout>
        <Text type="P2" fontWeight="Medium" style={{ marginBottom: 4 }}>
          How interactive should it be?
        </Text>
        <InteractionOptions
          interactivity={interactivity}
          setInteractivity={setInteractivity}
        />
      </AutoLayout>
    </Modal>
  );
};
