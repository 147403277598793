import { FC } from "react";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { StyleDeclaration, StyleSheet } from "aphrodite";

type Props = {
  styleDeclaration?: StyleDeclaration;
};

const AiAssistBadge: FC<Props> = ({ styleDeclaration }) => {
  return (
    <AutoLayout alignmentVertical="center" styleDeclaration={styleDeclaration}>
      <AutoLayout
        width={24}
        height={24}
        alignmentVertical="center"
        alignmentHorizontal="center"
        styleDeclaration={styles.iconContainer}
      >
        <DeprecatedIcon type="sparkles-2" color={deprecatedTones.purple7} />
      </AutoLayout>
      <Text type="P1" fontWeight="SemiBold" color={deprecatedTones.purple7}>
        AI Assist Beta
      </Text>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    marginRight: 8,
    borderRadius: 8,
    backgroundColor: deprecatedTones.purple2,
    overflow: "hidden",
  },
});

export default AiAssistBadge;
