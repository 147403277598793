import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAiCourseMutationVariables = Types.Exact<{
  input: Types.AiCourseInput;
}>;


export type CreateAiCourseMutation = { __typename?: 'Mutation', createAICourse: { __typename?: 'CourseMutationResult', success: boolean, error?: { __typename?: 'Error', code: string } | null, course?: { __typename?: 'Course', id: number, draftVersion: { __typename?: 'CourseVersion', id: number }, libraryItem: { __typename?: 'LibraryItem', id: string } } | null } };

export type CreateAiCourseResultFragment = { __typename?: 'Course', id: number, draftVersion: { __typename?: 'CourseVersion', id: number }, libraryItem: { __typename?: 'LibraryItem', id: string } };

export const CreateAiCourseResultFragmentDoc = gql`
    fragment CreateAICourseResult on Course {
  id
  draftVersion {
    id
  }
  libraryItem {
    id
  }
}
    `;
export const CreateAiCourseDocument = gql`
    mutation CreateAICourse($input: AICourseInput!) {
  createAICourse(input: $input) {
    success
    error {
      code
    }
    course {
      ...CreateAICourseResult
    }
  }
}
    ${CreateAiCourseResultFragmentDoc}`;
export type CreateAiCourseMutationFn = Apollo.MutationFunction<CreateAiCourseMutation, CreateAiCourseMutationVariables>;

/**
 * __useCreateAiCourseMutation__
 *
 * To run a mutation, you first call `useCreateAiCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAiCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAiCourseMutation, { data, loading, error }] = useCreateAiCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAiCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateAiCourseMutation, CreateAiCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAiCourseMutation, CreateAiCourseMutationVariables>(CreateAiCourseDocument, options);
      }
export type CreateAiCourseMutationHookResult = ReturnType<typeof useCreateAiCourseMutation>;
export type CreateAiCourseMutationResult = Apollo.MutationResult<CreateAiCourseMutation>;
export type CreateAiCourseMutationOptions = Apollo.BaseMutationOptions<CreateAiCourseMutation, CreateAiCourseMutationVariables>;